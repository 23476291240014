import React, { ChangeEvent, FC, useRef } from 'react'
import styles from './styles.module.sass'

interface IFileInput {
  buttonComponent: FC<any>
  onChangeFile: (file: File) => void
  accept?: string
  isDisabled?: boolean
  id?: string,
  hideButton?: boolean
}

export const FileInput: FC<IFileInput> = ({
  buttonComponent: Button,
  onChangeFile,
  accept,
  isDisabled,
  id = 'fileInput',
  hideButton = false
}) => {
  const inputFile = useRef<HTMLInputElement | null>(null)
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    e.preventDefault()
    if (e.target.files?.length) onChangeFile(e.target.files[0])
    e.target.value = ''
  }

  return (
    <>
      {!hideButton && <Button onClick={() => inputFile.current?.click()} />}
      <input
        type="file"
        name="file"
        ref={inputFile}
        className={styles.fileInput}
        onChange={onInputChange}
        disabled={!!isDisabled}
        accept={accept}
        id={id}
      />
    </>
  )
}
