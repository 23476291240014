import type { ActionTypes } from './types'

const initialState = {
  countries: {},
  cities: [] as string[][],
  regions: {} as { [key: string]: { [key: string]: string } },
  areas: [] as {
    cities: string[]
    country: string
    name: string
  }[]
}

export const LocationReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case 'LOCATIONS__SET_ALL_LOCATIONS': {
      return {
        ...state,
        ...action?.locations
      }
    }
    default: return state
  }
}
