import type { RootState } from 'common/types'
import { createSelector } from 'reselect'

const videoChatSelector = (state: RootState) => state.videoChat
const selectedDevicesSelector = (state: RootState) => state.videoChat.selectedDevices
const isGroupSelector = (state: RootState) => state.videoChat.isGroup

export const getVideoChat = createSelector(videoChatSelector, (videoChat) => videoChat)
export const getVideoRoom = createSelector(getVideoChat, (videoChat) => videoChat?.room)

export const getDevices = createSelector(videoChatSelector, ({ devices }) => devices || {})
export const getVideoDevices = createSelector(getDevices, ({ videoinput }) => videoinput || [])
export const getSelectedDevices = createSelector(selectedDevicesSelector, (selectedDevices) => selectedDevices)
export const getIsGroup = createSelector(isGroupSelector, (isGroup) => isGroup)
