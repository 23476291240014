import { FC, ReactChild, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { Contact } from 'common/components/Contact'
import { useProfileNavigation } from 'common/hooks/useProfileNavigation'
import { SpinnerIcon } from 'common/icons_V2/SpinnerIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { NetworkFilters } from 'features/Contacts/Network/MyNetwork'
import { selectNetworkFilters } from 'features/FilterItems/selectors'
import { UserType } from 'features/MyProfile/types'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface ContactListProps {
  title: string | ReactChild,
  emptyStateTitle?: string,
  contacts: UserType[],
  searchString: string
  trustedUids: string[]
  allContactsUids: string[],
  getContactInfo: (contact: UserType) => string,
  buttonType?: string,
  showLoadingInAllPage?: boolean,
  isShowButtonVisible?: boolean,
  showTitle?: boolean,
  renderEmptySearchState: () => ReactNode,
  onShowGlobalSearchData: () => void
}

export const ContactList: FC<ContactListProps> = ({
  title,
  emptyStateTitle,
  contacts,
  searchString,
  trustedUids,
  allContactsUids,
  getContactInfo,
  buttonType,
  showLoadingInAllPage,
  isShowButtonVisible,
  renderEmptySearchState,
  onShowGlobalSearchData,
  showTitle
}) => {
  const { navigateToProfile } = useProfileNavigation()
  const networkFilters = useSelector(selectNetworkFilters)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const isEmptyInFilteredPage = contacts.length === 0 &&
   (networkFilters?.[0] !== NetworkFilters.ALL && !!networkFilters?.length)

  const renderContacts = (title: string | ReactChild) => {
    if (contacts.length > 0) {
      return contacts.map((contact: any) => {
        const {
          displayName, last_name, photoURL, uid, photo, username
        } = contact
        return (
          <Contact
            key={`${uid}${title}`}
            isTrusted={trustedUids.includes(uid)}
            isContact={allContactsUids.includes(uid)}
            searchString={searchString}
            onClick={() => navigateToProfile(uid, username)}
            name={displayName || last_name}
            photoUrl={photoURL}
            photo={photo}
            trustedByData={getContactInfo(contact)}
            uid={uid}
            buttonType={buttonType}
          />
        )
      })
    }
    switch (networkFilters[0]) {
      case NetworkFilters.FIRST_LEVEL:
      case NetworkFilters.SECOND_LEVEL:
      case NetworkFilters.ALL_CONTACTS:
      case NetworkFilters.GLOBAL_SEARCH:
        return renderEmptySearchState()
      case NetworkFilters.ALL:
      default:
        return (
          <>
            {isShowButtonVisible && (
              <GhostButton
                title={myNetworkTranslations.contactsGlobalShowMore}
                onClick={() => onShowGlobalSearchData()}
              />
            )}
            {!isShowButtonVisible && emptyStateTitle && (
              <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="div" className={styles.emptyState}>
                {emptyStateTitle}
              </Typography>
            )}
          </>
        )
    }
  }

  return (
    <div className={cn(isEmptyInFilteredPage ? styles.emptyContactsContent : styles.contactsContent)}>
      {showTitle && (
        <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="span" className={styles.subtitle}>
          {title}
        </Typography>
      )}
      <div className={cn(isEmptyInFilteredPage ? styles.emptyContactsList : styles.contactsList)}>
        {showLoadingInAllPage ? <div className={styles.spinner}><SpinnerIcon spinner /></div> : renderContacts(title)}
      </div>
    </div>
  )
}
