import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ResponsiveLayout } from 'common/components/ResponsiveLayout_V2'
import { WelcomeContacts } from 'features/Contacts/Network/WelcomeContacts'
import { selectMemoizedUserById } from 'features/Home/selectors'
import { selectMyNetworkContacts } from 'features/MyProfile/selectors'
import { ContactActionsProvider } from 'providers/ContactActionsProvider'
import { MyNetwork } from './MyNetwork'
import styles from './styles.module.sass'

export const Network = () => {
  const { pathname } = useLocation()
  const [,, openedProfileUid] = pathname.split('/')
  const memoizedUser = useSelector(selectMemoizedUserById(openedProfileUid))
  const contacts = useSelector(selectMyNetworkContacts)
  const isRightContentEmpty = !memoizedUser && !contacts.length

  return (
    <ContactActionsProvider>
      <ResponsiveLayout
        rightContent={<MyNetwork />}
        wrapper={styles.networkWrapper}
        isLeftHiddenOnMobile
        rightContentEmptyComponent={<WelcomeContacts />}
        responsiveLayoutRight={isRightContentEmpty ? styles.rightContent : styles.responsiveLayoutRight}
        isRightContentEmpty={isRightContentEmpty}
        isLeftWithHeader
        limitHeight
      />
    </ContactActionsProvider>
  )
}
