import { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ROUTE_PARAMS, ROUTE_SEARCH_PARAMS, ROUTES } from 'common/constants'
import { getQueryObject, getUserDetailsPath } from 'common/utils/url'
import { getMyProfile, getMyUid, selectMyNetworkContacts } from 'features/MyProfile/selectors'
import { PublicProfile } from 'features/PublicProfile'
import { SEARCH_PARAMS } from 'features/Translations/constants'
import { withRedirection } from 'hocs/withRedirection'

interface RedirectionRule {
  condition: boolean
  route: string
}

const useRedirectionRules = (): RedirectionRule[] => {
  const contacts = useSelector(selectMyNetworkContacts)
  const myUid = useSelector(getMyUid)
  const profile = useSelector(getMyProfile)
  const queryParams = useMemo(() => getQueryObject(location.search), [location.search])
  const sharedBy = queryParams[ROUTE_SEARCH_PARAMS.SHARED_BY]
  const vacancyId = queryParams[ROUTE_SEARCH_PARAMS.VACANCY_ID]
  const specialtyId = queryParams[ROUTE_SEARCH_PARAMS.SPECIALITY_ID]
  const isMe = sharedBy === myUid
  const contact = contacts.find(({ uid }) => uid === sharedBy)

  const getListingRoute = (uid: string, cardId: string) =>
    `${ROUTES.LISTINGS.replace(ROUTE_PARAMS.UID, uid)}?${SEARCH_PARAMS.LISTING_SELECTED_CARD}=${cardId}`

  const rules: RedirectionRule[] = useMemo(() => [
    {
      condition: !!vacancyId || !!specialtyId,
      route: getListingRoute(sharedBy, vacancyId || specialtyId)
    },
    {
      condition: (!contact && !isMe) || (!!contact || isMe),
      route: getUserDetailsPath(!!contact || isMe ? (contact?.username || profile?.username) : '', sharedBy)
    }
  ], [isMe, contact, myUid, sharedBy, vacancyId, specialtyId])
  return rules
}

export const AuthPublicProfile: FC = () => {
  const rules = useRedirectionRules()
  const Component = withRedirection(PublicProfile, rules)
  return <Component />
}
