import React, { FC, ReactNode, useEffect } from 'react'
import { CloseIcon } from 'common/icons/CloseIcon'
import styles from '../styles.module.sass'

interface INotification {
  children?: ReactNode
  notificationId: string
  removeMsg: (notificationId: string, e?: React.MouseEvent<HTMLDivElement>) => void
  viewMessage?: () => void
}

export const Notification: FC<INotification> = ({
  children, removeMsg, notificationId, viewMessage = () => null
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      removeMsg(notificationId)
    }, 10000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div className={styles.msg} onClick={viewMessage}>
      {children}
      <div
        className={styles.close}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => removeMsg(notificationId, e)}
      >
        <CloseIcon />
      </div>
    </div>
  )
}
