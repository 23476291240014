import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { ShareIcon } from 'common/icons_V2/ShareIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { ShareModalWithDiscard } from 'features/Home/components/CardActionsPopup_V2/components/ShareModal'
import { selectSettingsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface TShare {
  uid: string;
  title: string;
  closePopup?: () => void;
}

export const Share: FC<TShare> = ({ uid, title, closePopup }) => {
  const [isOpenShareModal, setIsOpenShareModal] = useState(false)
  const settingsTranslations = useSelector(selectSettingsTranslations)

  const onClose = () => {
    setIsOpenShareModal(false)
    closePopup?.()
  }

  return (
    <>
      <div className={styles.menuItem} onClick={() => { setIsOpenShareModal(true) }}>
        <ShareIcon />
        <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="p">
          {title}
        </Typography>
      </div>
      <ShareModalWithDiscard
        uid={uid}
        isOpen={isOpenShareModal}
        onClose={onClose}
        modalTitle={settingsTranslations.shareMyProfileModalTitle}
      />
    </>
  )
}
