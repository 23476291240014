import { FC } from 'react'
import cn from 'classnames'
import type { ContactsType, UserType } from 'features/MyProfile/types'
import { TrustingCount } from './components/TrustingCount'
import styles from './styles.module.sass'

interface IOtherProfileTrustedContacts {
  trustedContactsOtherProfile: ContactsType[]
  mutualUsers: ContactsType[]
  title: string
  setIsOpenTrustModal: () => void,
  auth: boolean
}

export const OtherProfileTrustedContacts: FC<IOtherProfileTrustedContacts> = ({
  trustedContactsOtherProfile, mutualUsers, title, setIsOpenTrustModal, auth
}) => {
  const getTrustedContacts = (data: UserType []) => {
    const sortedTrustedContacts = data
      .sort(({ photo, photoURL }) => ((photo || photoURL) ? -1 : 1)).slice(0, 3)
    return {
      trustedUsersForView: sortedTrustedContacts,
      count: data.length
    }
  }
  const trustedContacts = getTrustedContacts(trustedContactsOtherProfile)
  const users = getTrustedContacts(mutualUsers)

  return (
    <div className={cn(styles.trustedUsers, !trustedContacts?.count && styles.disable)}>
      <div
        onClick={setIsOpenTrustModal}
        className={cn(styles.text)}
      >
        <TrustingCount
          count={trustedContacts?.count}
          users={users}
          title={title}
          auth={auth}
        />
      </div>
    </div>
  )
}
