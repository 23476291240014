export function dataURLtoFile(base64Url: string) {
  const photoBase64 = base64Url.split(',')
  const [imageInfo] = base64Url.split(';')
  const [, imageType] = imageInfo?.split(':') || []
  const imageName = 'image'

  const bstr = atob(photoBase64[1])
  const { length } = bstr

  const uint8Array = new Array(length).fill(0).reduce((acc, _, i) => {
    acc[i] = bstr.charCodeAt(i)
    return acc
  }, new Uint8Array(length))
  return new File([uint8Array], imageName, { type: imageType })
}
