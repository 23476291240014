import {
  createContext, PropsWithChildren, useContext, useState
} from 'react'

export enum CloseModalType {
  VACANCY = 'vacancy',
  DELETE_VACANCY = 'delete_vacancy',
  DELETE_SPECIALITY = 'delete_speciality',
  CLOSE_SPECIALITY = 'close_speciality'
}

interface ICloseVacancySpecialityModalContext {
  isVisibleCloseModal: CloseModalType;
  setIsVisibleCloseModal: (isVisibleCloseModal: CloseModalType) => void;
  vacancyId: string;
  setVacancyId: (vacancyId: string) => void
  specialityId: string;
  setSpecialityId: (specialityId: string) => void
}

const Context = createContext<ICloseVacancySpecialityModalContext>({} as ICloseVacancySpecialityModalContext)
export const useCloseVacancySpecialityModal = () => useContext(Context)

export const CloseVacancySpecialityModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isVisibleCloseModal, setIsVisibleCloseModal] = useState<CloseModalType>('' as CloseModalType)
  const [vacancyId, setVacancyId] = useState('')
  const [specialityId, setSpecialityId] = useState('')

  return (
    <Context.Provider
      value={{
        isVisibleCloseModal, setIsVisibleCloseModal, vacancyId, setVacancyId, specialityId, setSpecialityId
      }}
    >
      {children}
    </Context.Provider>
  )
}
