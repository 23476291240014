import type { ThunkAction } from 'redux-thunk'
import type { Auth } from 'firebase/auth'
import { AppStateType, InferActionsTypes } from 'common/types'
import { actions as contactsActions } from 'features/Contacts/actions'
import { actions as profileActions } from 'features/MyProfile/actions'
import { ProfileType, VacancyType } from 'features/MyProfile/types'
import { actions as notificationsActions } from 'features/Notifications/actions'
import { actions } from './actions'

export type ActionTypes = InferActionsTypes<
  | typeof actions
  | typeof profileActions
  | typeof notificationsActions
  | typeof contactsActions
>
export type ThunkType = ThunkAction<Promise<void | boolean> | void, AppStateType, Auth, ActionTypes>

export enum NotificationHistoryTypes {
  INTRO = 'intro',
  JOB_OFFER = 'job_offer'
}

export type MemoizedUserType = { [key: string]: ProfileType & { vacancies: VacancyType[] } & {isFullData?: boolean} }
export type UidByUsernameType = { [key: string]: string | undefined }
