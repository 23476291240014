import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { store } from 'store/store'
import { App } from './App'
import reportWebVitals from './reportWebVitals'

import './index.css'

dayjs.extend(calendar)
dayjs.extend(utc)
dayjs.extend(timezone)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
