import last from 'lodash/last'
import dayjs from 'dayjs'
import { Message } from '@twilio/conversations'
import {
  ChatType, MessageType, RemoteParticipantsType, RemoteParticipantType
} from 'features/Conversations/types'
import { ProfileType } from 'features/MyProfile/types'

export const constructRemoteParticipants = (
  remoteParticipants: RemoteParticipantType[],
  memoizedUsers: { [key: string]: ProfileType },
  deletedUsers: string[]
): RemoteParticipantsType => {
  return remoteParticipants.map(({
    uid,
    username,
    displayName,
    photoUrl,
    photo,
    isDeleted
  }) => {
    const user = memoizedUsers[uid]
    const isDeletedUser = isDeleted || deletedUsers.includes(uid)
    if (isDeletedUser) {
      return {
        uid,
        username,
        displayName: '',
        photoUrl: '',
        photo: '',
        isDeleted: true
      }
    }
    return {
      uid,
      username: user?.username || username,
      displayName: user?.displayName || displayName,
      photoUrl: user?.photoURL || photoUrl,
      photo: user?.photo || photo,
      isDeleted: false
    }
  })
}

export const getInitialOpenedChat = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get('id') || ''
}

export const sortChats = (chats: ChatType) => {
  const sortedChats: ChatType = {}

  Object.values(chats).sort((a, b) => {
    const lastMsgA = a.conversation?.lastMessage?.dateCreated
      ? dayjs(a.conversation.lastMessage.dateCreated).unix()
      : 0

    const lastMsgB = b.conversation?.lastMessage?.dateCreated
      ? dayjs(b.conversation?.lastMessage.dateCreated).unix()
      : 0

    return lastMsgB - lastMsgA
  }).forEach((value) => {
    sortedChats[value.chat] = value
  })
  return sortedChats
}

export const getDayMessage = (dateUpdated: Date | null, viewDays: string[]) => {
  const day = dayjs(dateUpdated).calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    lastWeek: 'DD MMM YYYY',
    sameElse: 'DD MMM YYYY'
  })
  if (!viewDays.includes(day)) {
    viewDays.push(day)
    return day
  }
  return null
}

export const getUnreadMessagesCount = (
  messages: (MessageType | Message)[],
  currentUserUid: string,
  unreadMessageCount: number
): number => {
  return last(messages)?.author === currentUserUid ? 0 : unreadMessageCount
}
