import { isMobileOrTablet } from './getAppLinkByOS'

export const openShareWindow = (url: string) => {
  const width = 600
  const height = 600
  const left = (screen.width / 2) - (width / 2)
  const top = (screen.height / 2) - (height / 2)
  window.open(url, '_blank', `noopener,noreferrer,width=${width},height=${height},left=${left},top=${top}`)
}

export const shareOnFacebook = (shareLink: string) => {
  const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`
  openShareWindow(url)
}

export const shareOnLinkedIn = (shareLink: string) => {
  const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareLink)}`
  openShareWindow(url)
}

export const shareOnWhatsApp = (shareLink: string, bodyText: string) => {
  const url = `https://${(isMobileOrTablet() ? 'api' : 'web')}.whatsapp.com/send?text=${encodeURIComponent(bodyText + shareLink)}`
  openShareWindow(url)
}

export const shareViaGmail = (shareLink: string, bodyText: string) => {
  const url = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=&body=${encodeURIComponent(bodyText + shareLink)}`
  openShareWindow(url)
}
