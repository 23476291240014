import { useState } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { CopyUrlIcon } from 'common/icons_V2/CopyUrlIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { getProfileLink } from 'common/utils/dynamicLinks'
import copyToClipboard from 'copy-to-clipboard'
import { selectMyProfileTranslations, selectOtherProfileTranslations } from 'features/Translations/selectors'
import { useProfileSections } from 'providers/ProfileSectionsProvider'
import styles from './styles.module.sass'

interface IProfileUrl {
  uid: string
  username?: string
  isOtherProfile: boolean
}

export const ProfileUrl = ({ uid, username, isOtherProfile }: IProfileUrl) => {
  const myProfileTranslations = useSelector(selectMyProfileTranslations)
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)
  const profileTranslations = isOtherProfile ? otherProfileTranslations : myProfileTranslations
  const { activeSection } = useProfileSections()
  const disable = !!activeSection
  const [isCopied, setIsCopied] = useState(false)

  const profileUrl = getProfileLink(uid, username)

  const handleCopy = () => {
    copyToClipboard(profileUrl)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 1000)
  }

  return (
    <div className={cn(styles.aboutElement, disable && styles.inActive)}>
      <Typography variant={TypographyVariants.Desktop_UI_L_Semibold} tag="div" className={styles.fieldTitle}>
        {profileTranslations.profileUrl}
      </Typography>
      <div className={styles.profileUrl}>
        <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="div">
          {profileUrl}
        </Typography>
        <div onClick={handleCopy} className={styles.copiedText}>
          {isCopied ? (
            <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p">
              {profileTranslations.profileLinkCopied}
            </Typography>
          ) : (
            <CopyUrlIcon />
          )}
        </div>
      </div>
    </div>
  )
}
