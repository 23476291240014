import { REGEX } from 'common/constants'

export const isNumber = (n: string | number | null | undefined): boolean => !isNaN(Number(n))

export const isTrueOrZero = (data: unknown) => data === 0 || !!data

export const isDigitsOnly = (n: any) => REGEX.DIGITS.test(String(n)) || ['0', 0].includes(n)

export const withLeadingPlusSign = (value: string) => {
  if (value) return (value[0] === '+' ? value : `+${value}`)
  return value
}
