import groupBy from 'lodash/groupBy'
import type { RootState } from 'common/types'
import { createSelector } from 'reselect'

const selectLocations = (state: RootState) => state.locations

export const selectAllLocations = (withCountries = false) => createSelector(
  selectLocations, (locations) => {
    if (withCountries) {
      const withAreas = locations.areas.reduce((acc, { country, name: areaName }) => {
        acc.push([country, areaName])
        return acc
      }, [] as string[][])
      const SEPARATOR = '#'
      const groupedCountries = groupBy(
        locations.cities,
        ([country, region]) => [country, region].join(SEPARATOR)
      )
      const groupedCountriesArr = Object.entries(groupedCountries)
      const countries: { [key: string]: boolean } = {}
      const cities = groupedCountriesArr.reduce((acc, [key, value], index) => {
        const [country, region] = key.split(SEPARATOR)
        const [prevCountry] = groupedCountriesArr[index - 1]?.[0]?.split(SEPARATOR) || []

        if (prevCountry !== country && !countries[country]) {
          countries[country] = true
          acc.push([country])
        }
        if (region) acc.push([country, region])
        acc.push(...value)
        return acc
      }, withAreas as string[][])
      return { ...locations, cities }
    }
    return locations || {
      countries: {}, cities: [] as string [][], regions: {} as { [key: string]: { [key: string]: string }}
    }
  }
)
