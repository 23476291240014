import { RefObject, useEffect, useState } from 'react'
import { useInputFocusDetect } from 'common/hooks/useInputFocusDetect'

export const useInputLabel = (
  inputValue: string,
  inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement>
) => {
  const [showLabel, setShowLabel] = useState<Boolean>(false)
  const { isInputFocused } = useInputFocusDetect(inputRef)

  useEffect(() => {
    setShowLabel(isInputFocused || !!inputValue)
  }, [inputValue, isInputFocused])

  return showLabel
}
