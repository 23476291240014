import { FC } from 'react'
import { useSelector } from 'react-redux'
import { CopyButton } from 'common/components/CopyButton'
import { Typography, TypographyVariants } from 'common/typography'
import { getInviteLink } from 'common/utils/dynamicLinks'
import { getMyUid } from 'features/MyProfile/selectors'
import { PageHeader } from 'features/PageHeader_V2'
import { selectInviteViaLinkTranslations } from 'features/Translations/selectors'

interface INetworkHeader {
  pageName: string
  subTitle: string
  isLoading: boolean
}

export const NetworkHeader: FC<INetworkHeader> = ({
  pageName, subTitle, isLoading = false
}) => {
  const myUid = useSelector(getMyUid)
  const inviteLink = getInviteLink(myUid)
  const inviteViaLinkTranslations = useSelector(selectInviteViaLinkTranslations)

  return (
    <>
      <PageHeader pageName={pageName} isLoading={isLoading} />
      <div>
        <Typography variant={TypographyVariants.Desktop_UI_L_Medium} tag="span">
          {subTitle}
        </Typography>
        <CopyButton
          shareLink={inviteLink}
          title={inviteViaLinkTranslations.copyLink}
          copiedTitle={inviteViaLinkTranslations.copyToClipBoard}
        />
      </div>
    </>
  )
}
