export const Vimeo = () => (
  <svg width="32" height="32" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0273 0.5H11.9727C5.36034 0.5 0 5.86035 0 12.4727V12.5273C0 19.1397 5.36034 24.5 11.9727 24.5H12.0273C18.6396 24.5 24 19.1397 24 12.5273V12.4727C24 5.86035 18.6396 0.5 12.0273 0.5Z" fill="#374957" />
    <g clipPath="url(#clip0_3364_7336)">
      <path d="M4 9.32002L4.646 10.171C4.646 10.171 5.986 9.11402 6.432 9.64002C6.884 10.171 8.595 16.547 9.161 17.726C9.655 18.759 11.026 20.122 12.524 19.15C14.036 18.172 19.027 13.91 19.921 8.86702C20.813 3.83302 13.905 4.88702 13.181 9.27502C15.003 8.18102 15.985 9.72302 15.051 11.466C14.115 13.216 13.256 14.357 12.811 14.357C12.374 14.357 12.028 13.183 11.516 11.145C10.991 9.03402 10.991 5.22802 8.795 5.66002C6.719 6.07002 4 9.32002 4 9.32002Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_3364_7336">
        <rect width="16" height="16" fill="white" transform="translate(4 4.5)" />
      </clipPath>
    </defs>
  </svg>
)
