import { useSelector } from 'react-redux'
import EmptySearchStateIcon from 'common/components/EmptySearchState/assets/EmptySearchStateIcon.svg'
import { selectErrorMsgsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

export const EmptySearchState = () => {
  const errorMsgsTranslations = useSelector(selectErrorMsgsTranslations)

  return (
    <div className={styles.emptySearchStateContent}>
      <img className={styles.emptySearchStateImage} src={EmptySearchStateIcon} alt="Empty Search" />
      <p className={styles.emptySearchStateTitle}>{errorMsgsTranslations.emptySearchStateTitle}</p>
    </div>
  )
}
