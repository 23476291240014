import { useSelector } from 'react-redux'
import { selectIsVacancyLoading, selectUserVacanciesById } from 'features/Home/selectors'
import { getMyUid, selectMyActiveVacancies, selectMyVacanciesLoading } from 'features/MyProfile/selectors'

export const useVacancies = (uid: string) => {
  const otherUserVacancies = useSelector(selectUserVacanciesById(uid))
  const myUid = useSelector(getMyUid)
  const myVacancies = useSelector(selectMyActiveVacancies)
  const isMyProfile = myUid === uid

  const isMyVacanciesLoading = useSelector(selectMyVacanciesLoading)
  const isVacanciesLoading = useSelector(selectIsVacancyLoading)

  return {
    vacancies: isMyProfile ? myVacancies : otherUserVacancies,
    isLoading: isMyProfile ? isMyVacanciesLoading : isVacanciesLoading
  }
}
