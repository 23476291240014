import { useMediaQuery } from 'react-responsive'
import { MEDIA_BREAKPOINTS } from 'common/constants'

export const useDesktopMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${MEDIA_BREAKPOINTS.lg + 1}px)` })

export const useTabletMediaQuery = () =>
  useMediaQuery({ query: `(max-width: ${MEDIA_BREAKPOINTS.lg}px) and (min-width: ${MEDIA_BREAKPOINTS.xs}px)` })

export const useMobileMediaQuery = () =>
  useMediaQuery({ query: `(max-width: ${MEDIA_BREAKPOINTS.xs - 1}px)` })
