import { FC } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import cn from 'classnames'
import { DefaultLink } from 'common/icons/socialLinks/DefaultLink'
import { getLinkDetails } from 'common/utils/profile'
import { SocialLinkType } from 'features/MyProfile/types'
import styles from './styles.module.sass'

interface ILinks {
  links: SocialLinkType[]
}

export const Links: FC<ILinks> = ({ links }) => {
  if (!links?.length) return <></>

  return (
    <ScrollContainer className={styles.workingDetails}>
      <div className={styles.profileLinksWrapper}>
        {links.map((link) => {
          if (!link?.url) return <></>
          const { url, name } = link
          const Icon = getLinkDetails(url)
          return (
            <a
              key={`${name}${url}`}
              className={cn(styles.link, styles.element)}
              href={url}
              target="_blank"
              rel="noreferrer"
            >
              <div>{Icon ? <Icon /> : <DefaultLink />}</div>
            </a>
          )
        })}
      </div>
    </ScrollContainer>
  )
}
