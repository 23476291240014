import type { RootState } from 'common/types'
import { selectMyNetworkContacts } from 'features/MyProfile/selectors'
import { createSelector } from 'reselect'

const selectContacts = (state: RootState) => state.contacts || {}

const getAdditionalProfilesSelector = (state: RootState) => state.contacts.additionalProfiles

export const getAllContacts = createSelector(
  getAdditionalProfilesSelector,
  selectMyNetworkContacts,
  (additional, contacts) => ({
    contacts,
    additional
  })
)

const getOtherProfileSelector = (state: RootState) => state.contacts.otherProfile

export const getOtherProfile = createSelector(getOtherProfileSelector, (profile) => profile)

export const selectMyContacts = createSelector(
  (state: RootState) => state.profile.profile?.contacts || [],
  (contacts) => contacts
)

const getParamsPublicProfileSelector = (state: RootState) => state.contacts.paramsPublicProfile

export const getParamsPublicProfile = createSelector(
  getParamsPublicProfileSelector,
  (paramsPublicProfile) => paramsPublicProfile
)

const getIsLoadingOtherProfileSelector = (state: RootState) => state.contacts.isLoadingOtherProfile

export const getIsLoadingOtherProfile = createSelector(
  getIsLoadingOtherProfileSelector,
  (isLoadingOtherProfile) => isLoadingOtherProfile
)

export const selectUserToIntroduce = createSelector(
  selectContacts,
  ({ userToIntroduce }) => userToIntroduce
)
