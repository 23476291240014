export enum PROFILE_FIELDS {
  COMPANY = 'company',
  LOCATION = 'location',
  FIRST_NAME = 'firstName',
  USER_NAME = 'username',
  JOB_TITLE = 'title',
  LAST_NAME = 'lastName',
  LINKS = 'links',
  LINK_URL = 'url',
}
