import { FC } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { BackIcon } from 'common/icons/BackIcon'
import { EditIcon } from 'common/icons/EditIcon'
import { MiniCloseIcon } from 'common/icons/MiniCloseIcon'
import { getMyUid } from 'features/MyProfile/selectors'
import styles from '../../styles.module.sass'

interface IHeader {
  goBack?: () => void;
  onOpenCloseModal?: () => void;
  onOpenEditModal?: () => void;
  userUid: string;
  name: string;
  isClosed?: boolean;
}

export const Header: FC<IHeader> = ({
  goBack,
  onOpenCloseModal,
  onOpenEditModal,
  userUid,
  name,
  isClosed
}) => {
  const myUid = useSelector(getMyUid)

  return (
    <div className={styles.headerVacancyDetails}>
      <div className={styles.headerVacancyDetailsTitleContent}>
        {!!goBack && <h3><BackIcon color="#16161D" onClick={goBack} /></h3>}
        <h1 className={styles.title} data-tip data-for="name">
          {name}
        </h1>
        <ReactTooltip id="name" place="top" effect="solid" multiline className={styles.tooltip}>
          <p>{name}</p>
        </ReactTooltip>
      </div>
      {(myUid === userUid && !isClosed) && (
        <div className={styles.icons}>
          <div onClick={onOpenEditModal}>
            <EditIcon />
          </div>
          <div onClick={onOpenCloseModal}>
            <MiniCloseIcon />
          </div>
        </div>
      )}
    </div>
  )
}
