import { FC } from 'react'
import cn from 'classnames'
import { CloseIcon } from 'common/icons_V2/CloseIcon'
import { SearchIcon } from 'common/icons_V2/SearchIcon'
import { SearchStateType } from 'features/SearchInput_V2/types'
import styles from './styles.module.sass'

interface ISearchInput {
  onChange?: (value: string) => void;
  onClose?: () => void;
  value: string
  className?: string
  showValue?: boolean
  placeholder: string
  state?: SearchStateType
}

export const SearchInput: FC<ISearchInput> = ({
  onChange, value, className, onClose, placeholder,
  state = SearchStateType.DEFAULT, showValue = true
}) => {
  const stateStyleMap: { [key in SearchStateType]?: string } = {
    [SearchStateType.WHITE]: styles.whiteState,
    [SearchStateType.DEFAULT]: styles.default
  }

  return (
    <div className={cn(styles.searchContainer, className)}>
      <SearchIcon color={showValue && value ? '#374957' : '#868EA1'} />
      <input
        value={(showValue && value) || ''}
        className={cn(
          styles.search,
          stateStyleMap[state]
        )}
        placeholder={placeholder || ''}
        onChange={(e) => onChange?.(e.target.value)}
      />
      {(showValue && value) && (
        <div className={styles.close} onClick={onClose || (() => onChange?.(''))}>
          <CloseIcon />
        </div>
      )}
    </div>
  )
}
