export const EmptySearchStateIcon = () => (
  <svg width="448" height="447" viewBox="0 0 448 447" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1605_26012)">
      <circle cx="212.744" cy="219.229" r="212.244" fill="#F5F8FF" />
      <circle cx="212.744" cy="219.229" r="165.079" fill="#E4ECFE" />
      <path d="M263.845 283.517H76.1552C74.6888 283.517 73.5 282.328 73.5 280.862V32.6552C73.5 31.1888 74.6888 30 76.1552 30H219.279L266.5 73.5888V280.862C266.5 282.328 265.311 283.517 263.845 283.517Z" fill="#F7FAFF" stroke="#B7CEFF" strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M96.338 84.1888C96.338 81.0982 98.8434 78.5928 101.934 78.5928H210.288C213.378 78.5928 215.884 81.0982 215.884 84.1888C215.884 87.2794 213.378 89.7848 210.288 89.7848H101.934C98.8434 89.7848 96.338 87.2794 96.338 84.1888ZM96.338 118.682C96.338 115.591 98.8434 113.086 101.934 113.086H241.745C244.836 113.086 247.341 115.591 247.341 118.682C247.341 121.772 244.836 124.278 241.745 124.278H101.934C98.8434 124.278 96.338 121.772 96.338 118.682ZM96.338 153.175C96.338 150.084 98.8434 147.579 101.934 147.579H140.382C143.473 147.579 145.978 150.084 145.978 153.175C145.978 156.265 143.473 158.771 140.382 158.771H101.934C98.8434 158.771 96.338 156.265 96.338 153.175ZM159.253 153.175C159.253 150.084 161.759 147.579 164.849 147.579H241.745C244.836 147.579 247.341 150.084 247.341 153.175C247.341 156.265 244.836 158.771 241.745 158.771H164.849C161.759 158.771 159.253 156.265 159.253 153.175ZM96.338 187.668C96.338 184.577 98.8434 182.072 101.934 182.072H203.297C206.388 182.072 208.893 184.577 208.893 187.668C208.893 190.758 206.388 193.264 203.297 193.264H101.934C98.8434 193.264 96.338 190.758 96.338 187.668ZM222.168 187.668C222.168 184.577 224.674 182.072 227.764 182.072H241.745C244.836 182.072 247.341 184.577 247.341 187.668C247.341 190.758 244.836 193.264 241.745 193.264H227.764C224.674 193.264 222.168 190.758 222.168 187.668ZM96.338 218.711C96.338 215.621 98.8434 213.115 101.934 213.115H231.26C234.35 213.115 236.856 215.621 236.856 218.711C236.856 221.802 234.35 224.307 231.26 224.307H101.934C98.8434 224.307 96.338 221.802 96.338 218.711ZM96.338 249.755C96.338 246.664 98.8434 244.159 101.934 244.159H196.307C199.397 244.159 201.903 246.664 201.903 249.755C201.903 252.845 199.397 255.351 196.307 255.351H101.934C98.8434 255.351 96.338 252.845 96.338 249.755Z" fill="#B7CEFF" />
      <path d="M96.338 84.1888C96.338 81.0982 98.8434 78.5928 101.934 78.5928H210.288C213.378 78.5928 215.884 81.0982 215.884 84.1888C215.884 87.2794 213.378 89.7849 210.288 89.7849H101.934C98.8434 89.7849 96.338 87.2794 96.338 84.1888Z" fill="#2D56B2" />
      <path d="M219.673 29L267.503 73.1509H223.673C221.463 73.1509 219.673 71.3601 219.673 69.1509V29Z" fill="#B7CEFF" />
      <circle cx="92.4884" cy="203.738" r="15.1603" stroke="#5388FE" strokeWidth="4" />
      <circle cx="407.716" cy="250.068" r="12.599" stroke="#2D56B2" strokeWidth="4" />
      <circle cx="393.5" cy="219" r="7" stroke="#90ABE8" strokeWidth="3" />
      <circle cx="319" cy="83.5" r="22.5" stroke="#7698E4" strokeWidth="4" />
      <circle cx="39" cy="128.5" r="16.5" stroke="#4E71C0" strokeWidth="4" />
      <circle cx="170" cy="372.5" r="16.5" stroke="#B9CFFC" strokeWidth="4" />
      <circle cx="35.8063" cy="276.267" r="3.87661" stroke="#4F86FF" strokeWidth="3" />
      <path d="M220.21 439.868C296.591 439.868 358.51 436.253 358.51 431.794C358.51 427.334 296.591 423.719 220.21 423.719C143.828 423.719 81.9088 427.334 81.9088 431.794C81.9088 436.253 143.828 439.868 220.21 439.868Z" fill="#B7CEFF" />
      <path d="M207.799 319.252C193.225 313.496 180.591 303.477 171.495 290.462C162.399 277.448 157.25 262.022 156.697 246.135C156.145 230.249 160.215 214.615 168.393 201.211C176.571 187.807 188.489 177.235 202.641 170.831C216.792 164.427 232.542 162.479 247.897 165.234C263.253 167.989 277.525 175.322 288.91 186.307C300.294 197.292 308.279 211.435 311.855 226.947C315.431 242.46 314.437 258.645 309 273.457C305.389 283.294 299.907 292.281 292.867 299.907C285.827 307.532 277.367 313.645 267.969 317.898C258.571 322.151 248.421 324.459 238.097 324.691C227.773 324.924 217.478 323.076 207.799 319.252ZM294.636 267.796C299.017 255.869 299.82 242.836 296.943 230.344C294.066 217.852 287.638 206.462 278.472 197.615C269.306 188.768 257.814 182.861 245.449 180.642C233.084 178.422 220.402 179.989 209.006 185.145C197.61 190.301 188.012 198.814 181.427 209.608C174.841 220.401 171.564 232.99 172.009 245.783C172.453 258.575 176.601 270.997 183.926 281.477C191.251 291.956 201.425 300.023 213.162 304.657C228.897 310.868 246.319 310.474 261.598 303.562C276.876 296.649 288.76 283.784 294.636 267.796Z" fill="#2D56B2" />
      <path opacity="0.76" d="M252.98 305.355C219.296 315.117 183.951 295.294 174.036 261.079C164.121 226.865 183.389 191.215 217.073 181.453C250.757 171.691 286.101 191.514 296.017 225.729C305.932 259.943 286.664 295.593 252.98 305.355Z" fill="#F4F7FF" fillOpacity="0.5" />
      <path d="M192.299 259.431C195.647 268.79 201.949 276.806 210.256 282.269C218.563 287.732 228.424 290.346 238.349 289.717C248.275 289.087 257.728 285.248 265.281 278.779C272.834 272.31 278.078 263.561 280.221 253.854" stroke="white" strokeWidth="10" strokeMiterlimit="10" />
      <path d="M293.349 323.761L318.624 297.268C319.583 296.263 321.17 296.248 322.168 297.233L389.672 363.849C390.67 364.834 390.702 366.448 389.744 367.453L364.469 393.945C363.51 394.95 361.923 394.966 360.925 393.98L293.421 327.365C292.422 326.379 292.39 324.766 293.349 323.761Z" fill="#2D56B2" />
      <path d="M360.204 393.715L293.427 327.001C293.193 326.767 293.004 326.487 292.873 326.18C292.741 325.872 292.67 325.542 292.662 325.208C292.654 324.874 292.71 324.543 292.827 324.234C292.944 323.925 293.119 323.643 293.343 323.406L299.512 316.857C299.06 317.337 298.815 317.985 298.831 318.659C298.847 319.333 299.123 319.978 299.597 320.452L366.373 387.166C366.608 387.401 366.885 387.586 367.188 387.712C367.49 387.838 367.813 387.902 368.137 387.9C368.461 387.898 368.781 387.83 369.077 387.701C369.374 387.571 369.642 387.382 369.866 387.144L363.696 393.694C363.472 393.931 363.204 394.12 362.908 394.25C362.611 394.379 362.292 394.447 361.968 394.449C361.643 394.451 361.321 394.387 361.018 394.261C360.715 394.135 360.439 393.95 360.204 393.715Z" fill="#1C2C83" />
      <path d="M383.756 370.027L316.507 303.309C316.27 303.074 315.991 302.889 315.686 302.763C315.381 302.638 315.055 302.575 314.728 302.577C314.401 302.58 314.078 302.649 313.779 302.779C313.479 302.91 313.208 303.1 312.982 303.339L306.05 310.644L319.017 296.978C319.474 296.496 320.102 296.223 320.763 296.217C321.424 296.211 322.064 296.475 322.542 296.948L389.791 363.667C390.269 364.141 390.545 364.787 390.56 365.462C390.574 366.138 390.326 366.788 389.869 367.27L383.833 373.63C384.06 373.392 384.237 373.109 384.356 372.799C384.475 372.489 384.532 372.157 384.525 371.823C384.517 371.488 384.446 371.157 384.314 370.849C384.182 370.541 383.992 370.262 383.756 370.027Z" fill="#193D8C" />
      <path d="M387.689 359.785L356.873 392.086C356.412 392.568 356.161 393.219 356.175 393.895C356.188 394.571 356.465 395.216 356.945 395.689L362.316 400.99C365.475 404.107 369.709 405.834 374.087 405.79C378.465 405.746 382.628 403.936 385.661 400.757L397.078 388.79C398.58 387.216 399.759 385.353 400.548 383.308C401.337 381.263 401.72 379.076 401.676 376.872C401.632 374.668 401.161 372.489 400.291 370.461C399.421 368.432 398.168 366.594 396.604 365.051L391.233 359.75C390.753 359.277 390.11 359.015 389.446 359.021C388.781 359.028 388.149 359.303 387.689 359.785Z" fill="#DBE8EC" />
      <path d="M390.591 371.715L385.2 366.555C384.719 366.095 384.071 365.843 383.397 365.856C382.724 365.869 382.082 366.145 381.611 366.624L355.882 392.781C355.972 392.644 356.075 392.515 356.19 392.398L387.707 360.356C388.178 359.877 388.821 359.601 389.494 359.588C390.167 359.576 390.815 359.827 391.297 360.288L396.687 365.447C399.857 368.482 401.664 372.644 401.712 377.02C401.76 381.395 400.045 385.624 396.943 388.778L391.497 394.314C394.297 391.12 395.763 387 395.594 382.799C395.426 378.598 393.636 374.633 390.591 371.715Z" fill="#E8EEEF" />
      <path d="M362.247 400.263L356.871 395.008C356.391 394.539 356.115 393.898 356.103 393.226C356.092 392.554 356.346 391.906 356.809 391.425L363.166 384.825C362.705 385.307 362.453 385.954 362.466 386.625C362.478 387.295 362.754 387.935 363.233 388.403L368.609 393.658C371.77 396.748 376.015 398.453 380.41 398.396C384.805 398.34 388.989 396.527 392.043 393.357L397.188 388.016L397.177 388.028L385.681 399.962C384.169 401.532 382.364 402.785 380.368 403.65C378.372 404.514 376.224 404.974 374.048 405.002C371.872 405.03 369.71 404.626 367.685 403.813C365.66 403 363.812 401.794 362.247 400.263Z" fill="#BAC5DB" />
    </g>
    <defs>
      <clipPath id="clip0_1605_26012">
        <rect width="447" height="447" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
