import { doc, onSnapshot } from 'firebase/firestore'
import translationsFallback from 'common/translationsFallback.json'
import { DispatchType, TranslationsType } from 'features/Translations/types'
import { firestoreDb } from 'store/store'

export enum ACTION_TYPES {
  SET_DATA = 'TRANSLATIONS__SET_DATA',
  SET_IS_LOADING = 'TRANSLATIONS__SET_IS_LOADING'
}

export const actions = {
  set: (data: TranslationsType) => ({ type: ACTION_TYPES.SET_DATA, data }) as const,
  setIsLoading: (isLoading: boolean) => ({ type: ACTION_TYPES.SET_IS_LOADING, isLoading }) as const
}

export const listenLokaliseChanges = (dispatch: DispatchType) => {
  const docRef = doc(firestoreDb, 'lokalise', 'web')
  return onSnapshot(docRef, async (doc) => {
    const lokaliseFileName = 'web.strings.json'
    const response = doc.data()
    if (response?.files?.length) {
      try {
        const webStringsUrl = response.files.find((url: string) => url.includes(lokaliseFileName))
        const translations = await fetch(webStringsUrl, { method: 'GET' })
        const translationsData = await translations.json()
        dispatch(actions.set(translationsData))
      } catch {
        dispatch(actions.set(translationsFallback))
      } finally {
        dispatch(actions.setIsLoading(false))
      }
    }
  }, () => dispatch(actions.set(translationsFallback)))
}
