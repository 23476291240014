import type { ThunkAction } from 'redux-thunk'
import type { Auth } from 'firebase/auth'
import type { Participant } from 'twilio-video'
import { AppStateType, InferActionsTypes } from 'common/types'
import { actions as actionsNotifications } from 'features/Notifications/actions'
import { actions } from './actions'

export type ActionTypes = InferActionsTypes<typeof actions | typeof actionsNotifications>
export type ThunkType = ThunkAction<Promise<void | boolean>, AppStateType, Auth, ActionTypes>

export type MapParticipantsType = { [sid: string]: Participant }

export type DevicesType = {
  [key: string]: MediaDeviceInfo[]
}

export enum DevicesTypes {
  Videoinput = 'videoinput',
  Audioinput = 'audioinput'
}
