import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getIsSWActive } from 'common/selectors'

export const useSW = () => {
  const history = useHistory()
  const isSWActive = useSelector(getIsSWActive)

  useEffect(() => {
    const onMessage = (event: any) => {
      history.push(event.data.url)
    }

    if (isSWActive) {
      navigator.serviceWorker.addEventListener('message', onMessage)
    }

    return () => {
      navigator.serviceWorker.removeEventListener('message', onMessage)
    }
  }, [isSWActive])
}
