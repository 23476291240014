import { v4 as uuidv4 } from 'uuid'
import Conversation, { Client as ConversationsClient, Message, Participant } from '@twilio/conversations'
import { profileAPI, usersAPI } from 'api'
import { actions as commonActions } from 'common/actions'
import { CHAT_MEDIA_KEY, CHAT_NAME_UID_SEPARATOR, ConversationListeners } from 'common/constants'
import { getUnreadMessagesCount } from 'common/utils/chats'
import { isTrueOrZero } from 'common/utils/numbers'
import {
  ChatType,
  MessageType,
  RemoteParticipantType,
  ThunkType
} from 'features/Conversations/types'
import { addMemoizedUser } from 'features/Home/actions'
import { favorSpecialty, favorVacancy } from 'features/MyProfile/actions'
import type { ContactsType, UserBriefInfo, UserType } from 'features/MyProfile/types'
import { getTokenFcm } from 'features/MyProfile/utils'
import { actions as actionsNotifications } from 'features/Notifications/actions'
import { ErrorModalTypes } from 'features/Notifications/types'
import { createMediaMsgFromLocal } from './components/Chat/Messages/utils'

export const actions = {
  setClient: (client: ConversationsClient) => (
    { type: 'CONVERSATIONS__SET_CONVERSATIONS_CLIENT', client } as const
  ),
  setChats: (chats: ChatType) => (
    { type: 'CONVERSATIONS__SET_CHATS', chats } as const
  ),
  addChat: (payload: ChatType) => (
    { type: 'CONVERSATIONS__ADD_CHAT', payload } as const
  ),
  setParticipantsToOpenChatWith:
    (profiles: ContactsType[], vacancyId?: string | null, specialtyId?: string | null) => ({
      type: 'CONVERSATIONS__SET_PARTICIPANTS_TO_OPEN_CHAT_WITH',
      payload: {
        profiles, vacancyId, specialtyId
      }
    } as const),
  setOpenedChat: (chat: string) => (
    { type: 'CONVERSATIONS__SET_OPENED_CHAT', chat } as const
  ),
  addMessage: (message: Message | MessageType, chat: string) => (
    { type: 'CONVERSATIONS__ADD_MESSAGE', payload: { message, chat } } as const
  ),
  removeMessage: (messageSid: string, chatId: string) => (
    { type: 'CONVERSATIONS__REMOVE_MESSAGE', payload: { messageSid, chatId } } as const
  ),
  replyToMessage: (message: Message | MessageType | null, userName?: string | null) => (
    { type: 'CONVERSATIONS__REPLY_TO_MESSAGE', message, userName } as const
  ),
  addMessages: (messages: (Message | MessageType)[], chat: string, hasPrevPage: boolean) => (
    { type: 'CONVERSATIONS__SET_MESSAGES', payload: { messages, chat, hasPrevPage } } as const
  ),
  setMessagesLoadingForChat: (chat: string) => (
    { type: 'CONVERSATIONS__SET_MESSAGES_LOADING_FOR_CHAT', payload: { chat } } as const
  ),
  updateMessage: (message: Message | MessageType, chat: string, lastMessageSid: string) => (
    { type: 'CONVERSATIONS__UPDATE_MESSAGE', payload: { message, chat, lastMessageSid } } as const
  ),
  updateMessageContent: (message: any) => (
    { type: 'CONVERSATIONS__UPDATE_MESSAGE_CONTENT', payload: { message } } as const
  ),
  updateSearchMessage: (messageIndex: number | null, searchString: string | null) => (
    { type: 'CONVERSATIONS__UPDATE_SEARCH_MESSAGE', payload: { messageIndex, searchString } } as const
  ),
  setIsMessagesHasUnread: (isMessagesHasUnread: boolean) => (
    { type: 'CONVERSATIONS__SET_IS_MESSAGES_UNREAD', isMessagesHasUnread } as const
  ),
  setUnreadMessagesPerChat: (chatId: string, unreadMessageCount: number | null) => (
    { type: 'CONVERSATIONS__SET_UNREAD_MESSAGES', payload: { chatId, unreadMessageCount } } as const
  ),
  setIsOffersAndFavorsLoaded: (isOffersAndFavorsLoaded: boolean) => (
    { type: 'CONVERSATIONS__SET_IS_VACANCIES_LOADED', isOffersAndFavorsLoaded } as const
  )
}

const getChatParticipants = (
  conversation: Conversation.Conversation,
  myUid: string
): string[] => {
  if (!conversation) return []
  return conversation.friendlyName
    ?.split(CHAT_NAME_UID_SEPARATOR)
    .filter((uid) => uid !== myUid) || []
}

const getChatsParticipants = (
  conversations: Conversation.Conversation[],
  myUid: string
): string[][] => {
  return conversations.map(
    (conversation) => getChatParticipants(conversation, myUid)
  )
}

const getChatIdForUsers = (chats: ChatType, isGroupChat: boolean, profiles: [] | UserType[], uid: string) => {
  const chat = Object.values(chats).find((chat) => {
    const { remoteParticipants } = chat
    if (isGroupChat) {
      return remoteParticipants.find(({ uid }) => uid === profiles[0].uid)
        && remoteParticipants.find(({ uid }) => uid === profiles[1].uid)
    }
    return remoteParticipants.length === 1 && remoteParticipants[0].uid === `${uid}`
  })
  return chat?.chat
}

export const handleConversationAdded = (
  conversation: Conversation.Conversation,
  conversations: Conversation.Conversation[],
  client: ConversationsClient,
  resolve: Function
) => {
  conversations.push(conversation)
  if (conversations.length === (client as any)._conversationsEntity.conversations.size) {
    resolve(conversations)
  }
}

async function collectConversationData(client: ConversationsClient): Promise<Conversation.Conversation[]> {
  return new Promise((resolve) => {
    const conversationAddedHandler = (conversation : Conversation.Conversation) =>
      handleConversationAdded(conversation, conversations, client, resolveConversation)

    const resolveConversation = (conversations: Conversation.Conversation[]) => {
      resolve(conversations)
      client?.off(ConversationListeners.CONVERSATION_ADDED, conversationAddedHandler)
    }

    const conversations: Conversation.Conversation[] = []
    client.on(ConversationListeners.CONVERSATION_ADDED, conversationAddedHandler)
  })
}

const getConversationsClient = (): ThunkType<ConversationsClient> => async (dispatch) => {
  const conversationsTokens = await profileAPI.getChatToken()
  const client = new ConversationsClient(conversationsTokens.tokens.fcm)
  const { token: fcmToken, isSWActive } = await getTokenFcm()
  if (fcmToken) client.setPushRegistrationId('fcm', fcmToken)
  dispatch(commonActions.setIsSWActivated(isSWActive))
  dispatch(actions.setClient(client))
  return client
}

export const init = (): ThunkType => async (dispatch) => {
  let client = await dispatch(getConversationsClient()) as ConversationsClient
  const { items: subscribedConversations } = await client.getSubscribedConversations()
  if (!subscribedConversations?.length) {
    dispatch(actions.setChats({}))
    return
  }
  client = await dispatch(getConversationsClient()) as ConversationsClient
  const conversations = await collectConversationData(client)
  const sortedConversations = conversations
    .sort((a, b) => (b?.lastMessage?.dateCreated?.getTime() ?? 0) - (a?.lastMessage?.dateCreated?.getTime() ?? 0))
  const batchSize = 10
  for (let i = 0; i < sortedConversations.length; i += batchSize) {
    const batch = sortedConversations.slice(i, i + batchSize)
    /* eslint-disable no-await-in-loop */
    await dispatch(processConversations(batch, client))
    /* eslint-disable no-await-in-loop */
  }
}

export const processConversations = (
  conversations: Conversation.Conversation[],
  client: Conversation.Client
): ThunkType => async (dispatch, getState) => {
  const {
    profile: { profile },
    surf: { memoizedUsers },
    conversations: { openedChat, chats }
  } = getState()
  if (!profile) return
  if (openedChat) {
    const openedChatExists = chats[openedChat] || conversations.some((conversation) => conversation.sid === openedChat)
    if (!openedChatExists) {
      const openedChatInfo = await client.getConversationBySid(openedChat)
      conversations.push(openedChatInfo)
    }
  }

  const participantsPerChat = getChatsParticipants(conversations, profile.uid)

  const addUsersBrief = async (uids: string[]): Promise<UserBriefInfo[] | []> => {
    try {
      const response = await usersAPI.getUsersBrief(uids)
      return response.profiles || []
    } catch (error) {
      return []
    }
  }

  const participantsUids = Array.from(new Set(participantsPerChat.flat()))
  const chunkSize = 10

  const groupedIds: string[][] = Array.from({ length: Math.ceil(participantsUids.length / chunkSize) }, (_, index) =>
    participantsUids.slice(index * chunkSize, (index + 1) * chunkSize))

  const remoteUsersArray: UserBriefInfo[][] = await Promise.all(
    groupedIds.map((uids: string[]) => addUsersBrief(uids))
  ) as UserBriefInfo[][]

  const remoteUsers: UserBriefInfo[] = remoteUsersArray.flat()

  const remoteUsersDictionary = remoteUsers ? remoteUsers.reduce<{ [key: string]: UserBriefInfo }>((
    users,
    user: UserBriefInfo | null
  ) => {
    if (user) return ({ ...users, [user.uid]: user })
    return users
  }, {}) : {}

  const messages = await Promise.all(conversations.map((conversation) => conversation.getMessages(1)))
  const unreadMessages: (number | null)[] = await Promise.all(
    conversations.map((conversation) => conversation.getUnreadMessagesCount())
  )

  const allChats = conversations.reduce((chats, conversation, index) => {
    const remoteParticipants = participantsPerChat[index].map((uid: string) => {
      const user = memoizedUsers[uid] || remoteUsersDictionary[uid]
      const {
        photoURL, displayName, photo, username
      } = user || {}
      return {
        uid,
        username,
        photoUrl: photoURL,
        photo,
        displayName,
        isDeleted: !user
      } as RemoteParticipantType
    })
    if (!remoteParticipants.length) {
      remoteParticipants.push({
        uid: '',
        photoUrl: '',
        photo: '',
        displayName: '',
        isDeleted: true
      })
    }

    return {
      ...chats,
      [conversation.sid]: {
        chat: conversation.sid,
        name: remoteParticipants[0]?.displayName,
        photoUrl: remoteParticipants[0]?.photoUrl,
        photo: remoteParticipants[0]?.photo,
        messages: messages[index].items,
        unreadMessageCount: unreadMessages[index] ?? messages[index].items.length,
        friendlyName: conversation.friendlyName || '',
        hasPrevPage: true,
        conversation,
        remoteParticipants
      }
    }
  }, {} as ChatType)

  dispatch(actions.setChats(allChats))
  dispatch(actions.setIsMessagesHasUnread(unreadMessages.some((n, idx) => {
    return getUnreadMessagesCount(
      messages[idx].items as MessageType[],
      profile.uid,
      n ?? (messages[idx].items.length)
    )
  })))
}

export const removeMessage = (message: Message | MessageType, soft?: boolean): ThunkType => async (dispatch) => {
  if (soft) {
    dispatch(actions.removeMessage(message?.sid, message?.conversation?.sid))
  } else {
    message
      ?.remove?.()
      .then(
        (message: Message | MessageType) => dispatch(actions.removeMessage(message?.sid, message?.conversation?.sid))
      )
      .catch(() => {
        dispatch(actionsNotifications.addErrorMsg({
          type: ErrorModalTypes.DEFAULT,
          description: 'Failed to delete the message'
        }))
      })
  }
}

export const updateUnreadCount = (chatId: string): ThunkType<number> => async (dispatch, getState) => {
  const { conversations: { chats }, profile: { profile } } = getState()
  const { conversation } = chats[chatId] || {}
  if (!conversation || !profile) return 0
  return conversation.getUnreadMessagesCount()
    .then(async (count: number | null) => {
      let unreadMessageCount: number
      if (count === null) {
        const messages = (await conversation.getMessages()).items as MessageType[]
        unreadMessageCount = getUnreadMessagesCount(
          messages, profile.uid, messages.length
        )
      } else {
        unreadMessageCount = getUnreadMessagesCount(
          [], profile.uid, count
        )
      }
      dispatch(actions.setUnreadMessagesPerChat(
        chatId,
        unreadMessageCount
      ))
      dispatch(actions.setIsMessagesHasUnread(
        Object.values(chats).some(
          (chat) => (chat.chat === chatId ? !!unreadMessageCount : !!chat.unreadMessageCount)
        )
      ))
      return unreadMessageCount
    })
    .catch(() => 0)
}

export const replyToMessage = (message: any): ThunkType => async (dispatch, getState) => {
  if (!message) dispatch(actions.replyToMessage(null))
  const state = getState()
  if (!state.profile?.profile) return
  const { profile: { profile: { uid, displayName } }, conversations: { chats } } = state
  const chatId = message?.conversation?.sid
  const myMessage = message?.author === uid
  const remoteParticipantDisplayName = chats[chatId].remoteParticipants.find(
    ({ uid }) => uid === message?.author
  )?.displayName
  dispatch(actions.replyToMessage(message, myMessage ? displayName : remoteParticipantDisplayName))
}

const sendAudioMessage = (
  conversation: Conversation.Conversation,
  localMessageObj: Message | MessageType,
  messageBlob: Blob,
  repliedMessage: { repliedMessage: number } | null,
  attributes?: any
): ThunkType => (dispatch) => {
  const formData = new FormData()
  const filename = 'recording.mp4'
  formData.append('file', messageBlob, filename)
  dispatch(actions.addMessage({
    ...localMessageObj,
    ...attributes,
    ...createMediaMsgFromLocal(messageBlob, filename)
  } as MessageType, conversation.sid))
  conversation.sendMessage(formData, {
    messageId: uuidv4(),
    ...repliedMessage,
    ...attributes
  }).then((index: number) => {
    // @ts-ignore
    const messages = conversation?._messagesEntity?.messagesByIndex
    dispatch(actions.updateMessage(messages.get(index), conversation.sid, localMessageObj.sid))
  })
}

const sendTextOrMediaMessage = (
  conversation: Conversation.Conversation,
  localMessageObj: Message | MessageType,
  messageBody: string,
  repliedMessage: { repliedMessage: number } | null,
  attributes?: any
): ThunkType =>
  (dispatch) => {
    const message = {
      ...localMessageObj,
      body: messageBody,
      ...(attributes?.media && createMediaMsgFromLocal(attributes?.media?.get?.(CHAT_MEDIA_KEY)))
    }
    dispatch(actions.addMessage(message, conversation.sid))
    conversation.sendMessage(attributes?.media || messageBody, {
      messageId: uuidv4(),
      ...repliedMessage,
      ...attributes
    }).then((index: number) => {
      // @ts-ignore
      const messages = conversation?._messagesEntity?.messagesByIndex
      if (messages) dispatch(actions.updateMessage(messages.get(index), conversation.sid, message.sid))
    })
  }

export const sendMessage = (
  message: string | Blob,
  chatId: string,
  attributes?: any
): ThunkType =>
  async (dispatch, getState) => {
    const { profile: { profile }, conversations: { chats } } = getState()
    const { conversation, messages, messageToReply } = chats[chatId]
    if (!chats[chatId] || !conversation) return
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { media, ...attributesRest } = attributes || {}
    const repliedMessage = isTrueOrZero(messageToReply?.index)
      ? { repliedMessage: messageToReply?.index || 0 }
      : null

    const localMessageObj = {
      author: profile?.uid ?? '',
      dateCreated: new Date(),
      dateUpdated: new Date(),
      attributes: { ...attributesRest, ...repliedMessage },
      index: messages?.length || 0,
      sid: (Math.random() + 1).toString(36).substring(7),
      aggregatedDeliveryReceipt: null,
      isSending: true
    } as Message | MessageType

    if (typeof message === 'string') {
      dispatch(sendTextOrMediaMessage(conversation, localMessageObj, message, repliedMessage, attributes))
    } else if (message) {
      dispatch(sendAudioMessage(conversation, localMessageObj, message, repliedMessage, attributesRest))
    }
  }

export const updateMessageAttribute = (
  message: Message
): ThunkType =>
  async (dispatch) => {
    const updatedAttributes = { ...(message.attributes as Record<string, any>), isListened: true }
    const result = await message.updateAttributes(updatedAttributes)
    dispatch(actions.updateMessageContent(result))
  }

export const setMessagesLoadingForChat =
  (conversation: Conversation.Conversation): ThunkType =>
    async (dispatch, getState) => {
      const { conversations: { chats } } = getState()
      if (!chats[conversation.sid].hasPrevPage) return
      dispatch(actions.setMessagesLoadingForChat(conversation.sid))
      conversation.getMessages(30).then(({ hasPrevPage, items }: any) => {
        dispatch(actions.addMessages(items, conversation.sid, hasPrevPage))
      })
    }

export const introduceGroupChat =
  (chatUid: string, introduceTo: ContactsType, userToIntroduce: ContactsType): ThunkType =>
    async (dispatch, getState) => {
      const { conversations: { client }, profile: { profile } } = getState()
      if (!profile) return
      const conversation = await client
        ?.getConversationBySid(chatUid)
        .catch(() => null)

      if (conversation) {
        const messages = await conversation.getMessages(1)
        const chat: ChatType = {
          [chatUid]: {
            chat: chatUid,
            name: introduceTo.displayName || '',
            photoUrl: introduceTo.photoURL,
            photo: introduceTo.photo,
            messages: messages.items,
            unreadMessageCount: 0,
            conversation,
            friendlyName: conversation.friendlyName || '',
            hasPrevPage: true,
            remoteParticipants: [
              {
                uid: introduceTo.uid, photo: introduceTo.photo, photoUrl: introduceTo.photoURL, displayName: introduceTo.displayName || ''
              },
              {
                uid: userToIntroduce.uid, photo: userToIntroduce.photo, photoUrl: userToIntroduce.photoURL, displayName: userToIntroduce.displayName || ''
              }
            ]
          }
        }
        dispatch(actions.addChat(chat))
      }
    }

export const openOfferOrFavorChatWithUser = (
  profile: ContactsType, vacancyId: string, onFinish?: () => void
): ThunkType => async (dispatch, getState) => {
  const { uid } = profile
  const {
    conversations: { isChatsLoaded, isOffersAndFavorsLoaded, chats }, profile: {
      profile: myProfile, favoredVacancies, offeredVacancies
    }
  } = getState()

  if (!myProfile) return
  if (!(isChatsLoaded && isOffersAndFavorsLoaded)) {
    dispatch(actions.setParticipantsToOpenChatWith([profile], vacancyId, null))
    onFinish?.()
    return
  }

  const allOffersAndFavors = [...favoredVacancies, ...offeredVacancies]
  const offerOrFavorVacancy = allOffersAndFavors.find(
    (offerOrFavor) => offerOrFavor.vacancyId === vacancyId
      && (uid === offerOrFavor?.employee?.uid || uid === offerOrFavor?.employer?.uid)
  )
  if (offerOrFavorVacancy) {
    if (!chats?.[offerOrFavorVacancy.chat]) {
      await dispatch(addChat(offerOrFavorVacancy.chat))
    }
    dispatch(actions.setOpenedChat(offerOrFavorVacancy.chat))
    dispatch(actions.setParticipantsToOpenChatWith([]))
    onFinish?.()
  } else {
    await dispatch(favorVacancy(vacancyId, profile, onFinish))
  }
}

export const openSpecialtyChatWithUser = (
  profile: ContactsType, specialtyId: string, onFinish?: () => void
): ThunkType => async (dispatch, getState) => {
  const { uid } = profile
  const {
    conversations: { isChatsLoaded, isOffersAndFavorsLoaded, chats },
    profile: {
      profile: myProfile,
      favoredSpecialties
    }
  } = getState()
  if (!myProfile) return
  if (!(isChatsLoaded && isOffersAndFavorsLoaded)) {
    dispatch(actions.setParticipantsToOpenChatWith([profile], null, specialtyId))
    onFinish?.()
    return
  }
  const specialtyItem = favoredSpecialties.find(
    (response) => response.specialtyId === specialtyId && uid === response.userUid
  )
  if (specialtyItem) {
    if (!chats?.[specialtyItem.chat]) {
      await dispatch(addChat(specialtyItem.chat))
    }
    dispatch(actions.setOpenedChat(specialtyItem.chat))
    dispatch(actions.setParticipantsToOpenChatWith([]))
    onFinish?.()
  } else {
    await dispatch(favorSpecialty(specialtyId, profile, onFinish))
  }
}

export const openChatWithUser = (profiles: ContactsType[] | [], redirect?: () => void, chatId?: string): ThunkType =>
  async (dispatch, getState) => {
    const isGroupChat = profiles.length > 1
    const {
      conversations: {
        client, chats, isChatsLoaded
      }, profile: { profile: myProfile }
    } = getState()
    if (!myProfile) return
    if (!isChatsLoaded) {
      dispatch(actions.setParticipantsToOpenChatWith(profiles))
      redirect?.()
      return
    }

    const getChatsParticipants = async (
      conversation: Conversation.Conversation,
      currentUserUid: string
    ) => {
      try {
        const participants: Participant[] = await conversation.getParticipants()
        const participantsList = participants.reduce<string[]>(
          (acc, { identity }) => {
            if (currentUserUid === identity) return acc
            return identity ? [...acc, identity] : acc
          },
          []
        )
        return participantsList
      } catch (error) {
        return []
      }
    }

    const addUsersBrief = async (uids: string[]): Promise<UserBriefInfo[] | []> => {
      try {
        const response = await usersAPI.getUsersBrief(uids)
        return response.profiles || []
      } catch (error) {
        return []
      }
    }
    const uid = isGroupChat
      ? `${profiles[0].uid}${CHAT_NAME_UID_SEPARATOR}${profiles[1].uid}`
      : profiles[0]?.uid

    const chatToOpen = chatId || getChatIdForUsers(chats, isGroupChat, profiles, uid)
    if (chatToOpen) {
      dispatch(actions.setOpenedChat(chatToOpen))
      redirect?.()
    } else {
      const createdChat: { chat_sid: string, status: string } = await usersAPI
        .createChat(uid)
        .catch(() => null)
      const conversation = await client
        ?.getConversationBySid(createdChat?.chat_sid)
        .catch(() => null)
      if (conversation) {
        let users: UserBriefInfo[] = profiles
        if (!users) {
          const chatParticipants = await getChatsParticipants(conversation, myProfile.uid) || []
          users = await addUsersBrief(chatParticipants)
        }
        const {
          displayName, photoURL, photo
        } = users[0]
        const messages = await conversation.getMessages(1)
        const chat: ChatType = {
          [createdChat.chat_sid]: {
            chat: createdChat.chat_sid,
            name: displayName,
            photoUrl: photoURL,
            photo,
            messages: messages.items,
            unreadMessageCount: 0,
            conversation,
            friendlyName: isGroupChat ? uid : conversation.friendlyName || '',
            hasPrevPage: true,
            remoteParticipants: users.map(({ uid, photoURL, displayName }) => ({
              uid,
              photoUrl: photoURL || '',
              photo: photo || '',
              displayName: displayName || ''
            }))
          }
        }
        dispatch(actions.addChat(chat))
        dispatch(actions.setOpenedChat(createdChat.chat_sid))
      }
      redirect?.()
    }
    dispatch(actions.setParticipantsToOpenChatWith([]))
  }

export const addChat =
  (chatId: string, userUids?: string[]): ThunkType<ChatType[keyof ChatType] | null> =>
    async (dispatch, getState) => {
      const {
        conversations: { client },
        profile: { profile }
      } = getState()

      const conversation = await client?.getConversationBySid(chatId)
      if (!conversation || !profile) return null

      const messages = await conversation?.getMessages(1)
      const userUidsUpdated: string[] = userUids?.length
        ? userUids
        : getChatParticipants(conversation, profile.uid)
      const remoteParticipants: RemoteParticipantType[] = await Promise.all(
        userUidsUpdated.map(async (uid) => {
          const user = await dispatch(addMemoizedUser(uid, false, {}))

          return {
            uid,
            photoUrl: user?.photoURL || '',
            photo: user?.photo || '',
            displayName: user?.displayName || '',
            isDeleted: !user
          } as RemoteParticipantType
        })
      )
      if (!remoteParticipants.length) {
        remoteParticipants.push({
          uid: '',
          photoUrl: '',
          photo: '',
          displayName: '',
          isDeleted: true
        })
      }

      const chat: ChatType = {
        [chatId]: {
          chat: chatId,
          name: remoteParticipants[0].displayName,
          photoUrl: remoteParticipants[0].photoUrl,
          photo: '',
          messages: messages?.items ?? [],
          unreadMessageCount: 0,
          conversation,
          friendlyName: conversation.friendlyName || '',
          hasPrevPage: true,
          remoteParticipants
        }
      }

      dispatch(actions.addChat(chat))
      dispatch(updateUnreadCount(conversation.sid))
      return chat[chatId]
    }

export const initializeOpenedChat = (openedChatId: string): ThunkType =>
  async (dispatch, getState) => {
    const {
      profile: { profile },
      conversations: { chats, isChatsLoaded }
    } = getState()

    if (!profile) return
    if (!chats?.[openedChatId] && isChatsLoaded) {
      await dispatch(addChat(openedChatId))
    }
    dispatch(actions.setOpenedChat(openedChatId))
  }
