import { useSelector } from 'react-redux'
import { CopyButton } from 'common/components/CopyButton'
import { useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { Typography, TypographyVariants } from 'common/typography'
import { getInviteLink } from 'common/utils/dynamicLinks'
import EmptyMyNetwork from 'features/Contacts/Network/assets/emptyMyNetwork.svg'
import MobilyEmptyMyNetwork from 'features/Contacts/Network/assets/mobileEmptyMyNetwork.svg'
import { getMyUid } from 'features/MyProfile/selectors'
import { selectInviteViaLinkTranslations, selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

export const WelcomeContacts = () => {
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const isMobile = useMobileMediaQuery()
  const myUid = useSelector(getMyUid)
  const inviteLink = getInviteLink(myUid)
  const inviteViaLinkTranslations = useSelector(selectInviteViaLinkTranslations)
  return (
    <div className={styles.emptyContainer}>
      <>
        <img
          className={styles.emptyImage}
          src={isMobile ? MobilyEmptyMyNetwork : EmptyMyNetwork}
          alt="My Network"
        />
        <div className={styles.emptyContainerInfo}>
          <Typography variant={TypographyVariants.Desktop_UI_XXL_Medium} tag="p" className={styles.title}>
            {myNetworkTranslations.contactsEmptyTitle}
          </Typography>
          <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="p" className={styles.description}>
            {myNetworkTranslations.contactsEmptyDescription}
          </Typography>

          <CopyButton
            shareLink={inviteLink}
            title={inviteViaLinkTranslations.copyLinkShort}
            copiedTitle={inviteViaLinkTranslations.copyToClipBoardShort}
            copyButtonContainer={styles.copyButtonContainer}
            showLink
          />
        </div>
      </>
    </div>
  )
}
