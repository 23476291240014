import {
  FC, useEffect, useMemo, useState
} from 'react'
import { InView } from 'react-intersection-observer'

interface IVirtualScroll {
  list: (string | { key: string, label: string })[];
  itemsPerView: number;
  itemProps: object;
  wrapper: FC<any>;
  renderer?: (item: string[]) => string
  getKey?: (item: (string | { key: string, label: string })) => string
}

export const VirtualScroll: FC<IVirtualScroll> = ({
  list, itemsPerView, itemProps, wrapper: Wrapper, renderer, getKey
}) => {
  const [endIndex, setEndIdx] = useState<number>(itemsPerView)

  useEffect(() => {
    setEndIdx(itemsPerView)
  }, [itemsPerView])

  return useMemo(() => {
    return (
      <>
        {list.slice(0, endIndex).map((item: any, index: number) => {
          const isLast = index === endIndex - 1 && index !== list.length - 1
          if (isLast) {
            return (
              <InView
                key={getKey ? getKey(item) : index}
                as="div"
                onChange={(inView) => {
                  if (inView) {
                    setEndIdx((s) => s + 1)
                  }
                }}
              >
                <Wrapper {...itemProps} renderer={renderer}>{item}</Wrapper>
              </InView>
            )
          }
          return (
            <Wrapper
              key={getKey ? getKey(item) : index}
              {...itemProps}
              renderer={renderer}
            >
              {item}
            </Wrapper>
          )
        })}
      </>
    )
  }, [list, endIndex, Wrapper, itemProps])
}
