import { RefObject, useEffect, useState } from 'react'

export const useInputFocusDetect = (inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement>) => {
  const [isInputFocused, setIsInputFocused] = useState(false)

  useEffect(() => {
    const handleFocus = () => setIsInputFocused(true)
    const handleBlur = () => setIsInputFocused(false)

    if (inputRef.current) {
      inputRef.current.addEventListener('focus', handleFocus)
      inputRef.current.addEventListener('blur', handleBlur)
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('focus', handleFocus)
        inputRef.current.removeEventListener('blur', handleBlur)
      }
    }
  }, [inputRef.current])

  return { isInputFocused }
}
