import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TOAST_DURATION } from 'common/constants'
import { actions as actionsToast } from 'features/ToastManager/actions'
import { Toast } from 'features/ToastManager/components/Toast'
import { selectToasts } from 'features/ToastManager/selectors'
import styles from './styles.module.sass'

export const ToastManager = () => {
  const toasts = useSelector(selectToasts)
  const dispatch = useDispatch()

  useEffect(() => {
    toasts.forEach((toast) => {
      const timer = setTimeout(() => {
        if (toasts.length) {
          dispatch(actionsToast.removeToast(toast.id, toast.type))
        }
      }, TOAST_DURATION)
      return () => clearTimeout(timer)
    })
  }, [toasts, dispatch])

  return (
    <div className={styles.toastContainer}>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          type={toast.type}
          message={toast.message}
          onClose={() => {
            dispatch(actionsToast.removeToast(toast.id, toast.type))
          }}
        />
      ))}
    </div>
  )
}
