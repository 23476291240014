import type { ThunkAction } from 'redux-thunk'
import type { Auth } from 'firebase/auth'
import { usersAPI } from 'api'
import { STATUS_VACANCIES } from 'common/constants'
import type { AppStateType } from 'common/types'
import type { ActionTypes, NotificationHistoryTypes, ThunkType } from 'features/Home/types'
import type { ProfileType, VacancyType } from 'features/MyProfile/types'

export enum ACTION_TYPES {
  SET_NOTIFICATION_HISTORY_IDS = 'SET_NOTIFICATION_HISTORY_IDS',
  PREPEND_NOTIFICATION_HISTORY_ID = 'PREPEND_NOTIFICATION_HISTORY_ID',
  DELETE_JOB_OFFER_NOTIFICATION = 'DELETE_JOB_OFFER_NOTIFICATION',
  DELETE_JOB_OFFER_NOTIFICATIONS = 'DELETE_JOB_OFFER_NOTIFICATIONS',
  DELETE_INTRO_NOTIFICATION = 'DELETE_INTRO_NOTIFICATION',
  ADD_MEMOIZED_USER = 'HOME__SET_MEMOIZED_USERS',
  ADD_BULK_MEMOIZED_USERS = 'HOME__SET_BULK_MEMOIZED_USERS',
  DELETE_MEMOIZED_USER = 'HOME__DELETE_MEMOIZED_USER',
  SET_USERS_VACANCIES = 'SET_USERS_VACANCIES',
  SET_USERS_VACANCIES_LOADING = 'SET_USERS_VACANCIES_LOADING',
  SET_USERS_TRUSTED = 'SET_USERS_TRUSTED',
  ADD_DELETED_USER = 'ADD_DELETED_USER',
  SET_ARCHIVED_NOTIFICATION_HISTORY_IDS = 'SET_ARCHIVED_NOTIFICATION_HISTORY_IDS',
  PREPEND_ARCHIVED_NOTIFICATION_HISTORY_ID = 'PREPEND_ARCHIVED_NOTIFICATION_HISTORY_ID',
  DELETE_ARCHIVED_JOB_OFFER_NOTIFICATION = 'DELETE_ARCHIVED_JOB_OFFER_NOTIFICATION',
  DELETE_ARCHIVED_JOB_OFFER_NOTIFICATIONS = 'DELETE_ARCHIVED_JOB_OFFER_NOTIFICATIONS'
}
export const actions = {
  setNotificationHistoryIds: (ids: string[], type: NotificationHistoryTypes) => (
    { type: ACTION_TYPES.SET_NOTIFICATION_HISTORY_IDS, payload: { ids, type } } as const
  ),
  prependNotificationHistoryId: (notificationId: string, type: NotificationHistoryTypes) => (
    { type: ACTION_TYPES.PREPEND_NOTIFICATION_HISTORY_ID, payload: { notificationId, type } } as const
  ),
  deleteJobOfferNotification: (notificationId: string) => (
    { type: ACTION_TYPES.DELETE_JOB_OFFER_NOTIFICATION, payload: { notificationId } } as const
  ),
  deleteJobOfferNotifications: (notificationIds: string[]) => (
    { type: ACTION_TYPES.DELETE_JOB_OFFER_NOTIFICATIONS, payload: { notificationIds } } as const
  ),
  deleteIntroNotification: (notificationId: string) => (
    { type: ACTION_TYPES.DELETE_INTRO_NOTIFICATION, payload: { notificationId } } as const
  ),
  addMemoizedUser: (user: ProfileType & { vacancies: VacancyType[] }, isFullData?: boolean) => (
    { type: ACTION_TYPES.ADD_MEMOIZED_USER, payload: { user, isFullData } } as const
  ),
  addBulkMemoizedUsers: (users: { [uid: string]: ProfileType & { vacancies?: VacancyType[] } }) => (
    { type: ACTION_TYPES.ADD_BULK_MEMOIZED_USERS, users } as const
  ),
  addDeletedUser: (uid: string) => (
    { type: ACTION_TYPES.ADD_DELETED_USER, uid } as const
  ),
  deleteMemoizedUser: (payload: string) => ({ type: ACTION_TYPES.DELETE_MEMOIZED_USER, payload } as const),
  setOtherUserVacancies: (vacancies: VacancyType[], uid: string) => (
    {
      type: ACTION_TYPES.SET_USERS_VACANCIES, vacancies, uid, isLoading: false
    } as const
  ),
  setOtherUserTrusted: (trusted: ProfileType[], uid: string) => (
    {
      type: ACTION_TYPES.SET_USERS_TRUSTED, trusted, uid
    } as const
  ),
  setOtherUserVacanciesLoading: (isLoading: boolean) => (
    { type: ACTION_TYPES.SET_USERS_VACANCIES_LOADING, payload: isLoading } as const
  ),
  setArchivedNotificationHistoryIds: (ids: string[], type: NotificationHistoryTypes) => (
    { type: ACTION_TYPES.SET_ARCHIVED_NOTIFICATION_HISTORY_IDS, payload: { ids, type } } as const
  ),
  prependArchivedNotificationHistoryId: (notificationId: string, type: NotificationHistoryTypes) => (
    { type: ACTION_TYPES.PREPEND_ARCHIVED_NOTIFICATION_HISTORY_ID, payload: { notificationId, type } } as const
  ),
  deleteArchivedJobOfferNotification: (notificationId: string) => (
    { type: ACTION_TYPES.DELETE_ARCHIVED_JOB_OFFER_NOTIFICATION, payload: { notificationId } } as const
  ),
  deleteArchivedJobOfferNotifications: (notificationIds: string[]) => (
    { type: ACTION_TYPES.DELETE_ARCHIVED_JOB_OFFER_NOTIFICATIONS, payload: { notificationIds } } as const
  )
}

// TODO: user type
export const addMemoizedUser = (
  uid: string,
  isFullData: boolean,
  { user, onFinish }: { user?: any, onFinish?: () => void, withErrorModal?: boolean },
  username?: string
): ThunkAction<Promise<ProfileType | null>, AppStateType, Auth, ActionTypes> =>
  async (dispatch, getState) => {
    if (isFullData ? user?.isFullData : user) {
      dispatch(actions.addMemoizedUser(user))
      onFinish?.()
      return user
    }
    const { surf: { memoizedUsers } } = getState()
    if (isFullData ? memoizedUsers[uid]?.isFullData : memoizedUsers[uid]) {
      onFinish?.()
      return memoizedUsers[uid]
    }
    try {
      const response = username
        ? await usersAPI.getUserByUsername(username)
        : await usersAPI.getUser(uid)
      if (response?.uid) {
        dispatch(actions.addMemoizedUser(response, true))
      }
      return response
    } catch (error: any) {
      return null
    } finally {
      onFinish?.()
    }
  }

export const getUserVacancies = (uid: string): ThunkType => async (dispatch, getState) => {
  const { surf: { otherUsersVacancies } } = getState()
  // if (!otherUsersVacancies?.[uid]?.length) dispatch(actions.setOtherUserVacanciesLoading(true))
  const response = await usersAPI.getUserVacancies(uid).catch(() => null)
  if (!response?.vacancies && !otherUsersVacancies?.[uid]?.length) {
    dispatch(actions.setOtherUserVacanciesLoading(false))
    return
  }
  const openedVacancies = response?.vacancies.filter((vacancy: any) => vacancy?.status !== STATUS_VACANCIES.CLOSED)
  dispatch(actions.setOtherUserVacancies(openedVacancies, uid))
}
