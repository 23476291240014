import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import cn from 'classnames'
import {
  CHAT_CONSTATNS, NAVBAR_ORIGIN, ROUTE_PARAMS, ROUTES
} from 'common/constants'
import { AuthIcon } from 'common/icons/AuthIcon'
import { ExploreIcon } from 'common/icons/ExploreIcon'
import { ExploreIconFilled } from 'common/icons/ExploreIconFilled'
import { MailIcon } from 'common/icons/MailIcon'
import { MailIconFilled } from 'common/icons/MailIconFilled'
import { Unlock } from 'common/icons/Unlock'
import { UsersIcon } from 'common/icons/UsersIcon'
import { UsersIconFilled } from 'common/icons/UsersIconFilled'
import { ListingFilledIcon } from 'common/icons_V2/ListingFilledIcon'
import { ListingIcon } from 'common/icons_V2/ListingIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { getQueryObject } from 'common/utils/url'
import { getIsAdminMode } from 'features/Admin/selectors'
import { getAuth } from 'features/Auth/selectors'
import { getOpenedChat, getUnreadChatsCount } from 'features/Conversations/selectors'
import { actions as actionsFilter } from 'features/FilterItems/actions'
import { getMyUid } from 'features/MyProfile/selectors'
import { actions as actionsSearch } from 'features/SearchInput_V2/actions'
import { NAVBAR_KEYS, SEARCH_PARAMS } from 'features/Translations/constants'
import { useSetPageScrollYPositionContext } from 'providers/SetPageScrollYPositionProvider'
import styles from './styles.module.sass'

interface IPage {
  url: string;
  icon: React.ReactNode;
  activeIcon?: React.ReactNode;
  unreadChatsCount?: number;
  hideOnMobile: boolean;
}

const pagesForUnauthorized: IPage[] = [
  {
    url: ROUTES.AUTH,
    icon: <AuthIcon />,
    hideOnMobile: false
  }
]

const pagesAdmin: IPage[] = [
  {
    url: ROUTES.ADMIN,
    icon: <Unlock />,
    hideOnMobile: false
  }
]

export const NavBar = () => {
  const dispatch = useDispatch()
  const auth = useSelector(getAuth)
  const myUid = useSelector(getMyUid)
  const isAdminMode = useSelector(getIsAdminMode)
  const location = useLocation()
  const unreadChatsCount = useSelector(
    (state) => getUnreadChatsCount(state, location.pathname === NAVBAR_KEYS.MESSAGES)
  )
  const openChat = useSelector(getOpenedChat)
  const isHideNavbar = location.pathname === NAVBAR_KEYS.MESSAGES && !!openChat
  const { setPageScrollYPosition, resetPageScrollYPosition } = useSetPageScrollYPositionContext()
  const searchParams = getQueryObject(location.search)

  const pages: IPage[] = [
    {
      url: NAVBAR_KEYS.EXPLORE,
      icon: <ExploreIcon />,
      activeIcon: <ExploreIconFilled />,
      hideOnMobile: false
    },
    {
      url: NAVBAR_KEYS.MY_NETWORK,
      icon: <UsersIcon />,
      activeIcon: <UsersIconFilled />,
      hideOnMobile: false
    },
    {
      url: NAVBAR_KEYS.MESSAGES,
      icon: <MailIcon />,
      activeIcon: <MailIconFilled />,
      unreadChatsCount,
      hideOnMobile: false
    },
    {
      url: NAVBAR_KEYS.LISTINGS.replace(ROUTE_PARAMS.UID, myUid),
      icon: <ListingIcon />,
      activeIcon: <ListingFilledIcon />,
      hideOnMobile: false
    },
    ...(isAdminMode ? pagesAdmin : [])
  ]

  if (!auth) {
    return (
      <div className={`${styles.container} ${styles.unauthorized}`}>
        {pagesForUnauthorized.map(({ url, icon }) => (
          <NavLink
            key={url + icon}
            to={url}
            className={styles.navLink}
            activeClassName={styles.activeLink}
          >
            {icon}
          </NavLink>
        ))}
      </div>
    )
  }

  const handlePageScrollAndFilters = (url: string) => {
    if (location.pathname === ROUTES.EXPLORE && url === NAVBAR_KEYS.EXPLORE) {
      resetPageScrollYPosition()
      dispatch(actionsFilter.setHomeFilters({ homeFilters: [] }))
      dispatch(actionsFilter.setListingFilters({ listingFilters: [] }))
      dispatch(actionsSearch.setHomeSearch({ homeSearch: '' }))
    }

    if (location.pathname === ROUTES.EXPLORE && url !== NAVBAR_KEYS.EXPLORE) {
      setPageScrollYPosition()
    }
  }
  const whitelistedParams = [
    SEARCH_PARAMS.HOME_SELECTED_CARD,
    SEARCH_PARAMS.LISTING_SELECTED_CARD,
    SEARCH_PARAMS.ARCHIVED_SELECTED_CARD
  ]
  const searchP = whitelistedParams.reduce((acc, param) => {
    let newVal = acc
    if (searchParams[param]) {
      if (newVal) newVal += '&'
      if (!newVal) newVal += '?'
      const str = `${param}=${searchParams[param]}`
      newVal += str
    }
    return newVal
  }, '')

  return (
    <div className={styles.gradientBg}>
      <div className={cn(styles.container, isHideNavbar && styles.hideNavbar)}>
        {pages.map(({
          url, icon, activeIcon, hideOnMobile, unreadChatsCount
        }) => (
          <NavLink
            key={url}
            to={{
              pathname: url,
              search: searchP,
              state: { from: NAVBAR_ORIGIN }
            }}
            className={cn(styles.navLink, hideOnMobile && styles.hideOnMobile)}
            activeClassName={url === ROUTES.ADMIN ? styles.activeLinkAdmin : styles.activeLink}
            onClick={() => handlePageScrollAndFilters(url)}
            exact
          >
            <div className={styles.iconContainer}>
              {!!unreadChatsCount && (
                <Typography
                  className={cn(styles.messageUnreadIcon, location.pathname === url && styles.activeUnreadIcon)}
                  variant={TypographyVariants.Desktop_UI_XS_Bold}
                  tag="div"
                >
                  {unreadChatsCount > CHAT_CONSTATNS.MAX_UNREAD_COUNT
                    ? CHAT_CONSTATNS.MAX_UNREAD_DISPLAY
                    : unreadChatsCount}
                </Typography>
              )}
              {location.pathname === url ? activeIcon || icon : icon}
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  )
}
