import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import type { Unsubscribe } from 'firebase/firestore'
// @ts-ignore
import incomingCallAudio from 'common/audio/incomingCall.mp3'
import type { Conversation as ConversationType, Message } from '@twilio/conversations'
import { profileAPI, usersAPI } from 'api'
import { Image } from 'common/components/Image'
import {
  ConversationListeners,
  LOCALISE_SEPARATORS,
  MESSAGE_AUTHOR,
  MESSAGE_LISTENER_BLACKLIST_ROUTES,
  ROUTES,
  SYSTEM_MESSAGE_TYPE
} from 'common/constants'
import { CloseIcon } from 'common/icons/CloseIcon'
import phoneEnd from 'common/images/phoneEnd.png'
import videoStart from 'common/images/videoStart.png'
import type { RootState } from 'common/types'
import { getAuthUid } from 'features/Auth/selectors'
import {
  actions as actionsConversations,
  openChatWithUser,
  processConversations,
  updateUnreadCount
} from 'features/Conversations/actions'
import { isAudioMessage } from 'features/Conversations/components/Chat/Messages/utils'
import { getChatsLoaded, getConversationClient } from 'features/Conversations/selectors'
import { selectMemoizedUsers } from 'features/Home/selectors'
import { declineCall, onProfileUpdate, onReceiveNotifications } from 'features/MyProfile/actions'
import { getMyUid } from 'features/MyProfile/selectors'
import type { ContactsType } from 'features/MyProfile/types'
import { actions } from 'features/Notifications/actions'
import { Notification } from 'features/Notifications/components/Notification'
import { getIsLoadedHistory, getMaxNotificationDate } from 'features/Notifications/selectors'
import { selectNotificationsTranslations } from 'features/Translations/selectors'
import { connectToVideoRoom } from 'features/VideoChat/actions'
import { getVideoRoom } from 'features/VideoChat/selectors'
import useSound from 'use-sound'
import styles from './styles.module.sass'

export const Notifications = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const uid = useSelector(getMyUid)
  const authUid = useSelector(getAuthUid)
  const users = useSelector(selectMemoizedUsers)
  const history = useHistory()
  const room = useSelector(getVideoRoom)
  const isLoadedHistory = useSelector(getIsLoadedHistory)
  const maxNotificationDate = useSelector(getMaxNotificationDate)
  const [isLoadedSound, setIsLoadedSound] = useState(false)
  const conversationClient = useSelector(getConversationClient)
  const notificationsTranslations = useSelector(selectNotificationsTranslations)
  const isChatsLoaded = useSelector(getChatsLoaded)

  const [playIncomingCall, { stop }] = useSound(incomingCallAudio, {
    loop: true,
    onload: () => {
      setIsLoadedSound(true)
    }
  })
  const {
    anyMsgs, receivedChatMsgs, incomingCall
  } = useSelector((state: RootState) => state.notifications)

  useEffect(() => {
    const addNotification = (message: Message, user: ContactsType) => {
      dispatch(
        actions.addReceivedChatMsg(
          message,
          user
        )
      )
    }

    const getNotificationReceiver = (
      message: Message
    ) => {
      if (message.author !== MESSAGE_AUTHOR.SYSTEM) {
        return addNotification(message, users[message.author || ''])
      }
      const actor = (message as Message & { attributes?: { uid?: string } }).attributes?.uid || ''
      if (actor === uid) return null
      const user = users[actor]
      if (!user) {
        return usersAPI.getUser(actor).then((response: ContactsType) => {
          addNotification(message, response)
        })
      }
      addNotification(message, user)
      return user
    }

    const isWhitelisted = !MESSAGE_LISTENER_BLACKLIST_ROUTES.includes(location.pathname)
    const onMessageAdded = async (message: Message) => {
      if (message.author && message.author !== uid) {
        if (users?.[message.author] || message.author === MESSAGE_AUTHOR.SYSTEM) {
          await getNotificationReceiver(message)
          dispatch(actionsConversations.addMessage(message, message.conversation.sid))
        } else {
          usersAPI.getUser(message.author).then((user) => {
            dispatch(actions.addReceivedChatMsg(message, user))
            dispatch(actionsConversations.addMessage(message, message.conversation.sid))
          })
        }
      }
    }
    if (isWhitelisted) {
      conversationClient?.on(ConversationListeners.MESSAGE_ADDED, onMessageAdded)
    }
    return () => {
      if (isWhitelisted) {
        conversationClient?.off(ConversationListeners.MESSAGE_ADDED, onMessageAdded)
      }
    }
  }, [conversationClient, location.pathname])

  useEffect(() => {
    const onConversationAdded = (conversation: ConversationType) => {
      if (conversationClient) dispatch(processConversations([conversation], conversationClient))
    }

    if (uid && isChatsLoaded && conversationClient) {
      conversationClient?.on(ConversationListeners.CONVERSATION_ADDED, onConversationAdded)
    }
    return () => {
      if (uid && isChatsLoaded && conversationClient) {
        conversationClient?.off(ConversationListeners.CONVERSATION_ADDED, onConversationAdded)
      }
    }
  }, [uid, isChatsLoaded, conversationClient])

  useEffect(() => {
    const onParticipantUpdated = (
      { conversation, author }: Message
    ) => {
      if (author !== uid) {
        dispatch(updateUnreadCount(conversation.sid))
      }
    }

    if (conversationClient) {
      conversationClient.on(ConversationListeners.MESSAGE_ADDED, onParticipantUpdated)
    }

    return () => {
      if (conversationClient) {
        conversationClient.off(ConversationListeners.MESSAGE_ADDED, onParticipantUpdated)
      }
    }
  }, [conversationClient])

  useEffect(() => {
    const fetchConversationClientToken = async () => {
      if (conversationClient) {
        const conversationsTokens = await profileAPI.getChatToken()
        conversationClient.updateToken(conversationsTokens.tokens.fcm)
      }
    }

    if (conversationClient) {
      conversationClient.on(ConversationListeners.TOKEN_ABOUT_TO_EXPIRE, fetchConversationClientToken)
    }

    return () => {
      if (conversationClient) {
        conversationClient.off(ConversationListeners.TOKEN_ABOUT_TO_EXPIRE, fetchConversationClientToken)
      }
    }
  }, [conversationClient])

  useEffect(() => {
    if (!incomingCall) stop()
  }, [incomingCall])

  useEffect(() => {
    if (incomingCall && isLoadedSound) {
      playIncomingCall()
    }
  }, [incomingCall, isLoadedSound, playIncomingCall])

  useEffect(() => {
    let unsubscribe: Unsubscribe = () => {}
    if (uid && isLoadedHistory && (maxNotificationDate !== null)) {
      unsubscribe = onReceiveNotifications(uid, dispatch, room, maxNotificationDate)
    }
    return () => {
      unsubscribe()
    }
  }, [uid, room, isLoadedHistory, dispatch, maxNotificationDate])

  useEffect(() => {
    let unsubscribeProfile: Unsubscribe = () => {}
    if (authUid) {
      unsubscribeProfile = onProfileUpdate(authUid, dispatch)
    }
    return () => {
      unsubscribeProfile()
    }
  }, [authUid])

  const replyWithVideo = () => {
    if (incomingCall) {
      // TODO: Send accept call with deviceId to BE for getting push when new participant joins
      const { room, token } = incomingCall

      stop()
      dispatch(connectToVideoRoom(room, token))
      dispatch(actions.removeIncomingCall())
    }
  }

  const onDeclineCall = () => {
    if (incomingCall) {
      stop()
      dispatch(actions.removeIncomingCall())
      dispatch(declineCall(incomingCall.uid))
    }
  }

  const removeReceivedChatMsg = (sid: string, e?: React.MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation()
    dispatch(actions.removeReceivedChatMsg(sid))
  }

  const removeReceivedAllMsg = () => {
    dispatch(actions.removeReceivedAllMsg())
  }
  const removeAnyMsg = (uid: string) => {
    dispatch(actions.removeAnyMsg(uid))
  }

  const viewMessage = (msg: Message, user: ContactsType | null) => {
    removeReceivedChatMsg(msg.sid)
    dispatch(openChatWithUser(user ? [user] : [], () => {
      history.push(`${ROUTES.MESSAGES}?id=${msg?.conversation?.sid}`)
    }, msg.sid))
  }

  const renderMsgMedia = (msg: Message) => {
    if (msg.body) {
      return msg.body
    }
    const [media] = msg.attachedMedia || []
    if (media) {
      const { filename, contentType } = media
      if (contentType === 'application/pdf') {
        return filename
      }
      if (isAudioMessage(msg)) {
        return notificationsTranslations.voiceMessage
      }
      if (contentType.startsWith('image')) {
        return notificationsTranslations.photoMessage
      }
    }
    return ''
  }

  const renderNotification = (user: ContactsType | null, msg: Message) => {
    if (msg.body && msg.author === MESSAGE_AUTHOR.SYSTEM) {
      const attributes = msg?.attributes as { type?: string } || {}
      const jobName = (msg?.body || '').split('\n')?.[1]
      const isJobEdited =
        attributes?.type === SYSTEM_MESSAGE_TYPE.MODIFIED_RESUME ||
        attributes?.type === SYSTEM_MESSAGE_TYPE.MODIFIED_VACANCY ||
        attributes?.type === SYSTEM_MESSAGE_TYPE.CLOSED_RESUME ||
        attributes?.type === SYSTEM_MESSAGE_TYPE.CLOSED_VACANCY
      if (msg.author === MESSAGE_AUTHOR.SYSTEM && !isJobEdited && jobName) {
        const notificationBody = notificationsTranslations
          ?.jobNotificationMessage
          ?.replace(LOCALISE_SEPARATORS.USER, user?.displayName || '')
          ?.replace(LOCALISE_SEPARATORS.JOB, jobName || '')
        return (
          <div className={cn(styles.text, msg.attachedMedia?.[0] && styles.mediaText)}>
            {notificationBody}
          </div>
        )
      }
    }
    return (
      <>
        {user && (<div className={styles.displayName}>{user.displayName}</div>)}
        <div className={cn(styles.text, msg.attachedMedia?.[0] && styles.mediaText)}>
          {renderMsgMedia(msg)}
        </div>
      </>
    )
  }

  return (
    <>
      {(receivedChatMsgs.length > 0
        || anyMsgs.length > 0) && (
        <div className={styles.rightSideMsgsContainer}>
          <div className={styles.closeAllNotifications}>
            <div>Close all notifications</div>
            <div className={styles.close} onClick={() => removeReceivedAllMsg()}>
              <CloseIcon />
            </div>
          </div>
          {receivedChatMsgs.map(({ msg, user }) => {
            const userName = user?.displayName
            return (
              <Notification
                key={`notificationsContacts-${msg.sid}`}
                notificationId={msg.sid}
                removeMsg={(_: string, e?: React.MouseEvent<HTMLDivElement>) => removeReceivedChatMsg(msg.sid, e)}
                viewMessage={() => viewMessage(msg, user)}
              >
                <div>
                  {user && (
                    <div className={styles.photoContainer}>
                      <Image photoURL={user.photoURL || ''} photo={user.photo} alt={userName} width={50} />
                    </div>
                  )}
                  <div className={styles.contentContainer}>
                    {renderNotification(user, msg)}
                  </div>
                </div>
              </Notification>
            )
          })}
          {anyMsgs.map(({ msg, uid }) => (
            <Notification key={`notificationsContacts-${uid}`} notificationId={uid} removeMsg={() => removeAnyMsg(uid)}>
              <div>
                <div className={styles.contentContainer}>
                  <div className={styles.text}>{msg}</div>
                </div>
              </div>
            </Notification>
          ))}
        </div>
      )}

      {incomingCall && (
      <div className={styles.incomingCallContainer}>
        <div className={styles.photoContainer}>
          <Image
            photoURL={incomingCall.photoUrl}
            photo={incomingCall.photo}
          />
        </div>
        <div className={styles.displayName}>{incomingCall.name}</div>
        <div className={styles.event}>Incoming call...</div>
        <div className={styles.buttonsContainer}>
          <div className={styles.button} onClick={onDeclineCall}>
            <img src={phoneEnd} alt="Throw off" />
          </div>
          <div className={styles.button} onClick={replyWithVideo}>
            <img src={videoStart} alt="Reply with video" />
          </div>
        </div>
      </div>
      )}
    </>
  )
}
