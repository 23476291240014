import { FC, ReactChild } from 'react'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { Typography, TypographyVariants } from 'common/typography'
import { isTrueOrZero } from 'common/utils/numbers'
import { useProfileSections } from 'providers/ProfileSectionsProvider'
import styles from './styles.module.sass'

export type TabType = {
  key: string,
  title: string,
  route?: string, // Not passing this disables the tab
  icon?: ReactChild,
  count?: number,
  onClick?: () => void
}
interface ITabs {
  tabs: TabType[]
  activeTab: string
}

export const Tabs: FC<ITabs> = ({
  tabs,
  activeTab
}) => {
  const history = useHistory()
  const { activeSection, withDisable } = useProfileSections()
  const disable = !!activeSection

  const onTabClick = (route?: string, onClick?: () => void) => {
    if (disable) return
    if (route && activeTab !== route) {
      onClick?.()
      history.push(route)
    }
  }

  return (
    <div className={styles.tabsContainer}>
      {tabs.map(({
        key, title, route, icon, count, onClick
      }) => (
        <div
          key={key}
          className={cn(
            styles.tab,
            (!count || !route) && styles.disabledTab,
            disable && styles.transparent
          )}
          onClick={
            withDisable
              ? () => withDisable(onTabClick)(route, onClick)
              : () => onTabClick(route, onClick)
          }
        >
          <div>
            {icon}
            <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="div" className={styles.countAndTitle}>
              <div className={count ? '' : styles.emptyTab}>
                {title}
              </div>
              {isTrueOrZero(count) && (
                <div className={count ? styles.count : ''}>
                  {count}
                </div>
              )}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  )
}
