import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { ROUTES } from 'common/constants'
import { useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { Typography, TypographyVariants } from 'common/typography'
import { selectOnboardingTranslations, selectSupportTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface Props {
  hideOnMobile?: boolean
}

export const Footer: FC<Props> = ({ hideOnMobile = false }) => {
  const onboardingTranslations = useSelector(selectOnboardingTranslations)
  const supportTranslations = useSelector(selectSupportTranslations)
  const isMobile = useMobileMediaQuery()

  return (
    <footer className={cn(styles.footer, hideOnMobile && styles.hideOnMobile)}>
      <div className={styles.content}>
        {onboardingTranslations.footerCopyright}
        {(!hideOnMobile || !isMobile) && (
          <>
            <Link className={styles.linkText} to={ROUTES.TERMS_OF_SERVICE}>
              <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="p">
                {onboardingTranslations.textLegalTerms}
              </Typography>
            </Link>
            <Link className={styles.linkText} to={ROUTES.PRIVACY_POLICY}>
              <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="p">
                {onboardingTranslations.privacyPolicy}
              </Typography>
            </Link>
            <Link className={styles.linkText} to={ROUTES.SUPPORT}>
              <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="p">
                {supportTranslations.title}
              </Typography>
            </Link>
          </>
        )}
      </div>
    </footer>
  )
}
