import {
  FC, useCallback, useRef, useState
} from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { FileInput } from 'common/components/FileInput'
import { Image } from 'common/components/Image_V2'
import { PersonIcon } from 'common/icons_V2/PersonIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { CropperModal } from 'features/PhotoCropper_V2/CropperModal'
import { selectEditProfileTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IPhotoCropper {
  className?: string
  cropImage: (imageUrl: string) => Promise<void>
  initialPhoto: { photo: string, photoURL: string }
  isDataUploading?: boolean
  showUploadTitle?: boolean
  width?: undefined | number
}

export const PhotoCropper: FC<IPhotoCropper> = ({
  className, cropImage, initialPhoto, showUploadTitle = false, width = 156, isDataUploading = false
}) => {
  const profileTranslations = useSelector(selectEditProfileTranslations)
  const [image, setImage] = useState<any>(initialPhoto)
  const [isOpenPhotoEditor, setIsOpenPhotoEditor] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const fileInputOnClickRef = useRef<() => void>()

  const onInputChange = (file: File) => {
    setIsOpenPhotoEditor(true)
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const handleClick = useCallback(
    (onClick?: () => void) => {
      if (!isLoading && !isDataUploading && onClick) {
        onClick()
      }
    },
    [isLoading, isDataUploading]
  )

  return (
    <>
      <FileInput
        buttonComponent={({ onClick }) => {
          fileInputOnClickRef.current = onClick
          return (
            <div
              className={cn(styles.photoContainer, className)}
            >
              {isLoading ? (
                <div className={styles.personIcon}>
                  <PersonIcon size={124} />
                </div>
              ) : (
                <Image
                  photoURL={initialPhoto?.photoURL || ''}
                  photo={initialPhoto?.photo}
                  alt="photo"
                  width={width}
                  isRounded
                />
              )}
            </div>
          )
        }}
        onChangeFile={onInputChange}
        accept="image/png, image/gif, image/jpeg, image/tiff, image/x-icon, image/apng, image/bmp, image/pjpeg, image/webp"
      />
      {showUploadTitle && (
        <div onClick={() => handleClick(fileInputOnClickRef.current)}>
          <Typography variant={TypographyVariants.Body_1_Medium} tag="div" className={styles.uploadTitle}>
            {profileTranslations.setNewPhoto}
          </Typography>
        </div>
      )}

      <CropperModal
        isLoading={isLoading || isDataUploading}
        setIsLoading={setIsLoading}
        imageUrl={image}
        setImageUrl={setImage}
        cropImage={cropImage}
        isOpenPhotoEditor={isOpenPhotoEditor}
        setIsOpenPhotoEditor={setIsOpenPhotoEditor}
      />
    </>
  )
}
