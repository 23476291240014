import { FC, useState } from 'react'
import cn from 'classnames'
import { AboutElementWithDiscard } from 'features/MyProfile/components/ProfilePage/AboutElement'
import { ContactInfoWithDiscard } from 'features/MyProfile/components/ProfilePage/ContactInfo'
import { ProfileUrl } from 'features/MyProfile/components/ProfilePage/ProfileUrl'
import { TagsWithDiscard } from 'features/MyProfile/components/ProfilePage/Tags'
import { ProfileType } from 'features/MyProfile/types'
import styles from './styles.module.sass'

interface IAbout {
  profile: ProfileType;
  isOtherProfile: boolean;
  profileContentHeight?: number;
}

export const ProfileDetails: FC<IAbout> = ({
  profile,
  isOtherProfile,
  profileContentHeight
}) => {
  const {
    about, phoneNumber, tags, email, uid, username
  } = profile
  const [isOpenAboutElement, setIsOpenAboutElement] = useState(false)

  return (
    <div
      className={cn(styles.aboutContainer, styles.tabContent)}
      style={{ ...(profileContentHeight && { maxHeight: `calc(100vh - ${profileContentHeight}px)` }) }}
    >
      <AboutElementWithDiscard
        isOtherProfile={isOtherProfile}
        about={about || ''}
        isOpen={isOpenAboutElement}
        setIsOpenAboutElement={setIsOpenAboutElement}
        onClose={() => setIsOpenAboutElement(false)}
      />
      <TagsWithDiscard
        isOtherProfile={isOtherProfile}
        initialTags={tags || []}
        isOpen={isOpenAboutElement}
        setIsOpenAboutElement={setIsOpenAboutElement}
        onClose={() => setIsOpenAboutElement(false)}
      />
      <ContactInfoWithDiscard
        isOtherProfile={isOtherProfile}
        phoneNumber={phoneNumber}
        email={email}
        isOpen={isOpenAboutElement}
        setIsOpenAboutElement={setIsOpenAboutElement}
        onClose={() => setIsOpenAboutElement(false)}
      />
      <ProfileUrl uid={uid} username={username} isOtherProfile={isOtherProfile} />
    </div>
  )
}
