import { CSSProperties, FC, PropsWithChildren } from 'react'
import cn from 'classnames'
import styles from './styles.module.sass'

interface ITrust {
  onClick: () => void;
  closePopup: () => void;
  isDisabled?: boolean;
  style?: CSSProperties
}

export const Base: FC<PropsWithChildren<ITrust>> = ({
  onClick,
  children,
  closePopup,
  isDisabled,
  style
}) => {
  return (
    <div
      className={cn(
        styles.menuItem,
        isDisabled && styles.otherContact
      )}
      style={style}
      onClick={async () => {
        if (!isDisabled) {
          await onClick()
          closePopup()
        }
      }}
    >
      {children}
    </div>
  )
}
