import { ACTION_TYPES } from 'features/PublicTokens/actions'
import type { ActionTypes } from 'features/PublicTokens/types'

const initialState = { } as {[key: string]: string}

export const PublicTokensReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case ACTION_TYPES.SET_PUBLIC_TOKEN:
      return {
        ...state,
        [action.uid]: action.token
      }
    default: return state
  }
}
