import { createSelector } from 'reselect'
import type { RootState } from './types'

const getpagesScrollYPositionSelector = (state: RootState) => state.app.pagesScrollYPosition
const getInitializedSelector = (state: RootState) => state.app.initialized
const getIsFullScreenSelector = (state: RootState) => state.app.isFullScreen
const getIsInitialLoginSelector = (state: RootState) => state.app.isInitialLogin

export const getPageScrollYPosition = (pageRoute: string) =>
  createSelector(getpagesScrollYPositionSelector, (pagesScrollYPosition) => pagesScrollYPosition[pageRoute])

export const getAppInitialized = createSelector(getInitializedSelector, (initialized) => initialized)
export const getIsFullScreen = createSelector(getIsFullScreenSelector, (isFullScreen) => isFullScreen)
export const getIsSWActive = createSelector(
  (state: RootState) => state.app,
  ({ isSWActive }) => isSWActive
)
export const getIsInitialLogin = createSelector(getIsInitialLoginSelector, (isInitialLogin) => isInitialLogin)
