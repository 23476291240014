import type { Message } from '@twilio/conversations'
import type { ContactsType } from 'features/MyProfile/types'
import type {
  IErrorModal,
  IncomingCallType,
  NotificationHistoryJobOfferType,
  NotificationsHistoryType,
  ValueNotificationsHistoryType
} from 'features/Notifications/types'

export const actions = {
  setHistory: (history: NotificationsHistoryType, maxNotificationDate: string | null) => (
    { type: 'NOTIFICATIONS__SET_HISTORY', payload: { history, maxNotificationDate } } as const
  ),
  addItemInHistory: (id: string, value: ValueNotificationsHistoryType | NotificationHistoryJobOfferType) => (
    { type: 'NOTIFICATIONS__ADD_ITEM_IN_HISTORY', payload: { id, value } } as const
  ),
  addHistoryItems: (history: { [key: string]: ValueNotificationsHistoryType | NotificationHistoryJobOfferType }) => (
    { type: 'NOTIFICATIONS__ADD_ITEMS_IN_HISTORY', payload: { history } } as const
  ),
  replaceItemInHistory: (
    oldNotificationId: string,
    value: ValueNotificationsHistoryType | NotificationHistoryJobOfferType
  ) => (
    { type: 'NOTIFICATIONS__REPLACE_ITEM_IN_HISTORY', payload: { oldNotificationId, value } } as const
  ),
  deleteItemFromHistory: (notificationId: string) => (
    { type: 'NOTIFICATIONS__DELETE_ITEM_FROM_HISTORY', payload: notificationId } as const
  ),
  deleteItemsFromHistory: (notificationIds: string[]) => (
    { type: 'NOTIFICATIONS__DELETE_ITEMS_FROM_HISTORY', payload: notificationIds } as const
  ),
  setIsLoadedHistory: (isLoadedHistory: boolean) => (
    { type: 'NOTIFICATIONS__SET_IS_LOADED_HISTORY', isLoadedHistory } as const
  ),
  setIsLoadedArchivedHistory: (isLoadedArchivedHistory: boolean) => (
    { type: 'NOTIFICATIONS__SET_IS_LOADED_ARCHIVED_HISTORY', isLoadedArchivedHistory } as const
  ),
  hidingNotification: (notificationId: string) => (
    { type: 'NOTIFICATIONS__SET_HIDING_NOTIFICATION', payload: notificationId } as const
  ),
  removeHidingNotification: (notificationId: string) => (
    { type: 'NOTIFICATIONS__REMOVE_HIDING_NOTIFICATION', payload: notificationId } as const
  ),
  archivingNotification: (notificationId: string) => (
    { type: 'NOTIFICATIONS__SET_ARCHIVING_NOTIFICATION', payload: notificationId } as const
  ),
  removeArchivingNotification: (notificationId: string) => (
    { type: 'NOTIFICATIONS__REMOVE_ARCHIVING_NOTIFICATION', payload: notificationId } as const
  ),
  addAnyMsg: (message: { msg: string, uid: string }) => ({ type: 'NOTIFICATIONS__ADD_ANY_MESSAGE', message } as const),
  removeAnyMsg: (uid: string) => ({ type: 'NOTIFICATIONS__REMOVE_ANY_MESSAGE', uid } as const),
  addErrorMsg: (msg: IErrorModal) => ({ type: 'NOTIFICATIONS__ADD_ERROR_MSG', msg } as const),
  removeErrorMsg: () => ({ type: 'NOTIFICATIONS__REMOVE_ERROR_MSG' } as const),
  updatedIntroNotificationStatus: (notificationId: string) => ({ type: 'NOTIFICATIONS__UPDATED_INTRO_STATUS', notificationId } as const),
  addReceivedChatMsg: (msg: Message, user: ContactsType | null) => (
    { type: 'NOTIFICATIONS__ADD_RECEIVED_CHAT_MSG', payload: { msg, user } } as const
  ),
  removeReceivedChatMsg: (sid: string) => ({ type: 'NOTIFICATIONS__REMOVE_RECEIVED_CHAT_MSG', sid } as const),
  removeReceivedAllMsg: () => ({ type: 'NOTIFICATIONS__REMOVE_RECEIVED_ALL_MSGS' } as const),
  addIncomingCall: (payload: IncomingCallType) => ({ type: 'NOTIFICATIONS__ADD_INCOMING_CALL', payload } as const),
  removeIncomingCall: () => ({ type: 'NOTIFICATIONS__REMOVE_INCOMING_CALL' } as const)
}
