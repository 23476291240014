import type { Message } from '@twilio/conversations'
import type { ContactsType } from 'features/MyProfile/types'
import type {
  ActionTypes,
  IErrorModal,
  IncomingCallType,
  NotificationsHistoryType
} from './types'

const initialState = {
  history: {} as NotificationsHistoryType,
  hidingNotifications: [] as string[],
  archivingNotifications: [] as string[],
  isLoadedHistory: false,
  isLoadedArchivedHistory: false,
  maxNotificationDate: null as string | null,
  anyMsgs: [] as { msg: string, uid: string }[],
  errorMsg: null as IErrorModal | null,
  receivedChatMsgs: [] as { user: ContactsType | null, msg: Message }[],
  incomingCall: null as IncomingCallType | null
}

export const NotificationsReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case 'NOTIFICATIONS__SET_HISTORY': {
      return {
        ...state,
        history: action.payload.history,
        maxNotificationDate: action.payload.maxNotificationDate
      }
    }
    case 'NOTIFICATIONS__ADD_ITEM_IN_HISTORY': {
      return {
        ...state,
        history: {
          ...state.history,
          [action.payload.id]: action.payload.value
        }
      }
    }
    case 'NOTIFICATIONS__ADD_ITEMS_IN_HISTORY': {
      return {
        ...state,
        history: {
          ...state.history,
          ...action.payload.history
        }
      }
    }
    case 'NOTIFICATIONS__REPLACE_ITEM_IN_HISTORY': {
      return {
        ...state,
        history: {
          ...state.history,
          [action.payload.oldNotificationId]: action.payload.value
        }
      }
    }
    case 'NOTIFICATIONS__DELETE_ITEM_FROM_HISTORY': {
      const history = { ...state.history }
      delete history[action.payload]
      return { ...state, history }
    }
    case 'NOTIFICATIONS__DELETE_ITEMS_FROM_HISTORY': {
      const history = { ...state.history }
      action.payload.forEach((id) => {
        delete history[id]
      })
      return { ...state, history }
    }
    case 'NOTIFICATIONS__SET_IS_LOADED_HISTORY': {
      return {
        ...state,
        isLoadedHistory: action.isLoadedHistory
      }
    }
    case 'NOTIFICATIONS__SET_IS_LOADED_ARCHIVED_HISTORY': {
      return {
        ...state,
        isLoadedArchivedHistory: action.isLoadedArchivedHistory
      }
    }
    case 'NOTIFICATIONS__SET_HIDING_NOTIFICATION':
      return {
        ...state,
        hidingNotifications: [...state.hidingNotifications, action.payload]
      }
    case 'NOTIFICATIONS__REMOVE_HIDING_NOTIFICATION':
      return {
        ...state,
        hidingNotifications: state.hidingNotifications.filter((id) => id !== action.payload)
      }
    case 'NOTIFICATIONS__SET_ARCHIVING_NOTIFICATION':
      return {
        ...state,
        archivingNotifications: [...state.archivingNotifications, action.payload]
      }
    case 'NOTIFICATIONS__REMOVE_ARCHIVING_NOTIFICATION':
      return {
        ...state,
        archivingNotifications: state.archivingNotifications.filter((id) => id !== action.payload)
      }
    case 'NOTIFICATIONS__ADD_ANY_MESSAGE': {
      return {
        ...state,
        anyMsgs: [...state.anyMsgs, action.message]
      }
    }
    case 'NOTIFICATIONS__REMOVE_ANY_MESSAGE':
      return {
        ...state,
        anyMsgs: state.anyMsgs.filter((message) => message.uid !== action.uid)
      }
    case 'NOTIFICATIONS__ADD_ERROR_MSG':
      return {
        ...state,
        errorMsg: action.msg
      }
    case 'NOTIFICATIONS__REMOVE_ERROR_MSG':
      return {
        ...state,
        errorMsg: null
      }
    case 'NOTIFICATIONS__UPDATED_INTRO_STATUS':
      return {
        ...state,
        history: {
          ...state.history,
          [action.notificationId]: {
            ...state.history[action.notificationId],
            status: 'read'
          }
        }
      }
    case 'NOTIFICATIONS__ADD_RECEIVED_CHAT_MSG':
      return {
        ...state,
        receivedChatMsgs: [...state.receivedChatMsgs, action.payload]
      }
    case 'NOTIFICATIONS__REMOVE_RECEIVED_ALL_MSGS':
      return {
        ...state,
        receivedChatMsgs: [],
        contactsEventsMsgs: [],
        anyMsgs: []
      }
    case 'NOTIFICATIONS__REMOVE_RECEIVED_CHAT_MSG':
      return {
        ...state,
        receivedChatMsgs: state.receivedChatMsgs.filter((msg) => msg.msg.sid !== action.sid)
      }
    case 'NOTIFICATIONS__ADD_INCOMING_CALL':
      return {
        ...state,
        incomingCall: action.payload
      }
    case 'NOTIFICATIONS__REMOVE_INCOMING_CALL':
      return {
        ...state,
        incomingCall: null
      }
    default:
      return state
  }
}
