import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PrimaryButton } from 'common/components/Button/PrimaryButton'
import { API_STATUS_CODES, LOCALISE_SEPARATORS, ROUTES } from 'common/constants'
import { CloseIcon } from 'common/icons/CloseIcon'
import { PaperIcon } from 'common/icons/PaperIcon'
import { getAuth } from 'features/Auth/selectors'
import { ErrorModalCta } from 'features/ErrorModal/types'
import { Modal } from 'features/Modal'
import { actions } from 'features/Notifications/actions'
import { getNotificationsErrorMsg } from 'features/Notifications/selectors'
import { ErrorModalTypes } from 'features/Notifications/types'
import { selectErrorModalTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

export const ErrorModal = () => {
  const dispatch = useDispatch()
  const errorModalTranslations = useSelector(selectErrorModalTranslations)
  const errorMsg = useSelector(getNotificationsErrorMsg)
  const history = useHistory()
  const auth = useSelector(getAuth)

  const onModalClose = () => {
    if (errorMsg?.status !== API_STATUS_CODES.BAD_REQUEST && errorMsg?.status !== API_STATUS_CODES.NOT_FOUND) {
      dispatch(actions.removeErrorMsg())
    }
  }

  // TODO: Implement in-app back navigation
  const onReloadButtonClick = () => {
    if (errorMsg?.ctaType === ErrorModalCta.BACK) {
      dispatch(actions.removeErrorMsg())
      history.push(auth ? ROUTES.EXPLORE : ROUTES.LANDING)
    } else {
      window.location.reload()
    }
  }

  const renderModalContent = () => {
    if (errorMsg?.type === ErrorModalTypes.CUSTOM) {
      return (
        <>
          <h2 className={styles.title}>
            {errorMsg?.title
              ? (errorModalTranslations as { [key: string]: string })[errorMsg.title]
              : errorModalTranslations.errorModalContent.replace(/\\n/g, '')}
          </h2>
          {errorMsg?.description && (
            <p className={styles.customDescription}>
              {(errorModalTranslations as { [key: string]: string })[errorMsg.description]}
            </p>
          )}
        </>
      )
    }

    return (
      <>
        <div className={styles.close} onClick={() => dispatch(actions.removeErrorMsg())}><CloseIcon /></div>
        <h2 className={styles.title}>{errorModalTranslations.errorModalContent.replace(/\\n/g, '')}</h2>
        {errorMsg?.status && (
          <p className={styles.body}>
            {errorModalTranslations?.errorMessage?.replace(LOCALISE_SEPARATORS.DIGIT, errorMsg.status.toString())}
          </p>
        )}
        {errorMsg?.description && (
          <p className={styles.body}>
            {errorModalTranslations.errorMessageText}:&nbsp;
            {errorMsg.description}
          </p>
        )}
      </>
    )
  }

  return (
    <Modal
      isOpen={!!errorMsg}
      width="335px"
      onClose={onModalClose}
      scrollableContent={(
        <div className={styles.errorMsgModalContent}>
          {renderModalContent()}
          <PaperIcon />
          <PrimaryButton
            type="button"
            title={errorMsg?.ctaType === ErrorModalCta.RELOAD ? errorModalTranslations.errorButton : 'Back'}
            className={styles.primaryButton}
            onClick={onReloadButtonClick}
          />
        </div>
      )}
    />
  )
}
