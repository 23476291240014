import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Image } from 'common/components/Image_V2'
import { PersonIcon } from 'common/icons_V2/PersonIcon'
import { getMyProfile } from 'features/MyProfile/selectors'

interface IProfileIcon {
  width?: number
}

export const ProfileIcon: FC<IProfileIcon> = ({ width = 56 }) => {
  const profile = useSelector(getMyProfile)

  return (
    profile?.photo || profile?.photoURL ? (
      <Image
        photoURL={profile?.photoURL}
        photo={profile?.photo}
        width={width}
        isRounded
      />
    ) : (
      <PersonIcon size={width} />
    )
  )
}
