import { FC } from 'react'
import cn from 'classnames'
import { VirtualScroll } from 'common/components/VirtualScroll'
import { colorTheMatch } from 'common/utils/colorTheMatch'
import styles from './styles.module.sass'

interface IPopupMenu {
  options: (string | { key: string, label: string })[]
  onOptionSelect: (e: any) => void
  searchString?: string
  isSelectElement?: boolean
  renderer?: (item: string[]) => string | any
  popupStyles?: string
}

export const EXPENDABLE_INPUT_OPTION_ID = 'EXPENDABLE_INPUT_OPTION_ID'
const Option: FC<any> = ({
  isSelectElement, onOptionSelect, children: stringifiedOption, searchString, renderer
}) => {
  const text = typeof stringifiedOption === 'string' ? stringifiedOption : stringifiedOption.label
  return (
    <button
      id={EXPENDABLE_INPUT_OPTION_ID}
      className={cn(styles.option, isSelectElement && styles.vacancy)}
      onClick={() => onOptionSelect(stringifiedOption)}
    >
      {colorTheMatch(renderer ? renderer(stringifiedOption) : text, searchString || '')}
    </button>
  )
}

export const PopupMenu: FC<IPopupMenu> = ({
  options, onOptionSelect, searchString, isSelectElement, renderer, popupStyles
}) => {
  return (
    <div className={cn(styles.popup, popupStyles)}>
      <VirtualScroll
        itemsPerView={10}
        wrapper={Option}
        itemProps={{
          isSelectElement,
          onOptionSelect,
          searchString
        }}
        renderer={renderer}
        list={options}
      />
    </div>
  )
}
