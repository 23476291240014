import { FC } from 'react'
import { Redirect } from 'react-router-dom'

export const withRedirection = (
  Component: FC<any>,
  rules: { condition: boolean; route: string }[]
) =>
  (props: any) => {
    const redirect = rules.find(({ condition }) => condition)
    if (redirect) return <Redirect to={redirect.route} />
    return <Component {...props} />
  }
