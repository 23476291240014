import React from 'react'
import cn from 'classnames'
import { CloseIcon } from 'common/icons_V2/CloseIcon'
import { ErrorIcon } from 'common/icons_V2/ErrorIcon'
import { InfoIcon } from 'common/icons_V2/InfoIcon'
import { SuccessIcon } from 'common/icons_V2/SuccessIcon'
import { WarningIcon } from 'common/icons_V2/WarningIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { ToastType } from 'features/ToastManager/types'
import styles from './styles.module.sass'

type IToast = {
  type: ToastType
  message: string
  onClose: () => void
}

export const Toast: React.FC<IToast> = ({ type, message, onClose }) => {
  const getToastIcon = (type: ToastType) => {
    switch (type) {
      case ToastType.SUCCESS:
        return <SuccessIcon />
      case ToastType.ERROR:
        return <ErrorIcon />
      case ToastType.WARNING:
        return <WarningIcon />
      case ToastType.INFO:
        return <InfoIcon />
      default:
        return null
    }
  }

  return (
    <div className={cn(styles.toast)}>
      {getToastIcon(type)}
      <div className={styles.content}>
        <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="div" className={styles.message}>
          {message}
        </Typography>
      </div>
      <div className={styles.close} onClick={onClose}>
        <CloseIcon />
      </div>
    </div>
  )
}
