// TODO: Implement a fallback to a different storage mechanism
// to gain access to localStorage if cookies are disabled

interface ILocalStorageService {
  setItem: (key: string, value: string) => void
  getItem: (key: string) => string | null
  removeItem: (key: string) => void
  clear: () => void
}

const LocalStorageService: ILocalStorageService = {
  setItem: (key: string, value: string): void => {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      // Error saving to localStorage
    }
  },

  getItem: (key: string): string | null => {
    try {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : null
    } catch (error) {
      return null
    }
  },

  removeItem: (key: string): void => {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      // Error removing from localStorage:
    }
  },

  clear: (): void => {
    try {
      localStorage.clear()
    } catch (error) {
      // Error clearing localStorage
    }
  }
}

export default LocalStorageService
