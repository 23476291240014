import {
  applyMiddleware, combineReducers, compose, createStore
} from 'redux'
import thunk from 'redux-thunk'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { ContextMenuReducer } from 'common/components/ContextMenu/reducer'
import { AppReducer } from 'common/reducer'
import type { ActionTypes } from 'common/types'
import envConfig from 'config'
import { AdminReducer } from 'features/Admin/reducer'
import { AuthReducer } from 'features/Auth/reducer'
import { ContactsReducer } from 'features/Contacts/reducer'
import { ConversationsReducer } from 'features/Conversations/reducer'
import { FilterReducer } from 'features/FilterItems/reducer'
import { HomeReducer } from 'features/Home/reducer'
import { LocationReducer } from 'features/Locations/reducer'
import { ProfileReducer } from 'features/MyProfile/reducer'
import { NotificationsReducer } from 'features/Notifications/reducer'
import { PublicTokensReducer } from 'features/PublicTokens/reducer'
import { SearchReducer } from 'features/SearchInput_V2/reducer'
import { ToastReducer } from 'features/ToastManager/reducer'
import { TranslationsReducer } from 'features/Translations/reducer'
import { VideoChatReducer } from 'features/VideoChat/reducer'

import 'firebase/messaging'

export const firebaseApp = initializeApp(envConfig.firebase)
export const firestoreDb = getFirestore(firebaseApp)
export const firebaseAuth = getAuth(firebaseApp)

const appReducer = combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  contextMenu: ContextMenuReducer,
  profile: ProfileReducer,
  contacts: ContactsReducer,
  surf: HomeReducer,
  notifications: NotificationsReducer,
  translations: TranslationsReducer,
  videoChat: VideoChatReducer,
  conversations: ConversationsReducer,
  admin: AdminReducer,
  publicTokens: PublicTokensReducer,
  locations: LocationReducer,
  filters: FilterReducer,
  search: SearchReducer,
  toast: ToastReducer
})

export const rootReducer = (state: any, action: ActionTypes) => {
  if (action.type === 'LOG_OUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const middlewares = [
  thunk.withExtraArgument(firebaseAuth)
]

const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))
