import type { Client as ConversationsClient, Message } from '@twilio/conversations'
import { getInitialOpenedChat } from 'common/utils/chats'
import type { ActionTypes, ChatType, MessageType } from 'features/Conversations/types'
import type { ContactsType } from 'features/MyProfile/types'

const initialState = {
  client: null as ConversationsClient | null,
  chats: {} as ChatType,
  openedChat: getInitialOpenedChat(),
  isChatsLoaded: false,
  isOffersAndFavorsLoaded: false,
  isMessagesHasUnread: false,
  participantsToOpenChatWith: {
    profiles: [] as ContactsType[],
    vacancyId: undefined as string | undefined | null,
    specialtyId: undefined as string | undefined | null
  },
  searchData: { searchChatIndex: null, searchString: '' } as { searchChatIndex: number | null, searchString: string }
}

export const ConversationsReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case 'CONVERSATIONS__SET_CONVERSATIONS_CLIENT':
      return {
        ...state,
        client: action.client
      }
    case 'CONVERSATIONS__SET_CHATS':
      return {
        ...state,
        chats: { ...state.chats, ...action.chats },
        isChatsLoaded: true
      }
    case 'CONVERSATIONS__ADD_CHAT':
      return {
        ...state,
        chats: { ...state.chats, ...action.payload }
      }
    case 'CONVERSATIONS__SET_PARTICIPANTS_TO_OPEN_CHAT_WITH': {
      const { profiles, vacancyId, specialtyId } = action.payload
      return {
        ...state,
        participantsToOpenChatWith: {
          profiles,
          vacancyId,
          specialtyId
        }
      }
    }
    case 'CONVERSATIONS__SET_OPENED_CHAT':
      return {
        ...state,
        openedChat: action.chat
      }
    case 'CONVERSATIONS__ADD_MESSAGE':
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.payload.chat]: {
            ...state.chats[action.payload.chat],
            messages: [
              ...(state.chats[action.payload.chat]?.messages || []),
              action.payload.message
            ]
          }
        }
      }
    case 'CONVERSATIONS__REMOVE_MESSAGE': {
      const { payload: { chatId, messageSid } } = action
      const { chats: { [chatId]: { messages, ...chat }, ...chats } } = state
      return {
        ...state,
        chats: {
          ...chats,
          [chatId]: {
            ...chat,
            messages: messages.filter((message: any) => message.sid !== messageSid)
          }
        }
      }
    }
    case 'CONVERSATIONS__SET_MESSAGES_LOADING_FOR_CHAT': {
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.payload.chat]: {
            ...state.chats[action.payload.chat],
            isMessagesLoading: true
          }
        }
      }
    }
    case 'CONVERSATIONS__REPLY_TO_MESSAGE': {
      const { sid, index, body } = action?.message || {} as any
      const { chats: { [state.openedChat]: chat, ...chats } } = state
      const findedChat = chat.messages.find((msg) => msg && msg.index === index)
      return {
        ...state,
        chats: {
          ...chats,
          [state.openedChat]: {
            ...chat,
            messageToReply: sid ? {
              sid,
              body,
              index,
              userName: action.userName || '',
              mediaUrl: (findedChat as any)?.state?.attachedMedia?.[0].mcsMedia?.state.contentDirectUrl ?? '',
              media: findedChat?.attachedMedia?.[0] || null
            } : null
          }
        }
      }
    }
    case 'CONVERSATIONS__SET_MESSAGES': {
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.payload.chat]: {
            ...state.chats[action.payload.chat],
            messages: action.payload.messages,
            hasPrevPage: action.payload.hasPrevPage,
            isMessagesLoading: false
          }
        }
      }
    }
    case 'CONVERSATIONS__UPDATE_MESSAGE': {
      const { chats: { [action.payload.chat]: { messages, ...chat }, ...chats } } = state
      return {
        ...state,
        chats: {
          ...chats,
          [action.payload.chat]: {
            ...chat,
            messages: [
              ...messages.filter((message) => message?.sid !== action.payload.lastMessageSid),
              action.payload.message
            ]
          }
        }
      }
    }
    case 'CONVERSATIONS__UPDATE_MESSAGE_CONTENT': {
      const { chats: { [action.payload.message.conversation.sid]: { messages, ...chat }, ...chats } } = state
      return {
        ...state,
        chats: {
          ...chats,
          [action.payload.message.conversation.sid]: {
            ...chat,
            messages: messages.reduce((acc: (Message | MessageType)[], message: Message | MessageType) => {
              if (message?.sid === action.payload.message.sid) {
                return [...acc, action.payload.message]
              }
              return [...acc, message]
            }, [])
          }
        }
      }
    }
    case 'CONVERSATIONS__UPDATE_SEARCH_MESSAGE': {
      return {
        ...state,
        searchData: {
          searchString: action.payload.searchString ?? state.searchData.searchString,
          searchChatIndex: action.payload.messageIndex
        }
      }
    }
    case 'CONVERSATIONS__SET_IS_MESSAGES_UNREAD': {
      return {
        ...state,
        isMessagesHasUnread: action.isMessagesHasUnread
      }
    }
    case 'CONVERSATIONS__SET_IS_VACANCIES_LOADED': {
      return {
        ...state,
        isOffersAndFavorsLoaded: action.isOffersAndFavorsLoaded
      }
    }
    case 'CONVERSATIONS__SET_UNREAD_MESSAGES': {
      const { chats: { [action.payload.chatId]: chat, ...chats } } = state
      return {
        ...state,
        chats: {
          ...chats,
          [action.payload.chatId]: {
            ...chat,
            unreadMessageCount: action.payload.unreadMessageCount
          }
        }
      }
    }
    default: return state
  }
}
