import { ROUTE_SEARCH_PARAMS } from 'common/constants'
import { getQueryObject } from 'common/utils/url'

export const checkRequestPublicProfile = (search: string) => {
  const {
    [ROUTE_SEARCH_PARAMS.VACANCY_ID]: vacancy_id,
    [ROUTE_SEARCH_PARAMS.SPECIALITY_ID]: speciality_id,
    [ROUTE_SEARCH_PARAMS.SHARED_BY]: shared_by,
    [ROUTE_SEARCH_PARAMS.TOKEN]: token
  } = getQueryObject(search)

  if (shared_by && token) {
    return {
      uid: shared_by,
      token,
      vacancy_id,
      speciality_id
    }
  }

  return null
}
