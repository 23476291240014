import { FC } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { Image } from 'common/components/Image_V2'
import { Octagon } from 'common/icons_V2/Octagon'
import { selectTrustLevels } from 'features/MyProfile/selectors'
import styles from './styles.module.sass'

interface IImageWithTrustMark {
  photoURL: string
  alt?: string
  width: number
  className?: string
  trustInfoStyles?: string
  onClick?: () => void
  photo: string
  uid: string
}

export const getTrustContentPosition = (width: number) => {
  if (width >= 122) {
    return { right: -15, bottom: -11 }
  }

  if (width >= 30) {
    return { top: width - 16, left: width - 12 }
  }

  return { top: 14, left: 16 }
}

const getLevelTextStyle = (width: number) => {
  if (width >= 96) {
    return styles.textStyleXXL
  }

  if (width >= 72) {
    return styles.textStyleXL
  }

  if (width >= 56) {
    return styles.textStyleL
  }

  if (width >= 44) {
    return styles.textStyleM
  }

  if (width >= 32) {
    return styles.textStyleS
  }

  if (width >= 16) {
    return styles.textStyleXS
  }

  return styles.textStyleXXS
}

export const ImageWithTrustMark: FC<IImageWithTrustMark> = ({
  photoURL, alt, width, className, onClick, photo, uid, trustInfoStyles
}) => {
  const trustLevels = useSelector(selectTrustLevels)
  const isTrustContactLevel = trustLevels[uid] === 1
  const levelTextStyle = getLevelTextStyle(width)

  return (
    <div
      className={cn(className, styles.imageWithTrustMark)}
      onClick={onClick}
    >
      <div className={styles.imageContainer}>
        <Image
          isRounded
          photoURL={photoURL}
          photo={photo}
          width={width}
          alt={alt}
        />
      </div>
      {(!!trustLevels[uid]) && (
        <div className={cn(styles.trustInfo, trustInfoStyles)}>
          <div className={styles.trustMark}>
            <Octagon />
            <div className={cn(
              trustLevels[uid] === 1 ? styles.trustLevel : styles.level,
              levelTextStyle,
              styles.textBase,
              isTrustContactLevel && styles.trustedContact
            )}
            >
              {trustLevels[uid]}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
