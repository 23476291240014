import React, { FC } from 'react'
import cn from 'classnames'
import { Image } from 'common/components/Image_V2'
import { Typography, TypographyVariants } from 'common/typography'
import { UserType } from 'features/MyProfile/types'
import styles from './styles.module.sass'

interface ITrustingCount {
  title: string
  count?: number
  users?: {
    trustedUsersForView: UserType[],
    count: number
  }
  auth: boolean
}

export const TrustingCount: FC<ITrustingCount> = ({
  title, count, users, auth
}) => {
  return (
    <div className={styles.trustingContainer}>
      {users?.count ? (
        <div className={cn(styles.images, users.count > 1 && styles.trustedUser)}>
          {users.trustedUsersForView.map(({ uid, photoURL, photo }) => (
            <div key={uid} className={styles.image}>
              <Image photoURL={photoURL} photo={photo} width={24} isRounded />
            </div>
          ))}
        </div>
      ) : null}

      <p className={styles.text}>
        <Typography
          variant={TypographyVariants.Desktop_UI_M_Regular}
          tag="span"
          className={cn(!count && styles.disabledTitle)}
        >
          {title}
        </Typography>
        {auth && (
          <Typography
            variant={TypographyVariants.Desktop_UI_M_Medium}
            tag="span"
            className={cn(count && styles.count)}
          >
            {count || 0}&nbsp;
          </Typography>
        )}
      </p>
    </div>
  )
}
