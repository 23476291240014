import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import cn from 'classnames'
import { ROUTE_SEGMENTS, STATUS_VACANCIES } from 'common/constants'
import { useSpecialties } from 'common/hooks/useSpecialities'
import { useSpecialityDetails } from 'common/hooks/useSpecialityDetails'
import { LoadingIcon } from 'common/icons/LoadingIcon'
import { getSpecialties } from 'features/MyProfile/actions'
import { Audience } from 'features/MyProfile/components/ProfilePage/UI/VacancySpecialityDetails/Audience'
import { Description } from 'features/MyProfile/components/ProfilePage/UI/VacancySpecialityDetails/Description'
import { Header } from 'features/MyProfile/components/ProfilePage/UI/VacancySpecialityDetails/Header'
import { Locations } from 'features/MyProfile/components/ProfilePage/UI/VacancySpecialityDetails/Locations'
import { Tags } from 'features/MyProfile/components/ProfilePage/UI/VacancySpecialityDetails/Tags'
import { WorkingDetails } from 'features/MyProfile/components/ProfilePage/UI/VacancySpecialityDetails/WorkingDetails'
import { selectProfileTranslations } from 'features/Translations/selectors'
import { CloseModalType, useCloseVacancySpecialityModal } from 'providers/CloseVacancySpecialityModalProvider'
import { ManageSpecialityModal } from '../VacancySpeciality_V2/ManageSpecialityModal'
import styles from './styles.module.sass'

interface ISpecialityDetails {
  basePath: string;
  goBack?: () => void;
  profileContentHeight?: number;
  specialtyIdProp?: string;
  userUidProp?: string;
}

export const SpecialityDetails: FC<ISpecialityDetails> = ({
  basePath, goBack, profileContentHeight, specialtyIdProp, userUidProp
}) => {
  const dispatch = useDispatch()
  const params = useParams<{ uid: string; specialityId: string }>()
  const specialityId = params.specialityId || specialtyIdProp || ''
  const userUid = params.uid || userUidProp || ''
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const { activeSpecialities: specialities, isLoading } = useSpecialties(userUid)
  const profileTranslations = useSelector(selectProfileTranslations)
  const { setIsVisibleCloseModal, setSpecialityId } = useCloseVacancySpecialityModal()
  const {
    name, details, status, locations, tags, workingDetails, audience, dateDetails
  } = useSpecialityDetails(userUid, specialityId)

  useEffect(() => {
    if (isLoading) {
      dispatch(getSpecialties(userUid))
    }
  }, [userUid])

  if (!isLoading && specialities.length && !name) {
    return (
      <Redirect to={`${basePath}/${userUid}${ROUTE_SEGMENTS.SPECIALTIES}`} />
    )
  }

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingIcon spinner />
      </div>
    )
  }

  const onOpenCloseModal = () => {
    setIsVisibleCloseModal(CloseModalType.DELETE_SPECIALITY)
    setSpecialityId(specialityId || '')
  }

  const onOpenEditModal = () => {
    setIsOpenEditModal(true)
  }

  return (
    <div
      className={cn(styles.vacancyContainer, styles.tabContent)}
      style={{ ...(profileContentHeight && { maxHeight: `calc(100vh - ${profileContentHeight}px)` }) }}
    >
      <Header
        goBack={goBack}
        onOpenCloseModal={onOpenCloseModal}
        userUid={userUid}
        name={name}
        onOpenEditModal={onOpenEditModal}
      />
      {status === STATUS_VACANCIES.CLOSED && (
        <div className={styles.closeVacancy}>{profileTranslations.textClosedVacancy}</div>
      )}
      <div className={styles.vacancyDetails}>
        {!!locations.length && (
          <Locations title={profileTranslations.locationTitle} locations={locations} />
        )}
        <WorkingDetails title={profileTranslations.subtitleVacancyDetails} workingDetails={workingDetails} />
        {!!tags?.length && (
          <Tags title={profileTranslations.tags} tags={tags} />
        )}
        {!!details?.description && (
          <Description title={profileTranslations.specialityAboutMe} description={details.description} />
        )}
        {!!audience && (
          <Audience title={profileTranslations.jobAudienceTitle} audience={audience} />
        )}
        <p className={styles.dateDetails}>{dateDetails}</p>
      </div>
      <ManageSpecialityModal
        isOpen={isOpenEditModal}
        onClose={() => setIsOpenEditModal(false)}
        specialityId={specialityId}
      />
    </div>
  )
}
