import {
  FC,
  useRef
} from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'
import { Tabs } from 'common/components/Tabs'
import { ROUTE_SEARCH_PARAMS, ROUTES } from 'common/constants'
import { getQueryObject } from 'common/utils/url'
import { BaseProfile } from 'features/MyProfile/components/ProfilePage/BaseProfile'
import { ProfileDetails } from 'features/MyProfile/components/ProfilePage/ProfileDetails'
import { PublicProfileLOModal, PublicProfileLOModalType } from 'features/MyProfile/components/ProfilePage/PublicProfileLOModal'
import { SpecialityDetails } from 'features/MyProfile/components/ProfilePage/SpecialityDetails'
import { VacancyDetails } from 'features/MyProfile/components/ProfilePage/VacancyDetails'
import { ProfileType } from 'features/MyProfile/types'
import { PROFILE_TABS_KEYS } from 'features/Translations/constants'
import { selectOtherProfileTranslations } from 'features/Translations/selectors'

interface IPublicProfile {
  openedProfile: ProfileType,
  isOtherProfile?: boolean
  basePath: string
}

const publicProfileTabs = {
  SPECIALITY: 'SPECIALITY',
  VACANCY: 'VACANCY',
  ABOUT: 'ABOUT'
}

export const PublicProfile: FC<IPublicProfile> = ({ openedProfile, isOtherProfile = false, basePath }) => {
  const otherProfileTabsTranslations = useSelector(selectOtherProfileTranslations)
  const { search } = useLocation()
  const profileHeaderRef = useRef<HTMLDivElement>(null)
  const {
    [ROUTE_SEARCH_PARAMS.VACANCY_ID]: vacancyId,
    [ROUTE_SEARCH_PARAMS.SPECIALITY_ID]: specialityId
  } = getQueryObject(search)

  const getActiveTab = () => {
    if (vacancyId) return publicProfileTabs.VACANCY
    if (specialityId) return publicProfileTabs.SPECIALITY
    return publicProfileTabs.ABOUT
  }
  const activeTab = getActiveTab()

  const getTabs = () => [
    { key: PROFILE_TABS_KEYS.OPEN_TO, route: activeTab === publicProfileTabs.SPECIALITY ? publicProfileTabs.SPECIALITY : '' },
    { key: PROFILE_TABS_KEYS.VACANCIES, route: activeTab === publicProfileTabs.VACANCY ? publicProfileTabs.VACANCY : '' }
  ]
  const tabs = getTabs().map((tab) => ({ ...tab, title: otherProfileTabsTranslations[tab.key] }))

  const getTabView = () => {
    switch (activeTab) {
      case publicProfileTabs.SPECIALITY:
        return (
          <SpecialityDetails
            basePath={basePath}
            profileContentHeight={400}
            specialtyIdProp={specialityId}
            userUidProp={openedProfile.uid}
          />
        )
      case publicProfileTabs.VACANCY:
        return (
          <VacancyDetails
            profileContentHeight={500}
            vacancyIdProp={vacancyId}
            userUidProp={openedProfile.uid}
          />
        )
      default:
        return (
          <ProfileDetails
            profile={openedProfile}
            isOtherProfile={isOtherProfile}
          />
        )
    }
  }

  return (
    <>
      <BaseProfile
        ref={profileHeaderRef}
        isOtherProfile={isOtherProfile}
        openedProfile={openedProfile}
        basePath={basePath}
      >
        <Tabs tabs={tabs} activeTab={activeTab} />
        <Switch>
          <Route path={ROUTES.PUBLIC_PROFILE}>
            {getTabView()}
          </Route>
        </Switch>
      </BaseProfile>
      <PublicProfileLOModal
        profile={openedProfile}
        modalType={
          [publicProfileTabs.SPECIALITY, publicProfileTabs.VACANCY].includes(activeTab)
            ? PublicProfileLOModalType.LISTINGS
            : PublicProfileLOModalType.PROFILE
        }
      />
    </>
  )
}
