import axios from 'axios'
import { getToken } from 'common/utils/auth'
import { dataURLtoFile } from 'common/utils/dataURLtoFile'
import { objectToQueryString } from 'common/utils/objectToQueryString'
import envConfig from 'config'
import { DeviceType, ProfileType } from 'features/MyProfile/types'
import { firebaseAuth } from 'store/store'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

const instance = axios.create({
  baseURL: envConfig.backendAPIUrl,
  headers
})

instance.interceptors.request.use(
  async (config) => {
    const user = firebaseAuth.currentUser
    if (user && config.url?.startsWith('/api')) {
      const token = await getToken(user)
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }, (error) => {
    console.log(error)
  }
)

instance.interceptors.response.use((res) => res, (error) => {
  const objectToReturn = { ...(error.response?.data || error.response || error), status: error.response?.status }
  return Promise.reject(objectToReturn)
})

export const profileAPI = {
  getMyProfile() {
    return instance.get('/api/user').then((res) => res.data)
  },
  async getSpecialties<DataT>(): Promise<DataT> {
    return instance.get('/api/specialities').then((res) => res.data)
  },
  async getAllLocations() {
    return instance.get('/api/locations').then((res) => res.data)
  },
  afterLogin(device: DeviceType) {
    return instance.post('/api/afterLogin', { device }).then((res) => res.data)
  },
  getMyVacancies() {
    return instance.get('/api/vacancy').then((res) => res.data)
  },
  addVacancy(vacancy: any) {
    return instance.put('/api/vacancy', vacancy).then((res) => res.data)
  },
  editVacancy(vacancyId: string, vacancy: any) {
    return instance.post(`/api/vacancy/${vacancyId}`, vacancy).then((res) => res.data)
  },
  deleteVacancy(vacancyId: string) {
    return instance.delete(`/api/vacancy/${vacancyId}`).then((res) => res.data)
  },
  closeVacancy(vacancyId: string) {
    return instance.patch(`/api/vacancy/${vacancyId}`).then((res) => res.data)
  },
  closeSpecialty(specialtyId: string) {
    return instance.patch(`/api/open_to/${specialtyId}`).then((res) => res.data)
  },
  getAllOffers() {
    return instance.get('/api/vacancy_offers').then((res) => res.data)
  },
  getAllVacanciesFavors() {
    return instance.get('/api/vacancy_response').then((res) => res.data)
  },
  favorVacancy(vacancyId: string, uid: string) {
    return instance.post(`/api/vacancy/${vacancyId}/${uid}/favor`).then((res) => res.data)
  },
  async updateMyProfile(value: { [key: string]: any }) {
    return instance.put('/api/user', value).then((res) => res.status)
  },
  async checkUsernameUnique(username: string) {
    return instance.post('/api/username/check', { username }).then((res) => res.status)
  },
  async hideNotifications(notifications: string[]) {
    return instance.post('/api/notifications/hide', { notifications }).then((res) => res.data)
  },
  updateProfilePhoto(file: File) {
    const body = new FormData()
    body.append('fileName', file)

    return instance.put('/api/user_photo', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        type: 'formData'
      }
    } as any).then((res) => res.data)
  },
  getChatToken() {
    return instance.get('/api/v2/chat/token').then((res) => res.data)
  },
  readNotifications(notifications: string[]) {
    return instance.post('/api/notifications', { notifications }).then((res) => res.data)
  },
  async archiveNotifications(notifications: string[]) {
    return instance.patch('/api/notifications', { notifications }).then((res) => res.data)
  },
  updateSettings(settings: { [key: string]: any }) {
    return instance.post('/api/settings', settings).then((res) => res.status)
  },
  forgetDevice(device: string) {
    return instance.post('/api/forgetDevice', { device }).then((res) => res.status)
  },
  async getSpecialities() {
    return instance.get('/api/open_to').then((res) => res.data)
  },
  async getOtherSpecialities(uid: string) {
    return instance.get(`/api/open_to/${uid}`).then((res) => res.data)
  },
  async addSpeciality(speciality: any) {
    return instance.put('/api/open_to', speciality).then((res) => res.data)
  },
  async editSpeciality(specialityId: string, speciality: any) {
    return instance.post(`/api/open_to/${specialityId}`, speciality)
  },
  async deleteSpeciality(specialityId: string) {
    return instance.delete(`/api/open_to/${specialityId}`)
  },
  async favorSpecialty(specialtyId: string, uid: string) {
    return instance.post(`/api/open_to/${specialtyId}/${uid}/favor`).then((res) => res.data)
  },
  async getSpecialityResponses() {
    return instance.get('/api/open_to_response').then((res) => res.data)
  }
}

export const profileAPIV2 = {
  async afterSignup(profile: ProfileType) {
    const {
      firstName, username, location, dream, job, photoURL, lastName, invitedBy
    } = profile
    const profileData = {
      first_name: firstName,
      user_name: username,
      last_name: lastName,
      invited_by: invitedBy,
      location: location || '',
      dream: dream || [],
      job_title: job.title || '',
      job_company: job.company || ''
    }
    const query = objectToQueryString(profileData)
    if (photoURL) {
      const body = new FormData()
      body.append('fileName', dataURLtoFile(photoURL))
      return instance.post(`/api/v2/afterSignup${query}`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
          type: 'formData'
        }
      } as any).then((res) => res.data)
    }
    return instance.post(`/api/v2/afterSignup${query}`).then((res) => res.data)
  }
}

export const usersAPI = {
  getUser(uid: string) {
    return instance.get(`/api/user/${uid}`).then((res) => res.data)
  },
  async getUserByUsername(username: string) {
    return instance.post('/api/user_byname', { username }).then((res) => res.data)
  },
  getUsersBrief(uids: string[]) {
    return instance.post('/api/users_brief', { uids }).then((res) => res.data)
  },
  deleteUser(uid: string) {
    return instance.delete(`/api_heavy/user/${uid}`).then((res) => res.data)
  },
  getMatches() {
    return instance.get('/api/matches').then((res) => res.data)
  },
  getCommonTrustedContactList() {
    return instance.get('/api/h2network').then((res) => res.data)
  },
  getTrustedContactList(uid: string, depth: number = 1) {
    return instance.get(`/api/r_trust_network?depth=${depth}&uid=${uid}`).then((res) => res.data)
  },
  getTrustLevels(level: number) {
    return instance.get(`/api/trust_network/${level}`).then((res) => res.data)
  },
  introduceUser(uid: string, uidToIntroduce: string, message: string) {
    return instance.post('/api/intro', { users: [uidToIntroduce, uid], message }).then((res) => res.data)
  },
  callNow(uid: string, device_id: string) {
    return instance.put(`/api/call/${uid}/now`, { variants: ['now'], device_id }).then((res) => res.data)
  },
  callLeave(uid: string) {
    return instance.delete(`/api/call/${uid}/now`).then((res) => res.status)
  },
  inviteInVideoRoom(uid: string, device_id: string, startTime: string, room?: string | null) {
    if (room) {
      return instance.put(`/api/call/${uid}/${room}/${startTime}`).then((res) => res.data)
    }
    return instance.put(`/api/call/${uid}/${startTime}/group_now`, { device_id }).then((res) => res.data)
  },
  callDecline(uid: string) {
    return instance.post(`/api/call/${uid}/now/decline`).then((res) => res.status)
  },
  createChat(uid: string) {
    return instance.post(`/api/chat/${uid}`).then((res) => res.data)
  },
  createPublicToken(uid: string) {
    return instance.get(`/api/publicToken/${uid}`).then((res) => res.data)
  },
  getPublicProfile(uid: string, token: string) {
    return instance.get(`publicProfile?uid=${uid}&token=${token}`).then((res) => res.data)
  },
  getPublicVacancy(uid: string, token: string, vacancy_id: string) {
    return instance.get(`publicVacancy?uid=${uid}&doc=${vacancy_id}&token=${token}`).then((res) => res.data)
  },
  getPublicResume(uid: string, token: string, resume_id: string) {
    return instance.get(`publicResume?uid=${uid}&doc=${resume_id}&token=${token}`).then((res) => res.data)
  },
  connectToCall(startTime: string, uid: string) {
    return instance.post(`/api/call/${uid}/${startTime}`).then((res) => res.data)
  },
  addInvest(uid: string, others: string[]) {
    return instance.post(`/api/invest/${uid}`, { others }).then((res) => res.data)
  },
  deleteInvest(uid: string) {
    return instance.delete(`/api/invest/${uid}`).then((res) => res.data)
  },
  getCallHistory() {
    return instance.get('/api/call-history').then((res) => res.data)
  },
  trustUsers(uids: string[]) {
    return instance.put('/api/trust', { trust: uids }).then((res) => res.data)
  },
  addContact(uid: string) {
    return instance.post(`/api/contact/${uid}`).then((res) => res.data)
  },
  getUserVacancies(uid: string) {
    return instance.get(`/api/vacancy/${uid}`).then((res) => res.data)
  },
  async getGlobalSearchUsers(queryString: string) {
    return instance
      .post('/api_heavy/global_search/profiles', { q: queryString }).then((res) => res.data)
  }
}

export const adminAPI = {
  getAllUsers() {
    return instance.get('/api/admin/users').then((res) => res.data)
  },
  deleteUser(uid: string) {
    return instance.delete(`/api/user/${uid}`).then((res) => res.data)
  }
}
