import cn from 'classnames'
import { Logo } from 'common/icons/Logo'
import preloaderGif from 'common/images/preloader.gif'
import styles from './styles.module.sass'

interface IPreloader {
  wrapperStyles?: string
}

export const Preloader = ({ wrapperStyles }: IPreloader) => (
  <div className={cn(wrapperStyles, styles.wrapper)}>
    <div className={styles.logo}>
      <Logo />
    </div>
    <div className={styles.ticker}>
      <img src={preloaderGif} alt="w8rk" />
    </div>
  </div>
)
