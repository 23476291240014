import React, { FC, useState } from 'react'
import Cropper from 'react-cropper'
import { useSelector } from 'react-redux'
import { PrimaryButton } from 'common/components/Button_V2/PrimaryButton'
import { SecondaryButton } from 'common/components/Button_V2/SecondaryButton'
import { Modal } from 'features/Modal_V2'
import { selectProfileTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

import 'cropperjs/dist/cropper.css'

interface ICropperModal {
  imageUrl: string
  setImageUrl: (imageUrl: string)=> void
  cropImage: (imageUrl: string)=> Promise<void>
  isOpenPhotoEditor: boolean
  setIsOpenPhotoEditor: (value: boolean) => void
  isLoading: boolean
  setIsLoading: (value: boolean) => void
}

export const CropperModal: FC<ICropperModal> = ({
  imageUrl, setImageUrl: setImage, cropImage, isOpenPhotoEditor, setIsOpenPhotoEditor, isLoading, setIsLoading
}) => {
  const profileTranslations = useSelector(selectProfileTranslations)
  const [cropper, setCropper] = useState<any>()

  const onCloseEditPhotoModal = () => {
    if (!isLoading) {
      setIsOpenPhotoEditor(false)
    }
  }

  const getCropData = async () => {
    setIsLoading(true)
    if (typeof cropper !== 'undefined') {
      const imageUrl = cropper.getCroppedCanvas().toDataURL()
      await cropImage(imageUrl)
      setIsLoading(false)
      onCloseEditPhotoModal()
    }
  }

  return (
    <Modal
      title={profileTranslations.editButton}
      isOpen={isOpenPhotoEditor}
      width={470}
      height={840}
      onClose={() => {
        onCloseEditPhotoModal()
        setImage('')
      }}
    >
      <>
        <Cropper
          aspectRatio={1}
          style={{ height: 640, width: '100%' }}
          initialAspectRatio={1}
          preview=".img-preview"
          src={imageUrl}
          viewMode={1}
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          background={false}
          responsive
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => setCropper(instance)}
          guides
        />
        <div className={styles.buttonContainer}>
          <SecondaryButton
            title={profileTranslations.backButton}
            disabled={isLoading}
            onClick={onCloseEditPhotoModal}
          />
          <PrimaryButton
            isLoading={isLoading}
            title={profileTranslations.saveButton}
            onClick={getCropData}
          />
        </div>
      </>
    </Modal>
  )
}
