import React, { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { NetworkFilters } from 'features/Contacts/Network/MyNetwork'
import { ContactList } from 'features/Contacts/Network/MyNetwork/components/ContactList'
import { selectNetworkFilters } from 'features/FilterItems/selectors'
import { selectContactUids, selectTrustedUids } from 'features/MyProfile/selectors'
import { UserType } from 'features/MyProfile/types'

interface IDefaultState {
  searchString: string
  isSearchStringValid: boolean
  sectionsData: {
    [key: string]: {
      title: string,
      emptyStateTitle: string,
      users: UserType[],
      getContactInfo: (contact: UserType) => string,
      buttonType?: string,
      showLoadingInAllPage?: boolean,
      showTitle: boolean,
      isShowButtonVisible?: boolean,
      renderEmptySearchState: () => ReactNode
    }
  },
  onShowGlobalSearchData: () => void
}

export const DefaultState: FC<IDefaultState> = ({
  searchString,
  isSearchStringValid,
  sectionsData,
  onShowGlobalSearchData
}) => {
  const networkFilters = useSelector(selectNetworkFilters)
  const trustedUids = useSelector(selectTrustedUids)
  const allContactsUids = useSelector(selectContactUids)

  const renderContactList = (selectedFilter: NetworkFilters) => {
    const {
      users: contacts, title, emptyStateTitle, buttonType, getContactInfo, showLoadingInAllPage, renderEmptySearchState,
      showTitle, isShowButtonVisible
    } = sectionsData[selectedFilter]
    return (
      <ContactList
        title={title}
        emptyStateTitle={emptyStateTitle}
        contacts={contacts}
        searchString={searchString}
        trustedUids={trustedUids}
        allContactsUids={allContactsUids}
        buttonType={buttonType}
        getContactInfo={getContactInfo}
        showLoadingInAllPage={showLoadingInAllPage}
        renderEmptySearchState={renderEmptySearchState}
        isShowButtonVisible={isShowButtonVisible}
        onShowGlobalSearchData={onShowGlobalSearchData}
        showTitle={showTitle}
      />
    )
  }

  const renderActiveTabContent = () => {
    switch (networkFilters[0]) {
      case NetworkFilters.FIRST_LEVEL:
      case NetworkFilters.SECOND_LEVEL:
      case NetworkFilters.ALL_CONTACTS:
      case NetworkFilters.GLOBAL_SEARCH:
        return renderContactList(networkFilters[0])
      case NetworkFilters.ALL:
      default:
        return (
          <>
            {renderContactList(NetworkFilters.FIRST_LEVEL)}
            {renderContactList(NetworkFilters.SECOND_LEVEL)}
            {renderContactList(NetworkFilters.ALL_CONTACTS)}
            {isSearchStringValid && renderContactList(NetworkFilters.GLOBAL_SEARCH)}
          </>
        )
    }
  }

  return (
    <>
      {renderActiveTabContent()}
    </>
  )
}
