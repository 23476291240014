import styles from 'common/icons_V2/styles.module.sass'

export const SpinnerIcon = ({ spinner = false }) => {
  return (
    <div className={styles.iconContainer}>
      <div className={spinner ? styles.preloader : ''}>
        <div className={styles.spinnerContainer}>
          <div className={styles.hollowCenterStyle} />
        </div>
      </div>
    </div>
  )
}
