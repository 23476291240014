import { FC, ReactNode } from 'react'
import cn from 'classnames'
import { LoadingIcon } from 'common/icons/LoadingIcon'
import styles from './styles.module.sass'

interface IResponsiveLayout {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  isRightContentLoading?: boolean;
  isRightContentEmpty?: boolean;
  isRightContentHidden?: boolean;
  rightContentEmptyComponent?: ReactNode;
  rightContentLoadingComponent?: ReactNode;
  isLeftHiddenOnMobile?: boolean;
  isLeftWithHeader?: boolean;
  className?: string;
  wrapper?: string;
  responsiveLayoutRight?: string;
  responsiveLayoutLeft?: string;
  limitHeight?: boolean;
}

export const ResponsiveLayout: FC<IResponsiveLayout> = ({
  rightContent,
  leftContent,
  isRightContentLoading = false,
  rightContentLoadingComponent,
  isRightContentEmpty = false,
  isRightContentHidden = false,
  rightContentEmptyComponent,
  isLeftHiddenOnMobile = false,
  isLeftWithHeader = false,
  className,
  wrapper,
  responsiveLayoutRight,
  responsiveLayoutLeft,
  limitHeight = false
}) => {
  return (
    <div className={cn(styles.responsiveLayout, wrapper)}>
      {leftContent && (
        <div className={cn(
          styles.leftSection,
          limitHeight && styles.limitHeight,
          isLeftHiddenOnMobile && styles.hideOnMobile
        )}
        >
          <div
            className={cn(
              styles.responsiveLayoutLeft,
              isRightContentHidden && styles.rightContentHidden,
              isLeftWithHeader && styles.leftWithHeader,
              styles.removeBorder,
              responsiveLayoutLeft
            )}
          >
            {leftContent}
          </div>
        </div>
      )}

      <div className={cn(
        styles.rightSection,
        limitHeight && styles.limitHeight,
        isRightContentHidden && styles.rightContentHidden,
        !isLeftHiddenOnMobile && styles.hideOnMobile
      )}
      >
        <div className={cn(
          styles.responsiveLayoutRight,
          styles.removeBorder,
          isRightContentLoading && styles.contentCenter,
          className,
          responsiveLayoutRight
        )}
        >
          {isRightContentLoading && (
            <>
              {rightContentLoadingComponent || <LoadingIcon spinner />}
            </>
          )}
          {isRightContentEmpty && !isRightContentLoading && <>{rightContentEmptyComponent}</>}
          {!isRightContentEmpty && !isRightContentLoading && <>{rightContent}</>}
        </div>
      </div>
    </div>
  )
}
