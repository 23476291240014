import { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Modal } from 'features/Modal_V2'
import { Avatar } from 'features/MyProfile/components/Avatar'
import { EditHeaderForm } from 'features/MyProfile/components/EditHeaderModal/EditHeaderForm'
import { PhotoModal } from 'features/MyProfile/components/PhotoModal'
import { PROFILE_FIELDS } from 'features/MyProfile/constants'
import { getMyProfile } from 'features/MyProfile/selectors'
import { UserType } from 'features/MyProfile/types'
import { selectEditProfileTranslations } from 'features/Translations/selectors'
import { withDiscardModal } from 'hocs/withDiscardModal'
import styles from './styles.module.sass'

interface IEditHeaderModal {
  isOpen: boolean,
  onOpenDiscardModal: () => void
  onClose: () => void
}

const EditHeaderModal: FC<IEditHeaderModal> = ({
  isOpen, onOpenDiscardModal, onClose
}) => {
  const editProfileTranslations = useSelector(selectEditProfileTranslations)
  const profile = useSelector(getMyProfile) as UserType
  const { photoURL, photo } = profile
  const [isOpenPhotoModal, setIsOpenPhotoModal] = useState(false)

  const onCloseWithDiscard = () => {
    if (isDirty) {
      onOpenDiscardModal()
      return
    }
    onClose()
  }

  const formMethods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      [PROFILE_FIELDS.FIRST_NAME]: profile?.firstName,
      [PROFILE_FIELDS.USER_NAME]: profile?.username,
      [PROFILE_FIELDS.LAST_NAME]: profile?.lastName,
      job: { [PROFILE_FIELDS.COMPANY]: profile?.job?.company, [PROFILE_FIELDS.JOB_TITLE]: profile?.job?.title },
      [PROFILE_FIELDS.LOCATION]: (profile?.location ?? ''),
      [PROFILE_FIELDS.LINKS]: profile.links
    }
  })

  const {
    formState: { isDirty }
  } = formMethods

  return (
    <Modal
      isOpen={isOpen}
      width="470px"
      title={editProfileTranslations.editGeneralInfo}
      onClose={onCloseWithDiscard}
      isFullScreenOnMobile
    >
      <div className={styles.avatarContainer}>
        <Avatar photoURL={photoURL} photo={photo} showUploadTitle width={130} />
      </div>
      <FormProvider {...formMethods}>
        <EditHeaderForm
          onFinish={onClose}
          onCloseWithDiscard={onCloseWithDiscard}
        />
      </FormProvider>
      <PhotoModal
        isOpen={isOpenPhotoModal}
        photoURL={photoURL}
        onClose={() => setIsOpenPhotoModal(false)}
      />
    </Modal>
  )
}

export const EditHeaderModalWithDiscard = withDiscardModal(EditHeaderModal)
