export const Twitch = () => (
  <svg width="32" height="32" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0273 0.5H11.9727C5.36034 0.5 0 5.86035 0 12.4727V12.5273C0 19.1397 5.36034 24.5 11.9727 24.5H12.0273C18.6396 24.5 24 19.1397 24 12.5273V12.4727C24 5.86035 18.6396 0.5 12.0273 0.5Z" fill="#374957" />
    <g clipPath="url(#clip0_3364_7331)">
      <path d="M12.0003 9.41204V13.3241H10.7254V9.41204H12.0003ZM15.5002 9.41204V13.3241H14.2252V9.41204H15.5002ZM15.5002 16.2636L17.7251 13.9741V6.80693H7.2249V16.2636H10.0919V18.2199L12.0003 16.2636H15.5002ZM19 5.5V14.6316L15.1837 18.5437H12.3168L10.4083 20.5H8.49985V18.5443H5V8.10511L5.95849 5.5L19 5.5Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_3364_7331">
        <rect width="14" height="15" fill="white" transform="translate(5 5.5)" />
      </clipPath>
    </defs>
  </svg>
)
