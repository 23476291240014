import { ActionTypes, Toast } from 'features/ToastManager/types'

interface ToastState {
  toasts: Toast[]
}

const initialState: ToastState = {
  toasts: []
}

export const ToastReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case 'TOAST__ADD_TOAST': {
      const newToast = {
        ...action.toast,
        id: `${Date.now()}`
      }
      return {
        ...state,
        toasts: [...state.toasts, newToast]
      }
    }
    case 'TOAST__REMOVE_TOAST': {
      return {
        ...state,
        toasts: state.toasts.filter((toast) => !(toast.id === action.id && toast.type === action.toastType))
      }
    }
    default:
      return state
  }
}
