import { RecaptchaVerifier } from 'firebase/auth'
import { FAKE_PHONE_NUMBERS, RECAPTCHA_ELEMENT_ID } from 'common/constants'
import { withLeadingPlusSign } from 'common/utils/numbers'
import { firebaseAuth } from 'store/store'

export class RecaptchaSingleton {
  private static _instance: RecaptchaVerifier | null

  static getInstance(phoneNumber: string) {
    const isFakeNumber = FAKE_PHONE_NUMBERS.includes(withLeadingPlusSign(phoneNumber))
    // using the same mocked recaptcha more than once
    // causes signInWithPhoneNumber promise never resolve
    if (isFakeNumber || !RecaptchaSingleton._instance) {
      RecaptchaSingleton._instance = new RecaptchaVerifier(firebaseAuth, RECAPTCHA_ELEMENT_ID, {
        size: 'invisible',
        callback: (response: string) => {
          console.log('RecaptchaVerifier works!', response)
        }
      })
    }
    return RecaptchaSingleton._instance
  }

  static getInstanceForSupportForm() {
    if (!RecaptchaSingleton._instance) {
      RecaptchaSingleton._instance = new RecaptchaVerifier(firebaseAuth, RECAPTCHA_ELEMENT_ID, {
        size: 'invisible',
        callback: (response: string) => {
          console.log('RecaptchaVerifier works!', response)
        }
      })
    }
    return RecaptchaSingleton._instance
  }

  static removeInstance() {
    if (!RecaptchaSingleton._instance) return
    RecaptchaSingleton._instance.clear()
    RecaptchaSingleton._instance = null
  }
}
