import React, { FC, Fragment } from 'react'
import cn from 'classnames'
import styles from './styles.module.sass'

export enum TypographyVariants {
  Desktop_UI_XXL_Medium = 'Desktop_UI_XXL_Medium',
  Desktop_UI_XL_Medium = 'Desktop_UI_XL_Medium',
  Desktop_UI_XL_Bold = 'Desktop_UI_XL_Bold',
  Desktop_UI_XL_Light = 'Desktop_UI_XL_Light',
  Desktop_UI_L_Semibold = 'Desktop_UI_L_Semibold',
  Desktop_UI_L_Medium = 'Desktop_UI_L_Medium',
  Desktop_UI_L_Regular = 'Desktop_UI_L_Regular',
  Desktop_UI_S_Bold = 'Desktop_UI_S_Bold',
  Desktop_UI_XS_Bold = 'Desktop_UI_XS_Bold',
  Desktop_UI_S_Medium = 'Desktop_UI_S_Medium',
  Desktop_UI_S_Regular = 'Desktop_UI_S_Regular',
  Desktop_UI_M_Regular = 'Desktop_UI_M_Regular',
  Desktop_UI_M_Semibold = 'Desktop_UI_M_Semibold',
  Desktop_UI_M_Medium = 'Desktop_UI_M_Medium',

  Body_1_Medium = 'Body_1_Medium',
  Body_2_Medium = 'Body_2_Medium',
  Body_3_Medium = 'Body_3_Medium',
  Body_3_Semibold = 'Body_3_Semibold',
  Body_1_Regular = 'Body_1_Regular',
  Body_2_Regular='Body_2_Regular',

  Headings_H6_Regular = 'Headings_H6_Regular',
}

interface ITypography extends React.HTMLProps<HTMLDivElement>{
  variant: TypographyVariants
  tag?: React.ElementType
}

export const Typography: FC<ITypography> = ({
  variant, children, tag: Tag = Fragment, className, ...props
}) => {
  return (
    <Tag className={cn(styles[variant], className)} {...props}>
      {children}
    </Tag>
  )
}
