import React, {
  FC, useEffect, useRef, useState
} from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { SYMBOLS } from 'common/constants'
import { useDynamicInputWidth } from 'common/hooks/useDynamicInputWidth'
import { useInputLabel } from 'common/hooks/useInputLabel'
import { CloseIcon } from 'common/icons_V2/CloseIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { FooterInput } from 'features/FormInput_V2/FooterInput'
import { validateTagsInput } from 'features/MyProfile/components/VacancySpeciality_V2/validator'
import { selectErrorMsgsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface ITagsInputField {
  title?: string;
  fieldName?: string | any;
  error?: string;
  helperText?: string;
  maxCharCount?: number;
  showMaxCharCount?: boolean;
  placeholder?: string;
  defaultValue?: string [],
  autoFocus?: boolean
}

export const TagsInputField: FC<ITagsInputField> = ({
  title, fieldName, error, helperText, maxCharCount, showMaxCharCount, placeholder, defaultValue, autoFocus
}) => {
  const formContext = useFormContext()
  const errorMsgsTranslations = useSelector(selectErrorMsgsTranslations)
  const {
    getValues, clearErrors, setValue, setError, watch
  } = formContext
  const tags: string[] = watch(fieldName) || defaultValue || []

  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const hiddenSpanRef = useRef<HTMLSpanElement>(null)
  const showLabel = useInputLabel(inputValue, inputRef) || !!tags.length
  const spanStyles = useDynamicInputWidth(inputRef, inputValue, hiddenSpanRef, 4)

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [autoFocus])

  const deleteTag = (index: number) => {
    const updatedTags = getValues(fieldName).filter((_: string, i: number) => i !== index)
    validateTagsInput(
      [...updatedTags],
      fieldName,
      setError,
      clearErrors,
      errorMsgsTranslations,
      inputValue
    )
    setValue(fieldName, updatedTags, { shouldDirty: true })
  }

  const onTagSave = () => {
    const updatedTags = getValues(fieldName) as string[]
    if (error) clearErrors(fieldName)
    const isValid = validateTagsInput(
      [...updatedTags],
      fieldName,
      setError,
      clearErrors,
      errorMsgsTranslations,
      inputValue
    )

    if (isValid === true && inputValue) {
      setValue(fieldName, [...updatedTags, inputValue], { shouldDirty: true })
      setInputValue('')
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ' ' || e.key === 'Enter' || e.key === ',') {
      e.stopPropagation()
      e.preventDefault()
      onTagSave()
    }
    if (e.key === 'Backspace' && !inputValue && tags.length) {
      deleteTag(tags.length - 1)
    }
  }

  const onFocus = () => {
    clearErrors(fieldName)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.endsWith(',')) {
      onTagSave()
      return
    }
    const newValue = e.target.value
    const formattedValue = newValue.replace(/[\s|_]+/g, '_')
    if (maxCharCount && formattedValue.length > maxCharCount) {
      return
    }
    setInputValue(formattedValue)
    clearErrors(fieldName)
  }

  const handleContainerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <div className={styles.tagsContainer}>
      <Typography variant={TypographyVariants.Body_2_Medium} tag="label" className={cn(styles.tagsTitle, error && styles.errored)}>
        {showLabel && title}
      </Typography>
      <div className={cn(styles.tagsInputContainer, error && styles.errored)} onClick={handleContainerClick}>
        <Controller
          name={fieldName}
          rules={{
            validate: () => {
              return validateTagsInput(
                [...tags],
                fieldName,
                setError,
                clearErrors,
                errorMsgsTranslations,
                inputValue
              )
            }
          }}
          render={() => {
            return (
              <>
                {tags.map((tag: string, index: number) => (
                  <div className={styles.tag} key={index}>
                    <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="span" className={styles.tagTitle}>
                      {SYMBOLS.HASHTAG}{`${tag}`}
                    </Typography>
                    <span className={styles.deleteTag} onClick={() => deleteTag(index)}>
                      <CloseIcon size="16" color="#868EA1" />
                    </span>
                  </div>
                ))}
                <input
                  type="text"
                  onKeyDown={onKeyDown}
                  value={inputValue}
                  onChange={onChange}
                  onBlur={onTagSave}
                  onFocus={onFocus}
                  className={cn(styles.inputValue, inputValue && styles.hasValue)}
                  placeholder={showLabel ? '' : placeholder}
                  ref={inputRef}
                />
                <span ref={hiddenSpanRef} style={{ ...spanStyles }} className={styles.hiddenSpan}>{inputValue}</span>
              </>
            )
          }}
        />
      </div>
      <FooterInput
        value={inputValue}
        maxCharCount={maxCharCount}
        error={error}
        helperText={helperText}
        showMaxCharCount={showMaxCharCount}
      />
    </div>
  )
}
