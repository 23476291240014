import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { PAY_PERIODS } from 'common/constants'
import { selectOnboardingTranslations } from 'features/Translations/selectors'

export const usePayPeriods = () => {
  const onboardingTranslations = useSelector(selectOnboardingTranslations)
  return useMemo(() => [
    { key: PAY_PERIODS.PER_HOUR, label: onboardingTranslations[PAY_PERIODS.PER_HOUR] },
    { key: PAY_PERIODS.PER_DAY, label: onboardingTranslations[PAY_PERIODS.PER_DAY] },
    { key: PAY_PERIODS.PER_WEEK, label: onboardingTranslations[PAY_PERIODS.PER_WEEK] },
    { key: PAY_PERIODS.PER_MONTH, label: onboardingTranslations[PAY_PERIODS.PER_MONTH] },
    { key: PAY_PERIODS.PER_YEAR, label: onboardingTranslations[PAY_PERIODS.PER_YEAR] },
    { key: PAY_PERIODS.FIXED_PRICE, label: onboardingTranslations[PAY_PERIODS.FIXED_PRICE] } // per piece in figma?
  ], [onboardingTranslations])
}
