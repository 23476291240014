import React from 'react'

export const GmailIcon: React.FC = () => (
  <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M47.1239 26.4686C47.1239 24.6872 46.9792 23.3872 46.6661 22.0391H26.3137V30.0796H38.2601C38.0194 32.0779 36.7187 35.0872 33.8284 37.1093L33.7879 37.3784L40.2229 42.3611L40.6688 42.4057C44.7633 38.626 47.1239 33.0648 47.1239 26.4686Z" fill="#4285F4" />
    <path d="M26.3096 47.6646C32.1624 47.6646 37.076 45.7385 40.6648 42.4165L33.8244 37.1201C31.9938 38.3961 29.537 39.2868 26.3096 39.2868C20.5773 39.2868 15.712 35.5073 13.9776 30.2832L13.7234 30.3047L7.03207 35.4806L6.94458 35.7237C10.5092 42.8016 17.8314 47.6646 26.3096 47.6646Z" fill="#34A853" />
    <path d="M13.9793 30.2817C13.5216 28.9336 13.2568 27.489 13.2568 25.9964C13.2568 24.5038 13.5216 23.0593 13.9552 21.7112L13.9431 21.4241L7.16789 16.165L6.94621 16.2704C5.47704 19.2075 4.63403 22.5057 4.63403 25.9964C4.63403 29.4871 5.47704 32.7852 6.94621 35.7224L13.9793 30.2817Z" fill="#FBBC05" />
    <path d="M26.3096 12.7107C30.3801 12.7107 33.1259 14.4681 34.6915 15.9367L40.8093 9.96634C37.0519 6.4756 32.1624 4.33301 26.3096 4.33301C17.8314 4.33301 10.5092 9.19594 6.94458 16.2737L13.9535 21.7144C15.712 16.4904 20.5773 12.7107 26.3096 12.7107Z" fill="#EB4335" />
  </svg>
)
