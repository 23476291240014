import type { ConfirmationResult } from 'firebase/auth'
import type { ActionTypes } from './types'

const initialState = {
  auth: undefined as boolean | undefined,
  uid: '' as string,
  confirmation: null as ConfirmationResult | null,
  specialties: [] as string[],
  registerData: { number: '' },
  signUpData: { number: '' },
  isFailedConfirmationCode: false,
  isLoading: false,
  isWaitingProfileData: false
}

export const AuthReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case 'AUTH__SET_AUTH':
      return {
        ...state,
        auth: action.auth
      }
    case 'AUTH__SET_AUTH_UID':
      return {
        ...state,
        uid: action.uid
      }
    case 'AUTH__SET_SPECIALTIES':
      return {
        ...state,
        specialties: action.specialties
      }
    case 'AUTH__SET_CONFIRMATION':
      return {
        ...state,
        confirmation: action.confirmation
      }
    case 'AUTH__SET_REGISTER_DATA': {
      return {
        ...state,
        registerData: action.payload
      }
    }
    case 'AUTH__SET_SIGN_UP_DATA': {
      return {
        ...state,
        signUpData: action.payload
      }
    }
    case 'AUTH__SET_IS_FAILED_CONFIRMATION_CODE':
      return {
        ...state,
        isFailedConfirmationCode: action.isFailedConfirmationCode
      }
    case 'AUTH__SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      }
    case 'AUTH_SET_IS_WAITING_PROFILE_DATA':
      return {
        ...state,
        isWaitingProfileData: action.isWaitingProfileData
      }
    case 'AUTH__SET_RESET':
      return initialState
    default: return state
  }
}
