import React from 'react'

interface IChevronUpIcon {
  rotate?: number
}

export const ChevronUpIcon: React.FC<IChevronUpIcon> = ({ rotate = 0 }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" style={{ transform: `rotate(${rotate}deg)` }} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 11.25L9 6.75L13.5 11.25" stroke="#B1BAD2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
