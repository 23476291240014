import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TYPE_OF_JOB } from 'common/constants'
import { selectVacanciesTranslations } from 'features/Translations/selectors'

const TYPE_OF_JOBS = [
  TYPE_OF_JOB.FULL_TIME,
  TYPE_OF_JOB.PART_TIME,
  TYPE_OF_JOB.CONTRACTOR,
  TYPE_OF_JOB.SMALL_JOBS,
  TYPE_OF_JOB.INTERNSHIP,
  TYPE_OF_JOB.VOLUNTEER,
  TYPE_OF_JOB.ADVISOR,
  TYPE_OF_JOB.BOARD_OF_DIRECTORS,
  TYPE_OF_JOB.PROJECT_BASED,
  TYPE_OF_JOB.STARTUP_BUSINESS_PARTNER
]

export const useTypeOfJobs = () => {
  const vacanciesTranslations = useSelector(selectVacanciesTranslations)
  return useMemo(() => TYPE_OF_JOBS.reduce((acc, key) => {
    const label = vacanciesTranslations[key]
    if (label) acc.push({ key, label })
    return acc
  }, [] as { key: string, label: string }[]),
  [vacanciesTranslations])
}
