export const Github = () => (
  <svg width="32" height="32" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3364_7421)">
      <path d="M11.9999 0.5C9.15054 0.500121 6.39414 1.53979 4.22396 3.43297C2.05378 5.32615 0.61143 7.94931 0.154992 10.8331C-0.301447 13.7169 0.257812 16.6731 1.7327 19.1727C3.20759 21.6724 5.50186 23.5524 8.205 24.4764C8.80499 24.5841 9.02999 24.215 9.02999 23.892C9.02999 23.5998 9.01498 22.6309 9.01498 21.6005C5.99999 22.1695 5.22 20.847 4.98 20.1549C4.71369 19.4818 4.29153 18.8853 3.75001 18.417C3.33001 18.1863 2.73001 17.6173 3.73499 17.6019C4.11874 17.6446 4.48679 17.7815 4.80796 18.001C5.12913 18.2205 5.39395 18.5162 5.57999 18.863C5.74412 19.1653 5.96481 19.4314 6.22942 19.6461C6.49403 19.8608 6.79736 20.0198 7.12202 20.1141C7.44669 20.2084 7.78631 20.2361 8.12143 20.1956C8.45655 20.1551 8.78058 20.0471 9.07494 19.878C9.12689 19.2525 9.39877 18.6677 9.83996 18.2325C7.16998 17.9249 4.38 16.8637 4.38 12.1576C4.36314 10.9348 4.80322 9.75175 5.60999 8.85104C5.24313 7.78829 5.28606 6.622 5.72999 5.59061C5.72999 5.59061 6.73495 5.26763 9.02997 6.85172C10.9935 6.29803 13.0664 6.29803 15.0299 6.85172C17.3249 5.25227 18.3299 5.59061 18.3299 5.59061C18.7739 6.62198 18.8168 7.7883 18.4499 8.85104C19.2591 9.75021 19.6996 10.9343 19.6799 12.1576C19.6799 16.879 16.8749 17.9249 14.2049 18.2325C14.4913 18.5301 14.7119 18.8874 14.8516 19.2801C14.9914 19.6729 15.0471 20.0918 15.0149 20.5086C15.0149 22.1542 14.9999 23.4768 14.9999 23.8921C14.9999 24.2151 15.2249 24.5995 15.8249 24.4765C18.5233 23.545 20.811 21.6605 22.2796 19.1596C23.7482 16.6586 24.3021 13.7039 23.8425 10.8229C23.3829 7.94195 21.9397 5.3222 19.7705 3.43132C17.6012 1.54045 14.8472 0.501519 11.9999 0.5Z" fill="#374957" />
    </g>
    <defs>
      <clipPath id="clip0_3364_7421">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)
