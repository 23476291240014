import { FC } from 'react'
import cn from 'classnames'
import { EmptySearchStateIcon } from 'common/icons_V2/EmptySearchStateIcon'
import { EmptySearchStateWhiteIcon } from 'common/icons_V2/EmptySearchStateWhiteIcon'
import { Typography, TypographyVariants } from 'common/typography'
import styles from './styles.module.sass'

interface IEmptySearchState {
  emptySearchTitle: string
  emptySearchSubTitle?: string
  isBackgroundWhite?: boolean
  emptySearchStateContent?: string
}

export const EmptySearchState: FC<IEmptySearchState> = ({
  emptySearchTitle,
  emptySearchSubTitle,
  emptySearchStateContent,
  isBackgroundWhite = false
}) => {
  return (
    <div className={cn(styles.emptySearchState, emptySearchStateContent)}>
      {isBackgroundWhite ? (
        <EmptySearchStateWhiteIcon />
      ) : (
        <EmptySearchStateIcon />
      )}
      <div className={styles.emptySearchStateContent}>
        <Typography variant={TypographyVariants.Desktop_UI_XXL_Medium} tag="p">
          {emptySearchTitle}
        </Typography>
        <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="p">
          {emptySearchSubTitle || ''}
        </Typography>
      </div>
    </div>
  )
}
