import React, { FC, useRef } from 'react'
import cn from 'classnames'
import { useOutside } from 'common/hooks/useOutside'
import { PreloaderIcon } from 'common/icons/PreloaderIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { DropDownItemType } from 'features/NavBar/types'
import styles from './styles.module.sass'

interface IDropdownMenu {
  dropDownList: DropDownItemType[]
  onCloseList: () => void,
  dropdownMenuStyles?: string
}

export const DropdownMenu: FC<IDropdownMenu> = ({ dropDownList, dropdownMenuStyles, onCloseList }) => {
  const dropDownListEventRef = useRef<HTMLDivElement>(null)
  useOutside(dropDownListEventRef, onCloseList)

  return (
    <div
      className={cn(styles.dropdownMenu, dropdownMenuStyles)}
      ref={dropDownListEventRef}
    >
      {dropDownList.map((item, index) => (
        <div
          key={index}
          className={styles.dropdownItem}
          onClick={() => {
            item.onClick?.()
            onCloseList()
          }}
        >
          {item.isLoading && (<PreloaderIcon stroke="#ccd5e4" />)}
          {item.icon}
          <Typography
            variant={TypographyVariants.Desktop_UI_M_Regular}
            tag="p"
            style={{ color: item.textColor }}
          >
            {item.title}
          </Typography>
        </div>
      ))}
    </div>
  )
}
