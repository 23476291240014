import { getAllInfoByISO, getAllISOByCurrencyOrSymbol } from 'iso-country-currency'

export const getSymbolByCurrency = (currency: string) => {
  try {
    const [country] = getAllISOByCurrencyOrSymbol('currency', currency)
    const { symbol } = getAllInfoByISO(country)
    return symbol
  } catch {
    return currency
  }
}
