import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import cn from 'classnames'
import {
  ROUTE_PARAMS, ROUTE_SEGMENTS, ROUTES, STATUS_VACANCIES
} from 'common/constants'
import { useVacancies } from 'common/hooks/useVacancies'
import { useVacancyDetails } from 'common/hooks/useVacancyDetails'
import { LoadingIcon } from 'common/icons/LoadingIcon'
import { Audience } from 'features/MyProfile/components/ProfilePage/UI/VacancySpecialityDetails/Audience'
import { Description } from 'features/MyProfile/components/ProfilePage/UI/VacancySpecialityDetails/Description'
import { Header } from 'features/MyProfile/components/ProfilePage/UI/VacancySpecialityDetails/Header'
import { Locations } from 'features/MyProfile/components/ProfilePage/UI/VacancySpecialityDetails/Locations'
import { Tags } from 'features/MyProfile/components/ProfilePage/UI/VacancySpecialityDetails/Tags'
import { WorkingDetails } from 'features/MyProfile/components/ProfilePage/UI/VacancySpecialityDetails/WorkingDetails'
import { ManageVacancyModal } from 'features/MyProfile/components/VacancySpeciality_V2/ManageVacancyModal'
import { getMyUid } from 'features/MyProfile/selectors'
import { selectProfileTranslations } from 'features/Translations/selectors'
import { CloseModalType, useCloseVacancySpecialityModal } from 'providers/CloseVacancySpecialityModalProvider'
import styles from './styles.module.sass'

interface IVacancyDetails {
  goBack?: () => void;
  profileContentHeight?: number;
  vacancyIdProp?: string;
  userUidProp?: string;
}

export const VacancyDetails: FC<IVacancyDetails> = ({
  goBack, profileContentHeight, vacancyIdProp, userUidProp
}) => {
  const location = useLocation()
  const params = useParams<{ uid: string; vacancyId: string }>()
  const myUid = useSelector(getMyUid)
  const vacancyId = params.vacancyId || vacancyIdProp || ''
  const userUid = params.uid || userUidProp || ''
  const { vacancies, isLoading } = useVacancies(userUid)
  const profileTranslations = useSelector(selectProfileTranslations)
  const { setIsVisibleCloseModal, setVacancyId } = useCloseVacancySpecialityModal()
  const {
    name, details, status, locations, tags, workingDetails, audience, dateDetails
  } = useVacancyDetails(userUid, vacancyId)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const rootPath = location.pathname.split('/')[1]

  if (myUid === userUid && ROUTES.PROFILE_BASE !== `/${rootPath}`) {
    return (
      <Redirect
        to={ROUTES.PROFILE_VACANCY
          .replace(ROUTE_PARAMS.UID, myUid)
          .replace(ROUTE_PARAMS.VACANCY_ID, vacancyId)}
      />
    )
  }

  if (!isLoading && vacancies?.length && !name) {
    return (
      <Redirect
        to={`/${rootPath}/${userUid}${ROUTE_SEGMENTS.VACANCIES}`}
      />
    )
  }

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingIcon spinner />
      </div>
    )
  }

  const onOpenCloseModal = () => {
    setIsVisibleCloseModal(CloseModalType.VACANCY)
    setVacancyId(vacancyId || '')
  }

  const onOpenEditModal = () => {
    setIsOpenEditModal(true)
  }

  return (
    <div
      className={cn(styles.vacancyContainer, styles.tabContent)}
      style={{ ...(profileContentHeight && { maxHeight: `calc(100vh - ${profileContentHeight}px)` }) }}
    >
      <Header
        goBack={goBack}
        onOpenCloseModal={onOpenCloseModal}
        userUid={userUid}
        name={name || ''}
        onOpenEditModal={onOpenEditModal}
        isClosed={status === STATUS_VACANCIES.CLOSED}
      />
      {status === STATUS_VACANCIES.CLOSED && (
        <div className={styles.closeVacancy}>{profileTranslations.textClosedVacancy}</div>
      )}
      <div className={styles.vacancyDetails}>
        {!!locations.length && (
          <Locations title={profileTranslations.locationTitle} locations={locations} />
        )}
        <WorkingDetails title={profileTranslations.subtitleVacancyDetails} workingDetails={workingDetails} />
        {!!tags?.length && (
          <Tags title={profileTranslations.tags} tags={tags} />
        )}
        {!!details?.description && (
          <Description title={profileTranslations.aboutJob} description={details.description} />
        )}
        {!!audience && (
          <Audience title={profileTranslations.jobAudienceTitle} audience={audience} />
        )}
        <p className={styles.dateDetails}>{dateDetails}</p>
      </div>
      <ManageVacancyModal
        isOpen={isOpenEditModal}
        onClose={() => setIsOpenEditModal(false)}
        vacancyId={vacancyId}
      />
    </div>
  )
}
