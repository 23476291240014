import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { Logo } from 'common/icons/Logo'
import { PublicNavBar } from 'features/PublicNavBar'
import styles from './styles.module.sass'

export const PublicHeader = () => {
  return (
    <header className={cn(styles.header, styles.publicHeader)}>
      <div className={styles.content}>
        <NavLink to="/" className={styles.publicLogo}>
          <Logo />
        </NavLink>
        <PublicNavBar />
      </div>
    </header>
  )
}
