import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { LENGTH_LIMITS, REGEX } from 'common/constants'
import { PROFILE_FIELDS } from 'features/MyProfile/constants'
import { SocialLinkType } from 'features/MyProfile/types'
import { LINK_ERRORS } from 'features/Translations/constants'
import { selectLinkErrorsTranslations } from 'features/Translations/selectors'

type LinksFieldType = Pick<SocialLinkType, 'url'>

export const useLinks = (
  defaultLinks: SocialLinkType[]
): { validateLinks: () => boolean } => {
  const linksErrorsTranslations = useSelector(selectLinkErrorsTranslations)
  const {
    clearErrors,
    setError,
    watch
  } = useFormContext<{ links: LinksFieldType[] }>()
  const { [PROFILE_FIELDS.LINKS]: links } = watch()

  const areLinksEqual = (a: string, b: string) => {
    const formattedA = a?.match(REGEX.STARTS_WITH_HTTP)
      ? a?.split(REGEX.STARTS_WITH_HTTP)[1]?.toLowerCase()
      : a.toLowerCase()
    const formattedB = b?.match(REGEX.STARTS_WITH_HTTP)
      ? b?.split(REGEX.STARTS_WITH_HTTP)[1]?.toLowerCase()
      : b.toLowerCase()
    return formattedA === formattedB
  }

  const getDupLinkIdx = (index: number, { url }: LinksFieldType) => {
    return links.findIndex(
      (link, idx) =>
        index !== idx &&
        areLinksEqual(url, link.url)
    )
  }

  const onError = (index: number, errType: LINK_ERRORS, fieldName: PROFILE_FIELDS.LINK_URL) => {
    const translationSub = () => {
      switch (errType) {
        case LINK_ERRORS.URL_LENGTH: return LENGTH_LIMITS.MAX.LINK_URL
        default: return ''
      }
    }
    setError(
      `${PROFILE_FIELDS.LINKS}.${index}.${fieldName}`,
      { type: 'custom', message: linksErrorsTranslations[errType].replace('%', String(translationSub())) }
    )
  }

  const validateLinks = () => {
    const { isInvalid } = links.reduce<{ isInvalid: boolean }>((acc, { url }, index) => {
      if ((links.length >= 1 || defaultLinks.length >= 1) && (!url)) {
        clearErrors([
          `${PROFILE_FIELDS.LINKS}.${index}.${PROFILE_FIELDS.LINK_URL}`
        ])
        return acc
      }

      const isUrlEmpty = !url
      const isUrlPatternViolation = !isUrlEmpty && !url.match(REGEX.WEBSITE_LINK)
      const isInvalidUrlLength = url?.length > LENGTH_LIMITS.MAX.LINK_URL

      if (isUrlPatternViolation) onError(index, LINK_ERRORS.URL_PATTERN, PROFILE_FIELDS.LINK_URL)
      if (isUrlEmpty) onError(index, LINK_ERRORS.EMPTY_URL, PROFILE_FIELDS.LINK_URL)
      if (isInvalidUrlLength) onError(index, LINK_ERRORS.URL_LENGTH, PROFILE_FIELDS.LINK_URL)

      const duplicatedLinkIdx = links.length <= 1 ? -1 : getDupLinkIdx(index, { url })
      if (duplicatedLinkIdx > -1) {
        const lastDuplicatedIndex = Math.max(duplicatedLinkIdx, index)
        onError(lastDuplicatedIndex, LINK_ERRORS.DUPLICATE, PROFILE_FIELDS.LINK_URL)
      }

      const hasError =
      isUrlEmpty ||
      isUrlPatternViolation ||
      isInvalidUrlLength ||
      duplicatedLinkIdx > -1

      if (!hasError) {
        clearErrors([
          `${PROFILE_FIELDS.LINKS}.${index}.${PROFILE_FIELDS.LINK_URL}`
        ])
      }

      acc.isInvalid = acc.isInvalid || hasError
      return acc
    }, { isInvalid: false })

    return isInvalid
  }

  return { validateLinks }
}
