import { FC } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { Image } from 'common/components/Image'
import { MiniTrustIcon } from 'common/icons/MiniTrustIcon'
import { SmallTrustIcon } from 'common/icons/SmallTrustIcon'
import { SmallWhiteTrustIcon } from 'common/icons/SmallWhiteTrustIcon'
import { TrustIcon } from 'common/icons/TrustIcon'
import { WhiteTrustIcon } from 'common/icons/WhiteTrustIcon'
import { WhiteTrustIconMin } from 'common/icons/WhiteTrustIconMin'
import { selectTrustLevels } from 'features/MyProfile/selectors'
import styles from './styles.module.sass'

interface IImageWithTrustMark {
  photoURL: string
  alt?: string
  width: number
  className?: string
  onClick?: () => void
  photo: string
  uid: string
}

const getTrustContentPosition = (width: number) => {
  if (width >= 122) {
    return { right: -15, bottom: -11 }
  }

  if (width >= 30) {
    return { top: width - 16, left: width - 12 }
  }

  return { top: 14, left: 16 }
}

const getTrustIcon = (width: number, isTrustContactLevel: boolean) => {
  const trustIcon = isTrustContactLevel ? <TrustIcon /> : <WhiteTrustIcon />
  const miniTrustIcon = isTrustContactLevel ? <MiniTrustIcon /> : <WhiteTrustIconMin />
  const smallTrustIcon = isTrustContactLevel ? <SmallTrustIcon /> : <SmallWhiteTrustIcon />

  if (width >= 122) {
    return trustIcon
  }

  if (width >= 30) {
    return miniTrustIcon
  }

  return smallTrustIcon
}

const getLevelTextStyle = (width: number) => {
  if (width >= 122) {
    return styles.textStyle
  }

  if (width >= 30) {
    return styles.miniTextStyle
  }

  return styles.smallTextStyle
}

export const ImageWithTrustMark: FC<IImageWithTrustMark> = ({
  photoURL, alt, width, className, onClick, photo, uid
}) => {
  const trustLevels = useSelector(selectTrustLevels)
  const isTrustContactLevel = trustLevels[uid] === 1
  const trustContentPositionStyles = getTrustContentPosition(width)
  const trustIcon = getTrustIcon(width, isTrustContactLevel)
  const levelTextStyle = getLevelTextStyle(width)

  return (
    <div
      className={cn(className, styles.imageWithTrustMark)}
      onClick={onClick}
    >
      <div className={styles.imageContainer}>
        <Image
          photoURL={photoURL}
          photo={photo}
          width={width}
          alt={alt}
          level={trustLevels[uid] || 0}
        />
      </div>
      {(!!trustLevels[uid]) && (
        <div className={styles.trustInfo} style={trustContentPositionStyles}>
          <div className={styles.trustMark}>
            {trustIcon}
            <div className={cn(
              trustLevels[uid] === 1 ? styles.trustLevel : styles.level,
              levelTextStyle,
              isTrustContactLevel && styles.trustedContact
            )}
            >
              {trustLevels[uid]}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
