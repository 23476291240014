import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ResponsiveLayout } from 'common/components/ResponsiveLayout_V2'
import { QUERY_PARAMS, ROUTES } from 'common/constants'
import { useSpecialties } from 'common/hooks/useSpecialities'
import { LoadingIcon } from 'common/icons/LoadingIcon'
import { checkRequestPublicProfile } from 'common/utils/checkRequestPublicProfile'
import {
  actions as actionsContacts, getPublicProfile, getPublicResume, getPublicVacancy
} from 'features/Contacts/actions'
import { getIsLoadingOtherProfile } from 'features/Contacts/selectors'
import { actions as actionsFilter } from 'features/FilterItems/actions'
import { selectUserVacanciesById } from 'features/Home/selectors'
import { MyListingsFilters } from 'features/Listing/types'
import { PublicProfile as Profile } from 'features/MyProfile/components/ProfilePage/PublicProfile'
import { getOtherProfile } from 'features/MyProfile/selectors'
import styles from './styles.module.sass'

export const PublicProfile = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname, search } = useLocation()
  const isLoadingPublicProfile = useSelector(getIsLoadingOtherProfile)
  const paramsPublicProfile = checkRequestPublicProfile(history.location.search)
  const publicProfile = useSelector(getOtherProfile)
  const { isLoading: isSpecialtiesLoading } = useSpecialties(paramsPublicProfile?.uid || '')
  const vacancies = useSelector(selectUserVacanciesById(paramsPublicProfile?.uid || ''))
  const specialitiesLoaded = !paramsPublicProfile?.speciality_id || !isSpecialtiesLoading
  const vacanciesLoaded = !paramsPublicProfile?.vacancy_id || !!vacancies
  const isLoading = isLoadingPublicProfile || !specialitiesLoaded || !vacanciesLoaded

  useEffect(() => {
    if (paramsPublicProfile) {
      const {
        uid, token, vacancy_id, speciality_id
      } = paramsPublicProfile
      if (vacancy_id) {
        dispatch(getPublicVacancy(uid, token, vacancy_id))
        dispatch(actionsFilter.setListingFilters({ listingFilters: [MyListingsFilters.VACANCY] }))
      } else if (speciality_id) {
        dispatch(actionsFilter.setListingFilters({ listingFilters: [MyListingsFilters.SPECIALTY] }))
        dispatch(getPublicResume(uid, token, speciality_id))
      } else {
        dispatch(getPublicProfile(uid, token))
      }
      const fullPath = `${pathname}${search}`
      history.replace(
        `${fullPath}${fullPath.includes('?') ? '&' : '?'}${QUERY_PARAMS.REDIRECT}=${encodeURIComponent(fullPath)}`
      )
    }

    return () => {
      dispatch(actionsContacts.setOtherProfile(null))
      if (paramsPublicProfile) dispatch(actionsContacts.setParamsPublicProfile(null))
    }
  }, [])

  return (
    <ResponsiveLayout
      rightContent={(
        <>
          {!isLoading && !!publicProfile && (
            <div className={styles.publicProfileContainer}>
              <Profile openedProfile={publicProfile} isOtherProfile basePath={ROUTES.PUBLIC_PROFILE} />
            </div>
          )}
          {(isLoading || !publicProfile) && (
            <div className={styles.publicProfileContainerSpinner}>
              <LoadingIcon spinner />
            </div>
          )}
        </>
      )}
      isLeftHiddenOnMobile
      className={styles.onlyRightContent}
      wrapper={styles.wrapper}
    />

  )
}
