import {
  FC,
  ReactElement
} from 'react'
import cn from 'classnames'
import {
  autoUpdate,
  flip,
  Placement,
  useFloating
} from '@floating-ui/react-dom'
import { ArrowBottomIcon } from 'common/icons/ArrowBottomIcon'
import { DropdownMenu } from 'features/DropdownMenu_V2'
import { ProfileSection } from 'features/NavBar/components/DropDownButton/ProfileSection'
import { DropDownItemType } from 'features/NavBar/types'
import styles from './styles.module.sass'

interface IDropDownButton {
  icon: ReactElement | null
  list: DropDownItemType[]
  isActive?: boolean
  arrow?: boolean
  isOpenList: boolean
  onCloseList: () => void
  onToggleOpenList?: () => void
  listWidth?: number | string
  className?: string;
  dynamicPosition?: boolean;
  dropdownPlacement?: Placement;
  isMobileScreen?: boolean
  closeSideBar?: () => void
}

export const DropDownButton: FC<IDropDownButton> = ({
  icon,
  list,
  isActive,
  arrow = true,
  isOpenList,
  onCloseList,
  onToggleOpenList,
  listWidth = 'unset',
  className,
  dynamicPosition = false,
  dropdownPlacement,
  isMobileScreen = false,
  closeSideBar
}) => {
  const { refs, floatingStyles } = useFloating({
    placement: dropdownPlacement,
    middleware: [flip()],
    whileElementsMounted(...args) {
      const cleanup = autoUpdate(...args)
      return cleanup
    }
  })

  const floatingContentStyles = dynamicPosition ? floatingStyles : {}

  return (
    <div className={cn(styles.dropDownButton, isMobileScreen && styles.border)}>
      {!!icon && (
        <div
          className={cn(
            styles.button,
            !isMobileScreen && styles.defaultStyles,
            isOpenList && styles.openDropDownList,
            isActive && styles.active
          )}
          onClick={isMobileScreen ? undefined : onToggleOpenList}
        >
          {icon}
          {arrow && <div className={styles.arrow}><ArrowBottomIcon /></div>}
        </div>
      )}
      {isOpenList && (
        <div
          className={cn(styles.dropDownContainer,
            !isMobileScreen && styles.isFullScreen, className)}
          ref={dynamicPosition ? refs.setFloating : null}
          style={{ width: listWidth, ...floatingContentStyles }}
        >
          <ProfileSection
            onAfterClick={() => {
              onCloseList()
              closeSideBar?.()
            }}
          />
          <DropdownMenu
            dropDownList={list}
            dropdownMenuStyles={styles.dropdownMenuStyles}
            onCloseList={onCloseList}
          />
        </div>
      )}
    </div>
  )
}
