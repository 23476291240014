import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingIcon } from 'common/icons/LoadingIcon'
import { IntroduceIcon } from 'common/icons_V2/IntroduceIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { actions as contactsActions } from 'features/Contacts/actions'
import { Base } from 'features/MyProfile/components/ProfilePopup/Base'
import { ProfileType } from 'features/MyProfile/types'
import { selectOtherProfileTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IIntroduce {
  profile: ProfileType,
  closePopup: () => void;
}

export const Introduce: FC<IIntroduce> = ({ profile, closePopup }) => {
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const onClick = async () => {
    try {
      setIsLoading(true)
      dispatch(contactsActions.setUserToIntroduce(profile))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Base
      isDisabled={isLoading}
      closePopup={closePopup}
      onClick={onClick}
      style={{ cursor: isLoading ? 'default' : 'pointer' }}
    >
      {isLoading ? (
        <div className={styles.loadingIcon}>
          <LoadingIcon spinner />
        </div>
      ) : (
        <IntroduceIcon color="#374957" />
      )}
      <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="p">
        {otherProfileTranslations.introduceButton}
      </Typography>
    </Base>
  )
}
