import { FC, ReactNode, useEffect } from 'react'
import cn from 'classnames'
import { PrimaryButton } from 'common/components/Button/PrimaryButton'
import { SecondaryButton } from 'common/components/Button/SecondaryButton'
import { CloseIcon } from 'common/icons/CloseIcon'
import { PreloaderIcon } from 'common/icons/PreloaderIcon'
import styles from './styles.module.sass'

export enum ButtonType {
  DEFAULT,
  DANGER
}

interface IModalFooter {
  buttonType?: ButtonType;
  secondaryButtonType?: ButtonType;
  primaryButtonText?: string
  primaryButtonOnClick?: (e: any) => void // TODO
  isPrimaryButtonDisabled?: boolean
  isPrimaryButtonLoading?: boolean
  footerStyles?: string
  buttonStyles?: string
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  isSecondaryButtonDisabled?: boolean;
  isSecondaryButtonLoading?: boolean;
}

interface IModal extends Partial<IModalFooter> {
  title?: string | undefined | ReactNode
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
  width?: number | string
  scrollableContent?: ReactNode
  footerWithScrollableContent?: ReactNode
  isFullScreenOnMobile?: boolean
  isLoading?: boolean
  isClosable?: boolean
}

export const ModalFooter: FC<IModalFooter> = ({
  buttonType = 'default',
  secondaryButtonType = 'default',
  footerStyles,
  primaryButtonText,
  buttonStyles,
  primaryButtonOnClick,
  isPrimaryButtonDisabled,
  isPrimaryButtonLoading,
  secondaryButtonText,
  secondaryButtonOnClick,
  isSecondaryButtonDisabled,
  isSecondaryButtonLoading
}) => {
  if (!primaryButtonText) return <></>
  return (
    <div className={cn(footerStyles, styles.footer)}>
      {secondaryButtonText && (
        <SecondaryButton
          title={secondaryButtonText}
          onClick={secondaryButtonOnClick}
          className={cn(secondaryButtonType === ButtonType.DANGER && styles.dangerSecondaryButton)}
          disabled={isSecondaryButtonDisabled || isSecondaryButtonLoading}
          isLoading={isSecondaryButtonLoading}
        />
      )}
      <PrimaryButton
        type="submit"
        title={primaryButtonText}
        className={cn(
          buttonStyles || styles.primaryButton,
          buttonType === ButtonType.DANGER && styles.dangerPrimaryButton
        )}
        onClick={primaryButtonOnClick}
        disabled={isPrimaryButtonDisabled || isPrimaryButtonLoading}
        isLoading={isPrimaryButtonLoading}
      />
    </div>
  )
}

export const Modal: FC<IModal> = ({
  title,
  isOpen,
  onClose,
  children,
  width = 560,
  primaryButtonText,
  primaryButtonOnClick,
  isPrimaryButtonDisabled,
  isPrimaryButtonLoading,
  scrollableContent,
  footerWithScrollableContent,
  footerStyles,
  buttonStyles,
  isFullScreenOnMobile = false,
  secondaryButtonText,
  secondaryButtonOnClick,
  isSecondaryButtonDisabled,
  isSecondaryButtonLoading,
  buttonType,
  secondaryButtonType,
  isLoading = false,
  isClosable = false
}) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen) {
        onClose()
      }
    }
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      document.body.style.overflow = 'auto'
      if (isOpen) document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, onClose])

  if (!isOpen) return null

  return (
    <div className={cn(styles.wrapper, isFullScreenOnMobile && styles.mobileWrapper)}>
      <div
        className={cn(styles.container, isFullScreenOnMobile && styles.mobileContainer)}
        style={{ width }}
      >
        {(title || isClosable) && (
          <div className={styles.header}>
            <div className={styles.title}>
              {title}
              {isLoading && <div className={styles.loading}><PreloaderIcon stroke="#D7DFED" /></div>}
            </div>
            <div className={styles.close} onClick={onClose}><CloseIcon /></div>
          </div>
        )}
        {children && (
          <div className={styles.children}>
            {children}
          </div>
        )}
        {scrollableContent && (
          <div className={styles.scrollableContent}>
            {scrollableContent}
          </div>
        )}
        {footerWithScrollableContent || (
          <ModalFooter
            buttonType={buttonType}
            secondaryButtonType={secondaryButtonType}
            footerStyles={footerStyles}
            primaryButtonText={primaryButtonText}
            buttonStyles={buttonStyles}
            primaryButtonOnClick={primaryButtonOnClick}
            isPrimaryButtonDisabled={isPrimaryButtonDisabled}
            isPrimaryButtonLoading={isPrimaryButtonLoading}
            secondaryButtonText={secondaryButtonText}
            secondaryButtonOnClick={secondaryButtonOnClick || onClose}
            isSecondaryButtonDisabled={isSecondaryButtonDisabled}
            isSecondaryButtonLoading={isSecondaryButtonLoading}
          />
        )}
      </div>
    </div>
  )
}
