import type { ActionTypes, AnchorPointType } from 'common/components/ContextMenu/types'

const initialState = {
  menuType: '',
  anchorPoint: { x: 0, y: 0 } as AnchorPointType,
  target: null as any
}

export const ContextMenuReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case 'CONTEXT_MENU__SET_MENU_TYPE': {
      return {
        ...state,
        menuType: action.menuType,
        target: action.target
      }
    }
    case 'CONTEXT_MENU__SET_ANCHOR_POINT': {
      return {
        ...state,
        anchorPoint: { x: action.x, y: action.y }
      }
    }
    default: return state
  }
}
