export const Medium = () => (
  <svg width="32" height="32" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3364_7408)">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 0.5C18.6277 0.5 24 5.87234 24 12.5C24 19.1277 18.6277 24.5 12 24.5C5.37234 24.5 0 19.1277 0 12.5C0 5.87234 5.37234 0.5 12 0.5Z" fill="#374957" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.47501 7.97095C10.9763 7.97095 13.0041 9.99876 13.0041 12.5C13.0041 15.0013 10.9763 17.0291 8.47501 17.0291C5.97422 17.0291 3.94641 15.0013 3.94641 12.5C3.94641 9.99876 5.97422 7.97095 8.47501 7.97095ZM19.2553 8.68626C19.6964 8.68626 20.0536 10.3939 20.0536 12.5C20.0536 14.6061 19.6964 16.3138 19.2553 16.3138C18.8147 16.3138 18.457 14.6061 18.457 12.5C18.457 10.3939 18.8147 8.68626 19.2553 8.68626ZM15.7219 8.3286C16.9875 8.3286 18.0131 10.1961 18.0131 12.5C18.0131 14.8039 16.9875 16.6714 15.7219 16.6714C14.4563 16.6714 13.4302 14.8039 13.4302 12.5C13.4302 10.1961 14.4563 8.3286 15.7219 8.3286Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_3364_7408">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)
