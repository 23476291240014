import { FC, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import cn from 'classnames'
import { useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { getIsFullScreen } from 'common/selectors'
import { getQueryObject } from 'common/utils/url'
import { Footer } from 'features/Footer'
import { SEARCH_PARAMS } from 'features/Translations/constants'
import { useSetPageScrollYPositionContext } from 'providers/SetPageScrollYPositionProvider'
import styles from './styles_V2.module.sass'

export const LayoutBody: FC<PropsWithChildren<{}>> = ({ children }) => {
  const isFullScreen = useSelector(getIsFullScreen)
  const isMobile = useMobileMediaQuery()
  const { setRef } = useSetPageScrollYPositionContext()
  const { search } = useLocation()

  const onSetRef = isMobile ? () => null : (divRef: HTMLDivElement | null) => {
    if (!getQueryObject(search)?.[SEARCH_PARAMS.HOME_SELECTED_CARD]) setRef?.(divRef)
  }

  const style = isFullScreen ? {
    maxWidth: '100%',
    overflow: 'auto',
    height: 'calc(100vh - 100px)',
    border: '1px solid #D7DFED'
  } : {}

  return (
    <div className={cn(styles.wrapper, styles.content)} ref={onSetRef}>
      <div className={styles.container} style={style}>
        {children}
      </div>
      <Footer hideOnMobile />
    </div>
  )
}
