import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { UnTrustIcon } from 'common/icons/UnTrustIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { selectMemoizedUsers } from 'features/Home/selectors'
import { Base } from 'features/MyProfile/components/ProfilePopup/Base'
import { selectTrustedUids } from 'features/MyProfile/selectors'
import { PROFILE_ACTIONS_KEYS } from 'features/Translations/constants'
import { selectOtherProfileTranslations } from 'features/Translations/selectors'
import { useContactActions } from 'providers/ContactActionsProvider'

interface IUnTrust {
  uid: string;
  closePopup: () => void;
}

export const UnTrust: FC<IUnTrust> = ({ uid, closePopup }) => {
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)
  const users = useSelector(selectMemoizedUsers)
  const isDisabled = !users?.[uid]
  const trustedUids = useSelector(selectTrustedUids)
  const [isLoading, setIsLoading] = useState(false)
  const { untrust } = useContactActions()

  if (!trustedUids.includes(uid)) return <></>

  return (
    <Base
      isDisabled={isDisabled || isLoading}
      closePopup={closePopup}
      onClick={async () => {
        if (isLoading) return
        setIsLoading(true)
        await untrust(uid)
        setIsLoading(false)
      }}
    >
      <UnTrustIcon />
      <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="p">
        {otherProfileTranslations[PROFILE_ACTIONS_KEYS.UNTRUST]}
      </Typography>
    </Base>
  )
}
