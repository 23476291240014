import React, {
  FC, useCallback, useEffect, useMemo, useRef, useState
} from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { LENGTH_LIMITS, SYMBOLS } from 'common/constants'
import { FIELDS } from 'common/enums'
import { useInputFocusDetect } from 'common/hooks/useInputFocusDetect'
import { useOutside } from 'common/hooks/useOutside'
import { LocationIcon } from 'common/icons_V2/LocationIcon'
import {
  findLocationRecord, formatLocation, getRegionShort, joinLocations
} from 'common/utils/profile'
import { truncateValue } from 'common/utils/truncateValue'
import { EXPENDABLE_INPUT_OPTION_ID } from 'features/Auth/components/OnboardingStepsNew/PopupMenu_V2'
import { FormExpandableInput, OptionType, withExpandableInputProps } from 'features/FormExpandableInput_V2'
import { FooterInput } from 'features/FormInput_V2/FooterInput'
import { getAllLocations } from 'features/Locations/actions'
import { selectAllLocations } from 'features/Locations/selectors'
import { useLocationValidation } from 'features/MyProfile/components/VacancySpeciality_V2/validator'
import { selectErrorMsgsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

const WithExpandableInput = withExpandableInputProps(FormExpandableInput)

export const LocationField: FC<any> = ({
  title,
  error,
  fieldName,
  isSubmitted,
  isOnEditing,
  placeholder,
  helperText,
  maxCharCount,
  showMaxCharCount,
  isRequired = true,
  isMultiselect = true,
  isWithCountries = true
}) => {
  const { validateLocationInput } = useLocationValidation(isWithCountries)
  const formContext = useFormContext<any>()
  const inputRef = useRef<HTMLInputElement>(null)
  const popupMenuRef = useRef<HTMLDivElement>(null)
  const allLocations = useSelector(selectAllLocations(isWithCountries))
  const errorMsgsTranslations = useSelector(selectErrorMsgsTranslations)
  const [isLocationsLoading, setIsLocationsLoading] = useState(false)
  const [forceClear, setForceClear] = useState(false)
  const [options, setOptions] = useState<OptionType>([])
  const dispatch = useDispatch<(action: any) => Promise<boolean>>()
  const [isDisabled, setIsDisabled] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [searchString, setSearchString] = useState('')
  const {
    getValues, clearErrors, setValue, setError, trigger
  } = formContext
  const [locations, setLocations] = useState<string[]>(getValues(fieldName) ?? [])

  useOutside(popupMenuRef, () => {
    setIsOpen(false)
  })

  useEffect(() => {
    const shouldOpen = !!searchString && !!options.length
    if (!shouldOpen) {
      setIsOpen(false)
    }
    if ((document.activeElement === inputRef?.current) && shouldOpen && !isDisabled) {
      setIsOpen(true)
    }
    if (isDisabled) {
      setIsOpen(false)
    }
    setIsDisabled(!searchString && !options.length)
  }, [searchString, options])

  useEffect(() => {
    setIsDisabled(false)
  }, [])

  useEffect(() => {
    fetchLocations()
  }, [])

  const fetchLocations = async () => {
    setIsLocationsLoading(true)
    await dispatch(getAllLocations())
    setIsLocationsLoading(false)
  }

  const rendererWithIcon = (option: string[]) => {
    const [countryCode, region, city] = option
    const result = [
      city,
      getRegionShort(countryCode, region, allLocations.regions),
      countryCode
    ]
      .filter(Boolean)
      .join(SYMBOLS.FE_LOCATION_SEPARATOR)
    return (
      <div className={styles.locationItem}>
        <LocationIcon />
        {result}
      </div>
    )
  }

  const renderer = (option: string[]) => {
    const [countryCode, region, city] = option
    return [
      city,
      getRegionShort(countryCode, region, allLocations.regions),
      countryCode
    ]
      .filter(Boolean)
      .join(SYMBOLS.FE_LOCATION_SEPARATOR)
  }

  const filteredCities = useMemo(() => {
    if (allLocations.cities && isMultiselect) {
      return allLocations.cities.filter((city: string[]) =>
        !locations.includes(formatLocation(renderer(city), allLocations.regions)))
    }
    return []
  }, [locations, isLocationsLoading])

  useEffect(() => {
    setOptions(isMultiselect ? filteredCities : allLocations.cities)
  }, [filteredCities])

  const matchBySearchString = useCallback((locationRecord: string[], searchString: string) => {
    const [countryCode, regionName, cityName] = locationRecord
    const searchSegments = searchString.split(/\s*,\s*/)?.filter(Boolean) || []
    const isSingleSearch = searchSegments.length === 1
    const countries = allLocations.countries as { [countryCode: string]: string }

    const compare = (a: string, b: string) => (a && b ? a.toLowerCase().includes(b.toLowerCase()) : false)
    const countryMatch = (search: string) => {
      return compare(countryCode, search) || compare(countries[countryCode], search)
    }
    const regionMatch = (search: string) => {
      return compare(
        getRegionShort(countryCode, regionName, allLocations.regions), search
      ) || compare(regionName, search)
    }

    if (isSingleSearch) {
      return countryMatch(searchSegments[0])
        || compare(cityName, searchSegments[0])
        || regionMatch(searchSegments[0])
    }

    const [a, b, c] = searchSegments
    return (countryMatch(a) && regionMatch(b) && (!c || compare(cityName, c)))
      || (countryMatch(a) && compare(cityName, b) && (!c || regionMatch(c)))
      || (regionMatch(a) && compare(cityName, b) && (!c || countryMatch(c)))
      || (regionMatch(a) && countryMatch(b) && (!c || compare(cityName, c)))
      || (compare(cityName, a) && regionMatch(b) && (!c || countryMatch(c)))
      || (compare(cityName, a) && countryMatch(b) && (!c || regionMatch(c)))
  }, [isLocationsLoading])

  const onLocationSave = (value: string) => {
    const [countryCode, regionCode, city] = value
      .trim()
      .split(SYMBOLS.FE_LOCATION_SEPARATOR)
      .reverse()
    onOptionSelect([countryCode, regionCode, city])
  }

  const getValueForLocal = (value: string) => {
    const items = value
      .trim()
      .split(SYMBOLS.FE_LOCATION_SEPARATOR)
      .reverse()
    const findCity = findLocationRecord(items, allLocations.cities, allLocations.regions)
    const country = (items[0] ?? '').toUpperCase()
    const region = items.length > 2 ? findCity[1] : items[1]
    const city = findCity[2] ?? ''
    return joinLocations(country, region, city, SYMBOLS.FE_LOCATION_SEPARATOR)
  }

  const deleteByIndex = (index: number) => {
    setLocations((prevState: string[]) => prevState.filter((_, i) => i !== index))
    const location = getValues(fieldName).filter((_: string, i: number) => i !== index)
    setValue(fieldName, location, { shouldDirty: true })
    clearErrors(fieldName)
    trigger(fieldName)
    if (inputRef?.current?.value) {
      onLocationSave(inputRef.current.value)
    }
  }

  const onOptionSelect = useCallback((item: string[]) => {
    if (item.some(Boolean)) {
      const findCity = findLocationRecord(item, allLocations.cities, allLocations.regions)
      const country = (item[0] ?? '').toUpperCase()
      const region = item.length > 2 ? findCity[1] : item[1]
      const city = findCity[2] ?? ''
      const {
        BE_LOCATION_SEPARATOR: BE_SEPARATOR,
        FE_LOCATION_SEPARATOR: FE_SEPARATOR
      } = SYMBOLS
      if (isMultiselect) {
        setForceClear(false)
        const valueForLocal = joinLocations(country, region, city, FE_SEPARATOR)
        const valueForBe = joinLocations(country, region, city, BE_SEPARATOR)
        const locationsField = getValues(fieldName)
        const { isValid, found } = validateLocationInput(
          {
            [fieldName]: [...locationsField],
            [FIELDS.LOCATION_TYPE]: getValues(FIELDS.LOCATION_TYPE)
          },
          (...args) => {
            if ((isSubmitted || isOnEditing) || args.includes(fieldName)) { setError(...args) }
          },
          clearErrors,
          errorMsgsTranslations,
          valueForLocal
        )
        if (isValid === true) {
          setForceClear(true)
          setValue(fieldName, [...locationsField, found || valueForBe], { shouldDirty: true })
          setLocations((location) => [...location, found || valueForLocal])
          setIsOpen((isOpen) => !isOpen)
        }
      } else {
        const found = findLocationRecord(item, allLocations.cities, allLocations.regions)

        if (!found?.length) {
          // TODO: Make error messages dynamic and reusable for each logic.
          const message = isRequired && !item
            ? errorMsgsTranslations.wrongLocation
            : errorMsgsTranslations.locationError
          setError(fieldName, { type: 'custom', message })
        } else {
          clearErrors(fieldName)
          setValue(
            fieldName,
            [country, region, city].join(BE_SEPARATOR),
            { shouldDirty: true }
          )
        }
      }
    }
  }, [getValues, validateLocationInput, setValue, isSubmitted, isOnEditing])

  const getOptionValue = (option: OptionType): string => {
    if (renderer && Array.isArray(option)) {
      return renderer(option)
    }
    if (Array.isArray(option)) return ''
    return (typeof option === 'string' ? option : option?.label || '')
  }

  const filteredOptions = useMemo(() => {
    return options.filter(
      (option: any) => getOptionValue(option).toLowerCase().includes(searchString.toLowerCase())
    )
  }, [options, searchString])
  const { isInputFocused } = useInputFocusDetect(inputRef)
  const showDropdown = isOpen && !isDisabled && !!filteredOptions.length
  const isFocused = isInputFocused && showDropdown

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      onLocationSave(value)
    }
    if (e.key === 'Backspace' && locations.length && isMultiselect && !value) {
      onFocus()
      deleteByIndex(locations.length - 1)
    }
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget?.id !== EXPENDABLE_INPUT_OPTION_ID) {
      onLocationSave(e.target.value)
    }
    if (isMultiselect && (isSubmitted || isOnEditing) && !getValues(fieldName).length) {
      trigger(fieldName)
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target
    value = truncateValue(value, maxCharCount)
    if (!isMultiselect) {
      setValue(fieldName, value, { shouldDirty: true })
    }
    setSearchString(value)
    setIsOpen(!!value.length)
  }

  const onFocus = () => {
    if (!isMultiselect || error) {
      clearErrors(fieldName)
    }
    if (isMultiselect) {
      setForceClear(false)
    }
  }

  useEffect(() => {
    if (forceClear) {
      setSearchString('')
    }
  }, [forceClear])

  return (
    <div className={styles.locationContainer}>
      <Controller
        name={fieldName}
        rules={{
          validate: (value, formValues) => {
            if (isMultiselect) {
              return validateLocationInput(
                {
                  [fieldName]: value,
                  [FIELDS.LOCATION_TYPE]: formValues[FIELDS.LOCATION_TYPE]
                },
                setError,
                clearErrors,
                errorMsgsTranslations,
                getValueForLocal(inputRef?.current?.value as string)
              ).isValid
            }

            if (!isRequired && value.length) {
              const found = findLocationRecord(value.split(', '), allLocations.cities, allLocations.regions)

              if (!found?.length) {
                const message = isRequired && !value
                  ? errorMsgsTranslations.wrongLocation
                  : errorMsgsTranslations.locationError
                setError(fieldName, { type: 'custom', message })
                return message
              }
            }

            return true
          }
        }}
        render={() => {
          return (
            <>
              <WithExpandableInput
                writable
                {...isMultiselect ? {
                  disabled: locations.length >= LENGTH_LIMITS.MAX.LOCATION_COUNT,
                  multiInputLimit: LENGTH_LIMITS.MAX.LOCATION_COUNT
                } : {}}
                title={title}
                placeholder={placeholder}
                forceClear={forceClear}
                onOptionSelect={onOptionSelect}
                isMultiselect={isMultiselect}
                isRequired={isRequired}
                isOptionsLoading={isLocationsLoading}
                expandableInputStyles={cn(styles.expandableInputStyles)}
                expandableInputWrapperStyles={styles.expandableInputWrapperStyles}
                error={error}
                helperText={isMultiselect ? helperText : ''}
                filterOptionsFn={matchBySearchString}
                defaultInputValue={isMultiselect ? '' : formatLocation(getValues(fieldName), allLocations.regions)}
                onKeyDown={onKeyDown}
                renderer={renderer}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                id={fieldName}
                ref={inputRef}
                showFooter={false}
                optionDrawer={rendererWithIcon}
                options={isMultiselect ? filteredCities : allLocations.cities}
                tagFormatter={(option: string) => formatLocation(option, allLocations.regions)}
                inputs={locations}
                deleteByIndex={isMultiselect && deleteByIndex}
                blurOnselect={false}
                maxCharCount={maxCharCount}
              />
            </>
          )
        }}
      />
      <FooterInput
        value={searchString}
        error={(!isFocused || isMultiselect) ? error : ''}
        helperText={helperText}
        showMaxCharCount={showMaxCharCount && (searchString?.length ?? 0) >= LENGTH_LIMITS.LIMIT_INDICATOR}
        maxCharCount={maxCharCount}
      />
    </div>
  )
}
