import { useSelector } from 'react-redux'
import TrustingEmptySearchIcon from 'common/components/EmptySearchState/assets/TrustingEmptySearchIcon.svg'
import { Typography, TypographyVariants } from 'common/typography'
import { selectOtherProfileTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

export const TrustingList = () => {
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)

  return (
    <div className={styles.emptySearchStateTrusting}>
      <Typography
        variant={TypographyVariants.Desktop_UI_XL_Medium}
        tag="p"
        className={styles.emptySearchStateTitle}
      >
        {otherProfileTranslations.trustingModalEmptyStateTitle}
      </Typography>
      <img className={styles.emptySearchStateImage} src={TrustingEmptySearchIcon} alt="Empty Search" />
      <Typography
        variant={TypographyVariants.Desktop_UI_XL_Medium}
        className={styles.emptySearchStateDescription}
        tag="p"
      >
        {otherProfileTranslations.trustingModalEmptyStateDescription}
      </Typography>
    </div>
  )
}
