import { useSelector } from 'react-redux'
import { selectActiveSpecialities, selectSpecialities } from 'features/MyProfile/selectors'

export const useSpecialties = (uid: string) => {
  const { isLoaded, data: specialties } = useSelector(selectSpecialities(uid))
  const activeSpecialities = useSelector(selectActiveSpecialities(uid))

  return {
    specialties,
    activeSpecialities,
    isLoading: !isLoaded
  }
}
