import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react'

export enum ProfileSections {
  ABOUT = 'about',
  TAGS = 'tags',
  CONTACT_INFO = 'contactInfo',
  PROFILE_URL = 'profileUrl'
}

interface IProfileSectionsContext {
  activeSection: ProfileSections | null;
  setActiveSection: React.Dispatch<React.SetStateAction<ProfileSections | null>>;
  getIsSectionActive: (section: ProfileSections) => boolean;
  getIsSectionInActive: (section: ProfileSections) => boolean;
  withDisable: (cb: any) => (...args: any[]) => any;
}

const Context = createContext<IProfileSectionsContext>({} as IProfileSectionsContext)
export const useProfileSections = () => useContext(Context)

export const ProfileSectionsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [activeSection, setActiveSection] = useState<ProfileSections | null>(null)

  const getIsSectionActive = (section: ProfileSections) => activeSection === section
  const getIsSectionInActive = (section: ProfileSections) => !!activeSection && activeSection !== section

  const withDisable = (cb: any) => (...args: any[]) => {
    if (activeSection) return
    cb(...args)
  }

  useEffect(() => {
    return () => setActiveSection(null)
  }, [])

  return (
    <Context.Provider
      value={{
        activeSection,
        setActiveSection,
        getIsSectionActive,
        getIsSectionInActive,
        withDisable
      }}
    >
      {children}
    </Context.Provider>
  )
}
