import type { RootState } from 'common/types'
import { ErrorModalCta } from 'features/ErrorModal/types'
import { createSelector } from 'reselect'
import { NotificationTypes, ValueNotificationsHistoryType } from './types'

const notificationsHistorySelector = (state: RootState) => state.notifications

export const getNotificationsErrorMsg = (state: RootState) =>
  (state.notifications.errorMsg
    ? {
      ...state.notifications.errorMsg,
      ctaType: state.notifications.errorMsg?.ctaType || ErrorModalCta.RELOAD
    }
    : null)

export const getMyNotificationsHistory =
  createSelector(notificationsHistorySelector, ({ history }) => history)

export const selectIntroNotifications = createSelector(
  getMyNotificationsHistory,
  (history) => Object.values(history).filter(
    (notification) => notification?.type === NotificationTypes.INTRO
  ) as ValueNotificationsHistoryType[]
)

export const selectMyIntroNotifications = createSelector(
  getMyNotificationsHistory,
  (history) => Object.values(history).filter(
    (notification) => notification?.type === NotificationTypes.MY_INTRO
  ) as ValueNotificationsHistoryType[]
)

export const getIsLoadedHistory =
  createSelector(notificationsHistorySelector, ({ isLoadedHistory }) => isLoadedHistory)
export const getIsLoadedArchivedHistory =
  createSelector(notificationsHistorySelector, ({ isLoadedArchivedHistory }) => isLoadedArchivedHistory)

export const getMaxNotificationDate =
  createSelector(notificationsHistorySelector, ({ maxNotificationDate }) => maxNotificationDate)

export const getHidingNotifications =
  createSelector(notificationsHistorySelector, ({ hidingNotifications }) => hidingNotifications)

export const getArchivingNotifications =
  createSelector(notificationsHistorySelector, ({ archivingNotifications }) => archivingNotifications)
