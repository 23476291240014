import { FC } from 'react'
import { useSelector } from 'react-redux'
import { PrimaryButton } from 'common/components/Button/PrimaryButton'
import { SecondaryButton } from 'common/components/Button/SecondaryButton'
import { ThunkType } from 'common/types'
import { Modal } from 'features/Modal'
import { selectOnboardingTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IDiscardModal {
  isOpen: boolean
  onClose: () => void
  onDiscard: () => ThunkType | void
}

export const DiscardModal: FC<IDiscardModal> = ({ isOpen, onDiscard, onClose }) => {
  const onboardingTranslations = useSelector(selectOnboardingTranslations)

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      width={600}
      title={onboardingTranslations.discardChangesTitle}
    >
      <div className={styles.wrapper}>
        <p>{onboardingTranslations.discardChangesSubTitle}</p>
        <div className={styles.buttons}>
          <SecondaryButton
            className={styles.cancel}
            title={onboardingTranslations.cancelButtonText}
            onClick={onClose}
          />
          <PrimaryButton
            className={styles.discard}
            title={onboardingTranslations.discardButtonText}
            onClick={() => {
              onClose()
              onDiscard()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
