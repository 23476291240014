export type ConfigType = {
  env: AppEnv;
  baseUrl: string;
  privacyPolicyUrl: string;
  termsAndConditionsUrl: string;
  dynamicLinkBase: string;
  dynamicLink: {
    android: {
      afl: string;
      apn: string;
    }
  };
  backendAPIUrl: string;
  features: FeaturesConfig;
  firebase: FirebaseConfig;
}

type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId?: string;
  storageBucket: string;
  appId?: string;
  measurementId?: string;
  messagingSenderId?: string;
}

type FeaturesConfig = {
  callEnabled: boolean;
  jobAudienceEnabled: boolean;
}

export enum AppEnv {
  DEV = 'dev',
  PROD = 'prod',
}
