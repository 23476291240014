import React from 'react'

export const PersonIcon = ({ size = 39 }) => (
  <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1695_4167)">
      <mask id="mask0_1695_4167" className="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
        <path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1695_4167)">
        <path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="#306FD1" fillOpacity="0.1" />
      </g>
      <circle cx="28" cy="21.6863" r="10.4314" fill="#306FD1" fillOpacity="0.3" />
      <circle cx="27.9314" cy="58.1274" r="23.8137" fill="#306FD1" fillOpacity="0.3" />
    </g>
    <defs>
      <clipPath id="clip0_1695_4167">
        <rect width="56" height="56" rx="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
