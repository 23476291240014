import {
  useCallback,
  useEffect, useMemo, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { EmptySearchState } from 'common/components/EmptySearchState_V2'
import { useMobileMediaQuery, useTabletMediaQuery } from 'common/hooks/mediaQuery'
import { SpinnerIcon } from 'common/icons_V2/SpinnerIcon'
import { searchInString } from 'common/utils/searchInString'
import { DefaultState } from 'features/Contacts/Network/MyNetwork/components/DefaultState'
import { NetworkHeader } from 'features/Contacts/Network/NetworkHeader'
import { WelcomeContacts } from 'features/Contacts/Network/WelcomeContacts'
import { getUsersByTrustLevel } from 'features/Contacts/utils'
import { FilterItems } from 'features/FilterItems'
import { actions as actionsFilter } from 'features/FilterItems/actions'
import { selectNetworkFilters } from 'features/FilterItems/selectors'
import { StateType } from 'features/FilterItems/types'
import { getGlobalSearchUsers } from 'features/MyProfile/actions'
import {
  getIsEditTrustContacts,
  selectIsLoadingTrustOrUntrust,
  selectMyNetworkContacts,
  selectTrustedProfiles,
  selectTrustedUids,
  selectTrustLevels,
  selectTrustUsers
} from 'features/MyProfile/selectors'
import { ProfileType, UserType } from 'features/MyProfile/types'
import { SearchInput } from 'features/SearchInput_V2'
import { actions as actionsToast } from 'features/ToastManager/actions'
import { ToastType } from 'features/ToastManager/types'
import { PROFILE_ACTIONS_KEYS } from 'features/Translations/constants'
import {
  selectErrorMsgsTranslations,
  selectMyNetworkTranslations,
  selectProfileTranslations,
  selectToastMessages
} from 'features/Translations/selectors'
import styles from './styles.module.sass'

export enum NetworkFilters {
  ALL = 1,
  FIRST_LEVEL = 2,
  SECOND_LEVEL = 3,
  ALL_CONTACTS = 4,
  GLOBAL_SEARCH = 5
}
const MIN_SEARCH_LENGTH = 3

export const MyNetwork = () => {
  const dispatch = useDispatch()
  const contacts = useSelector(selectMyNetworkContacts)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const profileTranslations = useSelector(selectProfileTranslations)
  const toastMessages = useSelector(selectToastMessages)
  const trustLevels = useSelector(selectTrustLevels)
  const allLevelsTrustUsers = useSelector(selectTrustUsers)
  const isLoading = useSelector(selectIsLoadingTrustOrUntrust)
  const trustedProfiles = useSelector(selectTrustedProfiles)
  const networkFilters = useSelector(selectNetworkFilters)
  const isMobile = useMobileMediaQuery()
  const isTablet = useTabletMediaQuery()
  const [searchString, setSearchString] = useState('')
  const [showGlobalSearchData, setShowGlobalSearchData] = useState(false)
  const [isSearchStringValid, setIsSearchStringValid] = useState(false)
  const [globalSearchUsers, setGlobalSearchUsers] = useState<ProfileType[]>([])
  const [loadingGlobalSearchUsers, setLoadingGlobalSearchUsers] = useState(false)
  const [globalSearchButtonOrFilterClicked, setGlobalSearchButtonOrFilterClicked] = useState(false)
  const activeRequestsRef = useRef(0)
  const errorMsgsTranslations = useSelector(selectErrorMsgsTranslations)
  const trustedUids = useSelector(selectTrustedUids)
  const isEditTrustContacts = useSelector(getIsEditTrustContacts)
  const listContainerRef = useRef<HTMLDivElement>(null)
  const showLoading = networkFilters[0] === NetworkFilters.GLOBAL_SEARCH && loadingGlobalSearchUsers

  const filterAndSortContacts = useCallback(
    (contactsList: UserType[], searchString: string, isSearchStringValid: boolean) => {
      const filteredContacts = !isSearchStringValid
        ? contactsList
        : contactsList.filter((contact) => {
          const fieldsToSearch = [
            contact.displayName || '',
            contact.job?.title || '',
            contact.job?.company || '',
            contact?.phoneNumber || ''
          ]
          return fieldsToSearch.some((field) => searchInString(searchString, field))
        })
      return filteredContacts.sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''))
    }, []
  )

  const secondLevelContacts = useMemo(() => {
    return getUsersByTrustLevel(allLevelsTrustUsers, trustLevels, 2)
  }, [allLevelsTrustUsers, trustLevels])

  const firstLevelContacts = useMemo(() => {
    return contacts.filter(({ uid }) => trustedUids.includes(uid))
  }, [contacts, trustedUids])

  const filteredMyContacts = useMemo(() => {
    return filterAndSortContacts(contacts, searchString, isSearchStringValid)
  }, [contacts, searchString, filterAndSortContacts, isSearchStringValid])

  const filteredSecondLevelContacts = useMemo(() => {
    return filterAndSortContacts(secondLevelContacts, searchString, isSearchStringValid)
  }, [secondLevelContacts, searchString, isSearchStringValid, filterAndSortContacts])

  const filteredFirstLevelContacts = useMemo(() => {
    return filteredMyContacts.filter(({ uid }) => trustedUids.includes(uid))
  }, [filteredMyContacts, trustedUids])

  const combinedUids = useMemo(() => {
    return new Set(
      [
        ...firstLevelContacts,
        ...secondLevelContacts,
        ...contacts
      ].map((contact) => contact.uid)
    )
  }, [firstLevelContacts, secondLevelContacts, contacts])

  const filteredCombinedUids = useMemo(() => {
    return new Set(
      [
        ...filteredFirstLevelContacts,
        ...filteredSecondLevelContacts,
        ...filteredMyContacts
      ].map((contact) => contact.uid)
    )
  }, [filteredFirstLevelContacts, filteredSecondLevelContacts, filteredMyContacts])

  const debouncedSearch = useCallback(debounce(async (query: string) => {
    activeRequestsRef.current += 1
    try {
      const users = await getGlobalSearchUsers(query)
      const filteredUsers = users.filter((user) => !combinedUids.has(user.uid))
      if (showGlobalSearchData) {
        setGlobalSearchUsers(filteredUsers)
      }
    } catch (error) {
      dispatch(actionsToast.addToast({
        type: ToastType.ERROR,
        message: toastMessages.errorGlobalSearchMessage
      }))
    } finally {
      activeRequestsRef.current -= 1
      if (activeRequestsRef.current === 0) {
        setLoadingGlobalSearchUsers(false)
      }
    }
  }, 500), [filteredFirstLevelContacts, filteredSecondLevelContacts, filteredMyContacts, showGlobalSearchData])

  useEffect(() => {
    if (globalSearchUsers.length) {
      setGlobalSearchUsers((users) => users.filter((user) => !combinedUids.has(user.uid)))
    }
  }, [combinedUids])

  useEffect(() => {
    if (isSearchStringValid && showGlobalSearchData) {
      setLoadingGlobalSearchUsers(true)
      debouncedSearch(searchString)
    }
    return () => {
      debouncedSearch.cancel()
    }
  }, [searchString, showGlobalSearchData, isSearchStringValid])

  useEffect(() => {
    if (isEditTrustContacts) {
      handleSetNetworkFilters([NetworkFilters.ALL])
    }
  }, [isEditTrustContacts])

  useEffect(() => {
    if (networkFilters[0] === NetworkFilters.GLOBAL_SEARCH) {
      setGlobalSearchButtonOrFilterClicked(true)
    }
    if (networkFilters[0] === NetworkFilters.GLOBAL_SEARCH || (isSearchStringValid && !filteredCombinedUids?.size)) {
      setShowGlobalSearchData(true)
    }
    if (networkFilters[0] !== NetworkFilters.GLOBAL_SEARCH && filteredCombinedUids?.size && isSearchStringValid &&
      !globalSearchButtonOrFilterClicked) {
      resetGlobalSearchData()
    }
  }, [networkFilters, isSearchStringValid, filteredCombinedUids, globalSearchButtonOrFilterClicked])

  useEffect(() => {
    return () => {
      handleSetNetworkFilters([NetworkFilters.ALL])
    }
  }, [location.pathname])

  const secondLevelContactsMaxCount =
    (Number(profileTranslations.maxCountTrusts) || 0) * (Number(profileTranslations.maxCountTrusts) || 0)
  const firstLevelContactsTitle = `${myNetworkTranslations.firstLevel} (${filteredFirstLevelContacts.length}/${profileTranslations.maxCountTrusts})`
  const secondLevelContactsTitle = `${myNetworkTranslations.secondLevel} (${filteredSecondLevelContacts.length}/${secondLevelContactsMaxCount})`
  const allContactsTitle = `${myNetworkTranslations.others} (${filteredMyContacts.length})`
  const globalSearchTitle = `${myNetworkTranslations.globalSearchLevel} (${loadingGlobalSearchUsers ? 0 : globalSearchUsers.length})`
  const firstEmptyStateTitle = `${myNetworkTranslations.firstEmptyStateTitle} (${filteredFirstLevelContacts.length}/${profileTranslations.maxCountTrusts})`
  const secondEmptyStateTitle = `${myNetworkTranslations.secondEmptyStateTitle} (${filteredSecondLevelContacts.length}/${secondLevelContactsMaxCount})`
  const allContactsEmptyStateTitle = `${myNetworkTranslations.contactsEmptyStateTitle} (${filteredMyContacts.length})`
  const globalSearchEmptyStateTitle = `${myNetworkTranslations.globalSearchEmptyStateTitle} (${globalSearchUsers.length})`
  const isShowSearchInput: boolean = !((isMobile || isTablet) && !filteredMyContacts.length && !searchString.length)
  const isShowEmptyStateImage: boolean = (isMobile || isTablet) && !filteredMyContacts.length && !searchString.length
  const showEmptySearchState: boolean = !filteredMyContacts.length && !filteredSecondLevelContacts.length &&
    (!globalSearchUsers.length && !!searchString && showGlobalSearchData && !loadingGlobalSearchUsers)
  const isShowButtonVisible = isSearchStringValid && !showGlobalSearchData &&
    (networkFilters[0] === NetworkFilters.ALL || networkFilters[0] === NetworkFilters.GLOBAL_SEARCH)

  const getNetworkFilters = () => {
    const filters = []
    if (firstLevelContacts.length || secondLevelContacts.length || isSearchStringValid) {
      filters.push({ id: NetworkFilters.ALL, name: myNetworkTranslations.networkFiltersAll })
    }
    if (firstLevelContacts.length) {
      filters.push({ id: NetworkFilters.FIRST_LEVEL, name: myNetworkTranslations.networkFiltersFirst })
    }
    if (secondLevelContacts.length) {
      filters.push({ id: NetworkFilters.SECOND_LEVEL, name: myNetworkTranslations.networkFiltersSecond })
    }
    if (firstLevelContacts.length || secondLevelContacts.length || isSearchStringValid) {
      filters.push({ id: NetworkFilters.ALL_CONTACTS, name: myNetworkTranslations.networkFiltersAllContacts })
    }
    if (isSearchStringValid) {
      filters.push({ id: NetworkFilters.GLOBAL_SEARCH, name: myNetworkTranslations.networkFiltersGlobal })
    }
    return filters
  }
  const showFilters = !!getNetworkFilters()?.length

  const getTrustedByData = (contact: UserType) => {
    const trustedByList = trustedProfiles[contact?.uid] || []
    const trustedByData = trustedByList.length > 0
      ? `${myNetworkTranslations.profileTrustedBySummaryText} ${trustedByList[0]} ${trustedByList.length > 1 ? '...' : ''}`
      : ''
    return trustedByData
  }

  const getJobTitle = (contact: UserType) => {
    return contact?.job?.title || ''
  }

  const renderEmptySearchState = () => {
    return (
      <div className={styles.listContainer}>
        <EmptySearchState
          emptySearchTitle={errorMsgsTranslations.emptySearchStateTitle}
          emptySearchSubTitle={errorMsgsTranslations.emptyNetworkSearchStateSubTitle}
          isBackgroundWhite
        />
      </div>
    )
  }

  const sectionsData = {
    [NetworkFilters.FIRST_LEVEL]: {
      title: firstLevelContactsTitle,
      emptyStateTitle: firstEmptyStateTitle,
      users: filteredFirstLevelContacts,
      getContactInfo: getJobTitle,
      renderEmptySearchState,
      showTitle: !!filteredFirstLevelContacts?.length
    },
    [NetworkFilters.SECOND_LEVEL]: {
      title: secondLevelContactsTitle,
      emptyStateTitle: secondEmptyStateTitle,
      users: filteredSecondLevelContacts,
      buttonType: PROFILE_ACTIONS_KEYS.ADD_CONTACT,
      getContactInfo: getTrustedByData,
      renderEmptySearchState,
      showTitle: !!filteredSecondLevelContacts?.length
    },
    [NetworkFilters.ALL_CONTACTS]: {
      title: allContactsTitle,
      emptyStateTitle: allContactsEmptyStateTitle,
      users: filteredMyContacts,
      buttonType: PROFILE_ACTIONS_KEYS.TRUST,
      getContactInfo: getJobTitle,
      renderEmptySearchState,
      showTitle: !!filteredMyContacts?.length
    },
    [NetworkFilters.GLOBAL_SEARCH]: {
      title: globalSearchTitle,
      emptyStateTitle: globalSearchEmptyStateTitle,
      users: globalSearchUsers,
      buttonType: PROFILE_ACTIONS_KEYS.ADD_CONTACT,
      getContactInfo: getJobTitle,
      showLoadingInAllPage: loadingGlobalSearchUsers && showGlobalSearchData,
      renderEmptySearchState,
      showTitle: !!globalSearchUsers?.length || networkFilters[0] !== NetworkFilters.GLOBAL_SEARCH,
      isShowButtonVisible
    }
  }

  const resetGlobalSearchData = () => {
    setShowGlobalSearchData(false)
    setGlobalSearchButtonOrFilterClicked(false)
    setGlobalSearchUsers([])
  }

  const onShowGlobalSearchData = () => {
    setGlobalSearchButtonOrFilterClicked(true)
    setShowGlobalSearchData(true)
  }

  const onChange = (searchString: string) => {
    const isValid = searchString?.length >= MIN_SEARCH_LENGTH
    setSearchString(searchString)
    setIsSearchStringValid(isValid)
    if (!isValid) {
      resetGlobalSearchData()
    }
    if (!isValid && networkFilters[0] === NetworkFilters.GLOBAL_SEARCH) {
      handleSetNetworkFilters([NetworkFilters.ALL])
    }
  }

  useEffect(() => {
    if (!showFilters) {
      handleSetNetworkFilters([])
    }
  }, [showFilters])

  const handleSetNetworkFilters = (filters: number[]) => {
    dispatch(actionsFilter.setNetworkFilters({ networkFilters: filters }))
    if (listContainerRef.current) {
      listContainerRef.current.scrollTo(0, 0)
    }
  }

  const getSearchedState = () => {
    if (!searchString) return null
    return (
      <>
        {showLoading
          ? <SpinnerIcon spinner />
          : (
            <DefaultState
              searchString={searchString}
              isSearchStringValid={isSearchStringValid}
              sectionsData={sectionsData}
              onShowGlobalSearchData={onShowGlobalSearchData}
            />
          )}
      </>
    )
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerNavBar}>
          <NetworkHeader
            pageName={myNetworkTranslations.title}
            subTitle={myNetworkTranslations.subtitle}
            isLoading={isLoading}
          />
          {isShowSearchInput && (
            <>
              <SearchInput
                onChange={onChange}
                className={styles.searchContainer}
                value={searchString}
                placeholder={myNetworkTranslations.search}
              />
              {showFilters && (
                <FilterItems
                  items={getNetworkFilters()}
                  setActiveFilters={handleSetNetworkFilters}
                  activeFilters={networkFilters}
                  filtersContent={styles.filtersContent}
                  allowMultiple={false}
                  defaultFilter={NetworkFilters.ALL}
                  state={StateType.LIGHT}
                  refreshOnSelect
                />
              )}
            </>
          )}
        </div>
        <>
          {isShowEmptyStateImage ? (
            <WelcomeContacts />
          ) : (
            <>
              {showEmptySearchState
                ? renderEmptySearchState()
                : (
                  <div className={styles.listContainer} ref={listContainerRef}>
                    {isSearchStringValid
                      ? getSearchedState()
                      : (
                        <DefaultState
                          searchString={searchString}
                          isSearchStringValid={isSearchStringValid}
                          sectionsData={sectionsData}
                          onShowGlobalSearchData={onShowGlobalSearchData}
                        />
                      )}
                  </div>
                )}
            </>
          )}
        </>
      </div>
    </>
  )
}
