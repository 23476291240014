import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { updateProfilePhoto } from 'features/MyProfile/actions'
import { ThunkType } from 'features/MyProfile/types'
import { PhotoCropper } from 'features/PhotoCropper_V2'

interface IAvatar {
  photo: string,
  photoURL: string
  showUploadTitle?: boolean
  width?: undefined | number
}

export const Avatar: FC<IAvatar> = ({
  photo, photoURL, showUploadTitle, width
}) => {
  const dispatch = useDispatch<(action: ThunkType) => Promise<void>>()

  return (
    <PhotoCropper
      showUploadTitle={showUploadTitle}
      width={width}
      initialPhoto={{ photo, photoURL }}
      cropImage={async (imageUrl: string) => {
        await dispatch(updateProfilePhoto(imageUrl))
      }}
    />
  )
}
