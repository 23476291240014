import {
  FC, memo, useEffect, useMemo, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { ImageWithTrustMark } from 'common/components/ImageWithTrustMark_V2'
import { Typography, TypographyVariants } from 'common/typography'
import { colorTheMatch } from 'common/utils/colorTheMatch'
import { addContact } from 'features/MyProfile/actions'
import { PROFILE_ACTIONS_KEYS } from 'features/Translations/constants'
import { selectMyNetworkTranslations, selectOtherProfileTranslations } from 'features/Translations/selectors'
import { useContactActions } from 'providers/ContactActionsProvider'
import styles from './styles.module.sass'

interface IContact {
  name: string,
  photoUrl: string,
  onClick: () => void,
  searchString?: string,
  uid: string
  photo: string,
  trustedByData: string,
  isContact?: boolean
  isTrusted: boolean,
  buttonType?: string
}

export const Contact: FC<IContact> = memo(({
  name,
  photoUrl,
  onClick,
  searchString,
  photo,
  uid,
  trustedByData = '',
  isContact = false,
  isTrusted,
  buttonType = ''
}) => {
  const dispatch = useDispatch()
  const profileItemRef = useRef(null)
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const [isLoading, setIsLoading] = useState(false)
  const [buttonWidth, setButtonWidth] = useState(0)
  const { trust, untrust } = useContactActions()

  useEffect(() => {
    const maxTextLength = Math.max(
      otherProfileTranslations[PROFILE_ACTIONS_KEYS.UNTRUST].length,
      otherProfileTranslations[PROFILE_ACTIONS_KEYS.TRUST].length,
      myNetworkTranslations.myNetworkAddContactButton.length
    )
    setButtonWidth(maxTextLength * 8)
  }, [isTrusted, otherProfileTranslations, myNetworkTranslations])

  const addNewContact = async (uid: string, isExistingContact: boolean) => {
    if (isLoading) return
    setIsLoading(true)
    try {
      await dispatch(addContact(uid, isExistingContact))
    } finally {
      setIsLoading(false)
    }
  }

  const getActionPerBtnType = useMemo(() => {
    switch (buttonType) {
      case PROFILE_ACTIONS_KEYS.ADD_CONTACT: {
        if (isContact) return null
        return {
          action: addNewContact,
          title: myNetworkTranslations.myNetworkAddContactButton
        }
      }
      case PROFILE_ACTIONS_KEYS.TRUST: {
        if (isTrusted) return null
        return {
          action: trust,
          title: otherProfileTranslations[PROFILE_ACTIONS_KEYS.TRUST]
        }
      }
      case PROFILE_ACTIONS_KEYS.UNTRUST:
        if (!isTrusted) return null
        return {
          action: untrust,
          title: otherProfileTranslations[PROFILE_ACTIONS_KEYS.UNTRUST]
        }
      default:
        return null
    }
  }, [buttonType, isTrusted, myNetworkTranslations, otherProfileTranslations, trust, untrust])

  return (
    <div
      className={styles.profileItem}
      ref={profileItemRef}
    >
      <ImageWithTrustMark
        uid={uid}
        photoURL={photoUrl}
        photo={photo}
        alt={name}
        width={56}
        className={styles.imgContainer}
        onClick={onClick}
      />
      <div className={styles.profileContent} onClick={onClick}>
        <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p" className={styles.name}>
          {colorTheMatch(name, searchString || '')}
        </Typography>
        <Typography variant={TypographyVariants.Desktop_UI_S_Medium} tag="p" className={styles.jobTitle}>
          {colorTheMatch(trustedByData, searchString || '')}
        </Typography>
      </div>
      <div className={styles.buttonContainer} style={{ width: `${buttonWidth}px` }}>
        {!!getActionPerBtnType && (
          <GhostButton
            title={getActionPerBtnType.title}
            onClick={(e) => {
              e.stopPropagation()
              getActionPerBtnType.action(uid, isContact)
            }}
          />
        )}
      </div>
    </div>
  )
})
