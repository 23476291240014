import React, { FC, ReactChild } from 'react'
import { PreloaderIcon } from 'common/icons/PreloaderIcon'
import styles from './styles.module.sass'

interface IButton {
  type?: 'submit' | 'button';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading?: boolean;
  title: ReactChild;
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
}

export const Button: FC<IButton> = ({
  type = 'button',
  onClick,
  isLoading,
  title,
  className,
  disabled,
  tabIndex
}) => {
  return (
    <button
      type={type}
      onClick={(e) => {
        if (!isLoading && !disabled) onClick?.(e)
      }}
      className={`${styles.button} ${className}`}
      disabled={disabled}
      tabIndex={tabIndex}
    >
      {isLoading ? <><PreloaderIcon stroke="#96baf6" />{title}</> : <>{title}</>}
    </button>
  )
}
