import React from 'react'

export const PaperIcon = () => (
  <svg width="132" height="125" viewBox="0 0 132 125" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.5043 77.4604C35.6224 69.5244 65.6828 35.7167 72.8981 23.2195L130.567 46.3849C122.278 68.2452 79.0179 106.911 61.2508 109.646L5.5043 77.4604Z" stroke="url(#paint0_linear_6213_491118)" strokeWidth="2" />
    <path d="M3.50021 88.3924C30.538 72.9317 50.8241 32.4958 54.559 18.557L116.259 26.0072C113.91 49.2679 82.1312 97.8126 65.6773 105.053L3.50021 88.3924Z" stroke="url(#paint1_linear_6213_491118)" strokeWidth="2" />
    <path d="M5.83301 96.7253C27.948 74.7935 37.0773 30.4849 37.0773 16.0545L98.6029 7.28174C102.355 30.3578 84.2228 85.4733 70.2035 96.7253H5.83301Z" fill="url(#paint2_linear_6213_491118)" />
    <path d="M44.2767 41.1481C42.7814 45.745 41.3152 51.0474 47.4123 35.4811C55.0335 16.0233 42.9019 27.9174 38.0689 50.0809C33.2359 72.2444 48.2831 56.6428 50.3545 46.6839C52.426 36.7249 40.2586 62.7082 46.6511 64.294C53.0436 65.8799 61.7633 41.4581 58.8165 44.7551C55.8696 48.0521 48.9682 70.5081 55.5183 69.3134C62.0683 68.1187 72.7311 43.3734 69.9101 45.0904C67.0891 46.8074 56.1425 77.5243 67.0498 72.1741" stroke="white" strokeWidth="2" />
    <path d="M72.3804 73.0133C72.3804 70.4623 71.6151 65.6473 68.554 66.7953C65.4928 67.9432 69.8295 71.4189 72.3804 73.0133Z" fill="white" />
    <path d="M74.772 74.9265C81.277 72.0567 81.6277 73.7308 80.99 74.9265C88.1646 75.644 101.749 78.1312 98.6874 82.3403C94.8609 87.6017 63.7709 92.8631 61.6185 88.5583C59.4661 84.2535 82.6641 81.1445 71.1847 81.1445C59.7053 81.1445 62.5751 84.971 47.7476 84.7318C32.9201 84.4927 40.3338 78.0355 57.7921 77.7964C75.2503 77.5572 66.6408 78.5138 74.772 74.9265Z" fill="white" />
    <path d="M72.3804 65.1212C71.9021 64.2443 71.2325 62.3949 72.3804 62.0122C73.5284 61.6296 72.8587 63.9254 72.3804 65.1212Z" fill="white" />
    <path d="M79.0768 71.5784C79.2362 70.7812 79.6986 69.2825 80.2725 69.6652C80.8465 70.0478 79.7145 71.1001 79.0768 71.5784Z" fill="white" />
    <path d="M98.8517 31.3489L97.5805 30.5014L78.9362 58.4679L74.6989 68.2138L75.9701 69.0613L76.8175 69.485L83.5973 61.4341L98.8517 31.3489Z" fill="white" />
    <path d="M73.4203 72.399L74.0771 68.8032L76.206 70.0323L73.4203 72.399Z" fill="white" />
    <defs>
      <linearGradient id="paint0_linear_6213_491118" x1="90.6132" y1="23.3175" x2="45.8914" y2="100.778" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1557FF" stopOpacity="0.2" />
        <stop offset="1" stopColor="#EB0DE0" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient id="paint1_linear_6213_491118" x1="71.6959" y1="14.0666" x2="48.5462" y2="100.462" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1557FF" stopOpacity="0.2" />
        <stop offset="1" stopColor="#EB0DE0" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient id="paint2_linear_6213_491118" x1="52.468" y1="7.28174" x2="52.468" y2="96.7253" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1557FF" stopOpacity="0.2" />
        <stop offset="1" stopColor="#EB0DE0" stopOpacity="0.2" />
      </linearGradient>
    </defs>
  </svg>
)
