import React, { FC, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LENGTH_LIMITS, REGEX } from 'common/constants'
import { MailIcon } from 'common/icons_V2/MailIcon'
import { PhoneIcon } from 'common/icons_V2/PhoneIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { FormInput } from 'features/FormInput_V2'
import { updateMyProfile } from 'features/MyProfile/actions'
import { GenericForm } from 'features/MyProfile/components/ProfilePage/GenericForm'
import { selectEditProfileTranslations, selectMyProfileTranslations, selectOtherProfileTranslations } from 'features/Translations/selectors'
import { withDiscardModal } from 'hocs/withDiscardModal'
import { ProfileSections } from 'providers/ProfileSectionsProvider'
import styles from './styles.module.sass'

interface IContactInfo {
  isOtherProfile?: boolean,
  phoneNumber: string
  email: string
  isOpen?: boolean
  setIsOpenAboutElement?: React.Dispatch<React.SetStateAction<boolean>>,
  onOpenDiscardModal: () => void
}

const FIELDS = {
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email'
}

const ContactInfo: FC<IContactInfo> = ({
  isOtherProfile,
  phoneNumber,
  email,
  isOpen,
  setIsOpenAboutElement,
  onOpenDiscardModal
}) => {
  const dispatch = useDispatch()
  const [error, setError] = useState<string | null>(null)
  const formInputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)
  const editProfileTranslations = useSelector(selectEditProfileTranslations)
  const myProfileTranslations = useSelector(selectMyProfileTranslations)
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)
  const profileTranslations = isOtherProfile ? otherProfileTranslations : myProfileTranslations
  const cleanEmail = (email?: string) => email?.trim() || ''

  const handleUpdate = async (data: any) => {
    setError(null)
    const cleanedData = {
      [FIELDS.EMAIL]: cleanEmail(data[FIELDS.EMAIL])
    }
    dispatch(updateMyProfile(cleanedData, (error) => {
      if (error) {
        setError(error)
      }
    }, true))
  }

  const CustomComponent: FC<any> = ({
    register,
    errors,
    clearErrors,
    trigger,
    phoneNumber,
    email,
    showMaxCharCount,
    isOnEditing
  }) => {
    return (
      <div className={styles.contactInfoDetails}>
        <Typography variant={TypographyVariants.Desktop_UI_L_Semibold} tag="div" className={styles.fieldTitle}>
          {profileTranslations.contactInfo}
        </Typography>
        <div className={styles.contactInfo}>
          <div className={styles.contactItem}>
            <PhoneIcon />
            <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="span">
              {phoneNumber}
            </Typography>
          </div>
          <div>
            <FormInput
              ref={formInputRef}
              title={editProfileTranslations.emailLabel}
              fieldName={FIELDS.EMAIL}
              error={errors}
              placeholder={editProfileTranslations.emailLabel}
              defaultValue={cleanEmail(email)}
              clearErrors={clearErrors}
              register={register}
              trigger={trigger}
              isOnEditing
              showMaxCharCount={showMaxCharCount}
              maxCharCount={LENGTH_LIMITS.MAX.SUPPORT_EMAIL}
              regex={REGEX.EMAIL}
              regexValidationMessage={editProfileTranslations.emailFieldError}
              autoFocus={isOnEditing}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <GenericForm
      fields={[
        {
          name: FIELDS.PHONE_NUMBER,
          type: 'text',
          defaultValue: phoneNumber,
          maxCharCount: LENGTH_LIMITS.MAX.SUPPORT_EMAIL,
          showMaxCharCount: true
        },
        {
          name: FIELDS.EMAIL,
          type: 'text',
          defaultValue: cleanEmail(email),
          helperText: editProfileTranslations.emptyEmailError
        }
      ]}
      formTitle={myProfileTranslations.contactInfo}
      error={error}
      onSubmit={handleUpdate}
      isOpen={isOpen}
      setIsOpenAboutElement={setIsOpenAboutElement}
      onCancel={onOpenDiscardModal}
      CustomComponent={CustomComponent}
      viewOnly={isOtherProfile}
      sectionName={ProfileSections.CONTACT_INFO}
    >
      <div className={styles.contact}>
        <div className={styles.contactItem}>
          <PhoneIcon />
          <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="span">
            {phoneNumber}
          </Typography>
        </div>
        {cleanEmail(email) ? (
          <div className={styles.contactItem}>
            <MailIcon />
            <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="span">
              {cleanEmail(email)}
            </Typography>
          </div>
        ) : null}
      </div>
    </GenericForm>
  )
}

export const ContactInfoWithDiscard = withDiscardModal(ContactInfo)
