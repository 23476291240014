import { FC } from 'react'
import cn from 'classnames'
import { CloseIcon } from 'common/icons/CloseIcon'
import { SearchIcon } from 'common/icons/SearchIcon'
import styles from './styles.module.sass'

interface ISearchInput {
  onChange?: (value: string) => void;
  isHomeSearch?: boolean;
  value: string
  className?: string
  placeholder?: string
}

export const SearchInput: FC<ISearchInput> = ({
  onChange, isHomeSearch = false, value, className, placeholder
}) => {
  return (
    <div className={cn(styles.searchContainer, isHomeSearch && styles.homePageSearchContainer, className)}>
      <SearchIcon />
      <input
        value={value || ''}
        className={cn(styles.search, isHomeSearch && styles.homePageSearch)}
        placeholder={placeholder ?? ''}
        onChange={(e) => onChange?.(e.target.value)}
      />
      {value && (
        <div className={styles.close} onClick={() => onChange?.('')}>
          <CloseIcon />
        </div>
      )}
    </div>
  )
}
