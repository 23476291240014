import {
  FC,
  MouseEvent,
  useEffect,
  useState
} from 'react'
import cn from 'classnames'
import { PersonIcon } from 'common/icons/PersonIcon'
import { calculateBorderRadius } from 'common/utils/calculateBorderRadius'
import { getImageSrcFromBase64 } from 'common/utils/getImageSrcFromBase64'
import styles from './styles.module.sass'

interface IImage {
  photoURL: string
  photo?: string
  alt?: string
  width?: number
  onClick?: (e: MouseEvent<HTMLElement>) => void
  isRounded?: boolean,
  level?: number
}

const calculateBorderWidth = (width: number, color: string) => {
  if (width > 65) {
    return `3px solid ${color}`
  }

  if (width > 25) {
    return `2px solid ${color}`
  }

  return `1px solid ${color}`
}

export const Image: FC<IImage> = ({
  photoURL, photo, alt, width = 20, isRounded, level, onClick, ...imageProps
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(!!photo || !photoURL)
  const [isImageExists, setIsImageExists] = useState(!!photo || !!photoURL)
  const imageStyles = {
    width: `${width}px`,
    height: `${width}px`,
    borderRadius: isRounded ? `${width / 2}px` : `${calculateBorderRadius(width)}px`,
    ...(level && { border: `${calculateBorderWidth(width, '#306FD1')}` }),
    padding: isImageExists ? 0 : `${width / 4}px`
  }

  useEffect(() => {
    setIsImageExists(!!photo || !!photoURL)
  }, [photoURL, photo])

  return (
    <>
      {!isImageLoaded && <div style={imageStyles} className={styles.skeleton} />}
      {isImageExists
        ? (
          <div onClick={onClick} className={styles.baseImageWrapper}>
            <img
              style={{ ...imageStyles }}
              className={cn(isImageLoaded ? styles.visible : styles.hidden)}
              src={getImageSrcFromBase64(photo || '', photoURL)}
              alt={alt || ''}
              onLoad={() => setIsImageLoaded(true)}
              onError={() => {
                setIsImageLoaded(true)
                setIsImageExists(false)
              }}
              {...imageProps}
            />
          </div>
        )
        : (
          <div onClick={onClick} style={imageStyles} className={cn(styles.personIcon)}>
            <PersonIcon />
          </div>
        )}
    </>
  )
}
