import { STATUS_VACANCIES } from 'common/constants'
import type { RootState } from 'common/types'
import { sortJobOffers } from 'common/utils/jobOffers'
import { selectMemoizedUsers } from 'features/Home/selectors'
import type { ContactsType } from 'features/MyProfile/types'
import { getMyNotificationsHistory } from 'features/Notifications/selectors'
import { NotificationHistoryJobOfferType } from 'features/Notifications/types'
import { createSelector } from 'reselect'

const selectHomeInfo = (state: RootState) => state.surf
const selectProfile = (state: RootState) => state.profile

const getMyProfileSelector = (state: RootState) => state.profile.profile

const getOtherProfileSelector = (state: RootState) => state.contacts.otherProfile

const getLoadersProfileSelector = (state: RootState) => state.profile.loaders

const getJobProfileSelector = (state: RootState) => {
  const { profile: { profile } } = state
  if (!profile || !profile.job) return null

  const {
    job: {
      company, position, description, email, web, onsite, remote, salaryMin, salaryMax, payPeriod, currency
    }
  } = profile

  return {
    company, position, description, email, web, onsite, remote, salaryMin, salaryMax, payPeriod, currency
  }
}

export const getDisplayNameSelector = (state: RootState) => state.profile.profile?.displayName
export const getFirstNameSelector = (state: RootState) => state.profile.profile?.first_name
export const getLastNameSelector = (state: RootState) => state.profile.profile?.last_name

export const getMyName = createSelector(getDisplayNameSelector, getFirstNameSelector, getLastNameSelector,
  (displayName, firstName, lastName) => displayName || `${firstName} ${lastName}`)

export const getMyProfile = createSelector(getMyProfileSelector, (profile) => profile)

export const getMyUid = createSelector(getMyProfileSelector, (profile) => profile?.uid ?? '')

export const selectMyVacancies = createSelector(selectProfile, ({ vacancies }) => vacancies)
export const selectMyVacanciesLoading = createSelector(selectProfile, ({ isVacanciesLoading }) => isVacanciesLoading)

export const selectTrustLevels = createSelector(selectProfile, ({ trustUsers }) => {
  if (!trustUsers) return {}
  const trustLevels: { [key: string]: number } = {}
  Object.keys(trustUsers).forEach((key) => { trustLevels[key] = trustUsers[key].level })
  return trustLevels
})

export const selectTrustUsers = createSelector(selectProfile, ({ trustUsers }) => {
  if (!trustUsers) return []
  const profiles = Object.values(trustUsers).map(({ profile }) => profile)
  return profiles.length > 1
    ? profiles.sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''))
    : profiles
})

export const selectCommonTrustedContactList = createSelector(selectProfile, ({ commonTrustedContactList }) =>
  commonTrustedContactList)

export const selectMyActiveVacancies = createSelector(selectProfile, ({ vacancies }) => vacancies.filter((vacancy) =>
  vacancy?.status === STATUS_VACANCIES.OPEN))

export const selectMyCloseVacancies = createSelector(selectProfile, ({ vacancies }) => vacancies.filter((vacancy) =>
  vacancy?.status === STATUS_VACANCIES.CLOSED))

export const selectSpecialities = (uid: string) =>
  createSelector(selectProfile, ({ specialities }) => specialities[uid] ?? {})

export const selectActiveSpecialities = (uid: string) =>
  createSelector(selectProfile, ({ specialities }) => {
    const userSpecialities = specialities[uid]?.data ?? []
    return userSpecialities.filter((speciality: any) => {
      return speciality?.status === STATUS_VACANCIES.OPEN
    })
  })

export const selectClosedSpecialities = (uid: string) =>
  createSelector(selectProfile, ({ specialities }) => {
    const userSpecialities = specialities[uid]?.data ?? []
    return userSpecialities.filter((speciality: any) => {
      return speciality?.status === STATUS_VACANCIES.CLOSED
    })
  })

export const getJob = createSelector(getJobProfileSelector, (job) => job)

export const getLoadersProfile = createSelector(getLoadersProfileSelector, (loaders) => loaders)

export const getProfile = createSelector(getOtherProfileSelector, getMyProfileSelector, (otherProfile, myProfile) => {
  if (otherProfile) return otherProfile
  return myProfile
})

export const getOtherProfile = createSelector(getOtherProfileSelector, (profile) => profile)

export const selectContactUids = createSelector(getMyProfileSelector, (profile) => profile?.contacts ?? [])

export const selectTrustedUids = createSelector(getMyProfileSelector, (profile) => profile?.trust || [])

export const selectMyNetworkContacts = createSelector(
  getMyProfileSelector,
  selectMemoizedUsers,
  selectTrustedUids,
  (profile, memoizedUsers, trustedUids) => {
    return !profile?.contacts ? [] : profile.contacts.reduce<ContactsType[]>((acc, uid) => {
      return [
        ...acc,
        { ...memoizedUsers[uid], isTrusted: trustedUids.includes(memoizedUsers[uid]?.uid) }
      ].sort((firstContact: ContactsType, secondContacts: ContactsType) => {
        if (secondContacts.displayName < firstContact.displayName) return 1
        return -1
      })
    }, [])
  }
)

export const selectTrustedProfiles = createSelector(
  selectTrustUsers,
  (profiles) => {
    const trustedData = profiles.reduce<{ [key: string]: string[] }>((acc, profile) => {
      profile.trust?.forEach((trustId) => {
        if (!acc[trustId]) {
          acc[trustId] = []
        }
        acc[trustId].push(profile.displayName)
      })
      return acc
    }, {})

    return { ...trustedData }
  }
)

export const getIsFullTrustContacts = createSelector(getMyProfileSelector, (profile) =>
  profile?.isFullTrustContacts || false)

export const getIsEditTrustContacts = createSelector(getMyProfileSelector, (profile) =>
  profile?.isEditTrustContacts || false)

export const getIsOpenContactsModal = createSelector(selectProfile, ({ isOpenContactsModal }) =>
  isOpenContactsModal || false)

export const selectIsLoadingTrustOrUntrust = createSelector(selectProfile, ({ isTrustOrUntrustLoading }) =>
  isTrustOrUntrustLoading || false)

export const getMyJobOfferIds = createSelector(
  selectHomeInfo,
  getMyNotificationsHistory,
  getMyProfile,
  (home, history, myProfile) => {
    return sortJobOffers(
      home.notificationHistoryIds.jobOffer
        .filter((id) => {
          return (history[id] as NotificationHistoryJobOfferType)?.jobDetails?.uid === myProfile?.uid
        }),
      history as { [key: string]: NotificationHistoryJobOfferType }
    )
  }
)

export const getMyArchivedNotificationHistoryJobOfferIds = createSelector(
  selectHomeInfo,
  getMyNotificationsHistory,
  (home, history) => {
    return sortJobOffers(
      home.notificationHistoryIds.archivedJobOffer, history as { [key: string]: NotificationHistoryJobOfferType }
    )
  }
)
