import { getIdToken, User } from 'firebase/auth'
import { AuthUserType } from 'common/types'
import { minutesToMs } from 'common/utils/dateTime'

export const getToken = async (user: User) => {
  const authUser = user.toJSON() as AuthUserType
  let token = authUser.stsTokenManager.accessToken
  if (authUser.stsTokenManager.expirationTime - Date.now() < minutesToMs(5)) {
    token = await getIdToken(user)
  }
  return token
}
