import { useSelector } from 'react-redux'
import { Typography, TypographyVariants } from 'common/typography'
import { ButtonType, Modal } from 'features/Modal_V2'
import { selectProfileTranslations, selectSettingsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IDeleteAllDataModal {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  isDeleteLoading: boolean;
}

export const DeleteAllDataModal = ({
  isOpen, onClose, onSubmit, isDeleteLoading
}: IDeleteAllDataModal) => {
  const settingsTranslations = useSelector(selectSettingsTranslations)
  const profileTranslations = useSelector(selectProfileTranslations)

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      width={470}
      title={settingsTranslations.deleteProfile}
      secondaryButtonOnClick={onClose}
      secondaryButtonText={profileTranslations.cancel}
      primaryButtonOnClick={onSubmit}
      primaryButtonText={profileTranslations.deleteButton}
      isSecondaryButtonLoading={isDeleteLoading}
      primaryButtonType={ButtonType.DANGER}
      isNonClosable
    >
      <Typography variant={TypographyVariants.Desktop_UI_L_Regular} tag="p" className={styles.body}>
        {settingsTranslations.profileDeleteMessage}
        <br />
        {settingsTranslations.profileDeleteAreYouSureMessage}
      </Typography>
    </Modal>
  )
}
