import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { LENGTH_LIMITS, SYMBOLS } from 'common/constants'
import { Typography, TypographyVariants } from 'common/typography'
import { updateMyProfile } from 'features/MyProfile/actions'
import { GenericForm } from 'features/MyProfile/components/ProfilePage/GenericForm'
import { getMyProfile } from 'features/MyProfile/selectors'
import { selectMyProfileTranslations, selectProfileTranslations } from 'features/Translations/selectors'
import { withDiscardModal } from 'hocs/withDiscardModal'
import { ProfileSections } from 'providers/ProfileSectionsProvider'
import styles from './styles.module.sass'

interface ITags {
  isOtherProfile?: boolean,
  initialTags: string[],
  isOpen?: boolean,
  setIsOpenAboutElement?: React.Dispatch<React.SetStateAction<boolean>>,
  onOpenDiscardModal: () => void
}

const FIELDS = {
  TAGS: 'tags'
}

const Tags: FC<ITags> = ({
  isOtherProfile, initialTags, isOpen, setIsOpenAboutElement, onOpenDiscardModal
}) => {
  const myProfileTranslations = useSelector(selectMyProfileTranslations)
  const profileTranslations = useSelector(selectProfileTranslations)
  const myProfile = useSelector(getMyProfile)

  const dispatch = useDispatch()
  const [error, setError] = useState<string | null>(null)

  const handleUpdate = (data: any) => {
    setError(null)
    const cleanedData = {
      [FIELDS.TAGS]: data[FIELDS.TAGS].map((tag: string) => tag.trim()).filter((tag: string) => tag)
    }
    dispatch(updateMyProfile(cleanedData, (error) => {
      if (error) {
        setError(error)
      }
    }, true))
  }
  const isMatchedTag = (tag: string) => {
    return isOtherProfile && myProfile?.tags?.includes(tag)
  }

  return (
    <GenericForm
      fields={[
        {
          name: FIELDS.TAGS,
          type: 'tags',
          title: profileTranslations.tags,
          defaultValue: initialTags,
          showMaxCharCount: true,
          maxCharCount: LENGTH_LIMITS.MAX.TAG_LENGTH,
          placeholder: profileTranslations.tags
        }
      ]}
      error={error}
      onSubmit={(data) => handleUpdate({ tags: data.tags })}
      viewOnly={isOtherProfile}
      formHelperText={myProfileTranslations.tagsPlaceholder}
      onCancel={() => onOpenDiscardModal()}
      isOpen={isOpen}
      setIsOpenAboutElement={setIsOpenAboutElement}
      sectionName={ProfileSections.TAGS}
    >
      {!!initialTags?.length && (
        <div className={styles.tagElements}>
          {initialTags.map((tag: string) => (
            <div className={cn(styles.tag, isMatchedTag(tag) && styles.matchedTag)} key={tag}>
              <Typography variant={TypographyVariants.Desktop_UI_S_Medium} tag="span" className={styles.tagTitle}>
                {SYMBOLS.HASHTAG}{`${tag}`}
              </Typography>
            </div>
          ))}
        </div>
      )}
    </GenericForm>
  )
}

export const TagsWithDiscard = withDiscardModal(Tags)
