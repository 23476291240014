import React, { FC, useEffect } from 'react'

// TODO: type
export const useWithScrollTop = (Component: FC<any>) => (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <Component {...props} />
}
