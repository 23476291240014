import { useSelector } from 'react-redux'
import { Modal } from 'features/Modal'
import { selectSettingsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IConfirmSignOutModal {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isSignOutLoading: boolean;
}

export const ConfirmSignOutModal = ({
  isOpen, onClose, onSubmit, isSignOutLoading
}: IConfirmSignOutModal) => {
  const settingsTranslations = useSelector(selectSettingsTranslations)

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      width={600}
      title={settingsTranslations.signOut}
      primaryButtonText={settingsTranslations.cancelButtonText}
      primaryButtonOnClick={onClose}
      secondaryButtonText={settingsTranslations.signOut}
      secondaryButtonOnClick={onSubmit}
      isSecondaryButtonLoading={isSignOutLoading}
    >
      <p className={styles.description}>{settingsTranslations.signOutAlertTitle}</p>
    </Modal>
  )
}
