import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { ImageWithTrustMark } from 'common/components/ImageWithTrustMark_V2'
import { SYMBOLS } from 'common/constants'
import { useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { LocationIcon } from 'common/icons_V2/LocationIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { formatLocation } from 'common/utils/profile'
import { selectAllLocations } from 'features/Locations/selectors'
import { Avatar } from 'features/MyProfile/components/Avatar'
import { EditHeaderModalWithDiscard } from 'features/MyProfile/components/EditHeaderModal'
import { PhotoModal } from 'features/MyProfile/components/PhotoModal'
import { ProfileType } from 'features/MyProfile/types'
import { selectProfileTranslations } from 'features/Translations/selectors'
import { useProfileSections } from 'providers/ProfileSectionsProvider'
import { TrustedContacts } from '../TrustedContacts'
import styles from './styles.module.sass'

interface IHeaderMyProfile {
  profile: ProfileType,
  jobAndCompany: string
  auth: boolean
}

export const HeaderMyProfile: FC<IHeaderMyProfile> = ({
  profile,
  jobAndCompany,
  auth
}) => {
  const {
    photoURL,
    photo,
    firstName,
    username,
    location,
    uid,
    lastName
  } = profile

  const isMobile = useMobileMediaQuery()
  const { activeSection, withDisable } = useProfileSections()
  const disable = !!activeSection
  const profileTabsTranslations = useSelector(selectProfileTranslations)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const allLocations = useSelector(selectAllLocations())
  const formattedLocation = formatLocation(location ?? '', allLocations.regions)
  const [isOpenPhotoModal, setIsOpenPhotoModal] = useState(false)

  return (
    <div className={styles.headerMyProfileContainer}>
      <div className={styles.myProfileContainer}>
        {isMobile ? (
          <>
            <div className={cn(styles.contactInfo, styles.headerOtherProfileForMobile)}>
              <div className={styles.shareAndEdit}>
                <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="p">
                  {firstName}&nbsp;{lastName}
                </Typography>
              </div>
              {username && (
                <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p">
                  {`${SYMBOLS.AT_SYMBOL}${username}`}
                </Typography>
              )}
              <Avatar photoURL={photoURL} photo={photo} />
              {jobAndCompany && (
                <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p" className={styles.jobAndCompany}>
                  {jobAndCompany}
                </Typography>
              )}
              <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p" className={styles.iconStyles}>
                {formattedLocation && (
                  <>
                    <LocationIcon />
                    {formattedLocation}
                  </>
                )}
              </Typography>
              <TrustedContacts
                isOtherProfile={false}
                profile={profile}
                auth={auth}
              />
            </div>
            <div className={styles.actions}>
              <GhostButton
                title={profileTabsTranslations.edit}
                onClick={() => { withDisable(setIsOpenEditModal)(true) }}
                disabled={disable}
              />
            </div>
          </>
        ) : (
          <>
            <ImageWithTrustMark
              onClick={() => withDisable(setIsOpenPhotoModal)(!!photo || !!(photoURL || ''))}
              photo={photo}
              photoURL={photoURL || ''}
              width={156}
              uid={uid}
              className={(!photo && !photoURL) ? styles.emptyPhotoContainer : ''}
            />
            <div className={styles.contactInfo}>
              <div className={styles.infoStyles}>
                <div className={styles.userInfo}>
                  <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="p">
                    {firstName}&nbsp;{lastName}
                  </Typography>
                  {username && (
                    <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p">
                      {`${SYMBOLS.AT_SYMBOL}${username}`}
                    </Typography>
                  )}
                  <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p" className={styles.jobAndCompany}>
                    {jobAndCompany}
                  </Typography>
                  <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p" className={styles.iconStyles}>
                    {formattedLocation && (
                      <>
                        <LocationIcon />
                        {formattedLocation}
                      </>
                    )}
                  </Typography>
                  <TrustedContacts
                    isOtherProfile={false}
                    profile={profile}
                    auth={auth}
                  />
                </div>
                <div className={styles.actions}>
                  <GhostButton
                    title={profileTabsTranslations.edit}
                    onClick={() => { withDisable(setIsOpenEditModal)(true) }}
                    disabled={disable}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {isOpenEditModal && (
          <EditHeaderModalWithDiscard
            isOpen={isOpenEditModal}
            onClose={() => setIsOpenEditModal(false)}
          />
        )}
        <PhotoModal
          isOpen={isOpenPhotoModal}
          photoURL={photoURL}
          onClose={() => setIsOpenPhotoModal(false)}
        />
      </div>
    </div>
  )
}
