import { FC } from 'react'
import styles from '../../styles.module.sass'

interface IDescription {
  title: string
  description: string
}

export const Description: FC<IDescription> = ({ title, description }) => {
  return (
    <div className={styles.subsection}>
      <h4 className={styles.subsectionTitle}>{title}</h4>
      <p>{description}</p>
    </div>
  )
}
