import { v4 as uuidv4 } from 'uuid'
import LocalStorageService from './LocalStorageService'

export const getDeviceID = () => {
  let deviceId = LocalStorageService.getItem('deviceId')
  if (!deviceId) {
    deviceId = uuidv4()
    LocalStorageService.setItem('deviceId', deviceId)
  }
  return deviceId
}
