import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'features/MyProfile/actions'
import { getIsFullTrustContacts, getIsOpenContactsModal } from 'features/MyProfile/selectors'
import { actions as actionsToast } from 'features/ToastManager/actions'
import { ToastType } from 'features/ToastManager/types'
import { selectProfileTranslations } from 'features/Translations/selectors'
import { TrustUsersModal } from 'features/TrustUsersModal'
import { ContactActionsProvider } from 'providers/ContactActionsProvider'

export const Modals = () => {
  const dispatch = useDispatch()
  const profileTranslations = useSelector(selectProfileTranslations)
  const isOpenContactsModal = useSelector(getIsOpenContactsModal)
  const isFullTrustContacts = useSelector(getIsFullTrustContacts)

  useEffect(() => {
    if (isFullTrustContacts) {
      dispatch(actionsToast.addToast({
        type: ToastType.WARNING,
        message: profileTranslations.networkTrustLimitToastMessage
      }))
      dispatch(actions.setIsFullTrustContacts(false))
    }
  }, [isFullTrustContacts])

  return (
    <>
      {isOpenContactsModal && (
        <ContactActionsProvider>
          <TrustUsersModal isOpenContactsModal={isOpenContactsModal} />
        </ContactActionsProvider>
      )}
      {/* TODO: Reuse for swap */}
      {/* {isFullTrustContacts && <LimitExceededTrustModal isFullTrustContacts={isFullTrustContacts} />} */}
    </>
  )
}
