import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trustUser, untrustUser } from 'features/MyProfile/actions'
import { selectMyNetworkContacts } from 'features/MyProfile/selectors'

// is useful for fixing the issue with quick (when the prev one is still pending) trust/untrust
// needs to be called top level of the list
// so that the state if common for the entire element group
export const useTrustUntrust = () => {
  const contacts = useSelector(selectMyNetworkContacts)
  const dispatch = useDispatch()
  const [trustedUsers, setTrustedUsers] = useState<string[]>(
    contacts.reduce((acc: string[], { isTrusted, uid }) => {
      return (isTrusted ? [...acc, uid] : acc)
    }, [])
  )

  const trust = async (uid: string) => {
    setTrustedUsers((prev) => [...prev, uid])
    await dispatch(trustUser([...trustedUsers, uid]))
  }
  const untrust = async (uid: string) => {
    setTrustedUsers((prev) => [...prev.filter((p) => p !== uid)])
    await dispatch(untrustUser(trustedUsers.filter((p) => p !== uid)))
  }

  return { trust, untrust }
}
