import { createContext, ReactNode, useContext } from 'react'
import { useSetPageScrollYPosition } from 'common/hooks/useSetPageScrollYPosition'

interface IProps {
  pageRoute: string;
  children: ReactNode
}

interface ISetPageScrollYPositionContext {
  ref: HTMLDivElement | null;
  setRef: (ref: HTMLDivElement | null) => void;
  setPageScrollYPosition: () => void;
  resetPageScrollYPosition: () => void;
}

const Context = createContext<ISetPageScrollYPositionContext>({} as ISetPageScrollYPositionContext)
export const useSetPageScrollYPositionContext = () => useContext(Context)

export const SetPageScrollYPositionProvider = ({ children, pageRoute }: IProps) => {
  const {
    ref, setRef, setPageScrollYPosition, resetPageScrollYPosition
  } = useSetPageScrollYPosition(pageRoute)

  return (
    <Context.Provider
      value={{
        ref, setRef, setPageScrollYPosition, resetPageScrollYPosition
      }}
    >
      {children}
    </Context.Provider>
  )
}
