import { FC } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { formatLocation } from 'common/utils/profile'
import { selectAllLocations } from 'features/Locations/selectors'
import styles from '../../styles.module.sass'

interface ILocations {
  title: string
  locations: string[]
}

export const Locations: FC<ILocations> = ({ title, locations }) => {
  const { regions } = useSelector(selectAllLocations())
  return (
    <div className={cn(styles.subsection, styles.locations)}>
      <h4 className={cn(styles.subsectionTitle)}>{title}</h4>
      {locations.map((location: string) => (
        <p key={location}>
          {formatLocation(location, regions)}
        </p>
      ))}
    </div>
  )
}
