import { RefObject, useEffect } from 'react'
import { EventType } from 'common/types'

export const useOutside = (
  ref: RefObject<HTMLElement>,
  action: () => void,
  eventType: EventType = EventType.CLICK
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        action()
      }
    }

    document.addEventListener(eventType, handleClickOutside)
    return () => {
      document.removeEventListener(eventType, handleClickOutside)
    }
  }, [ref, action, eventType])
}
