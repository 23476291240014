import React from 'react'

export const Unlock = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.58301 10.0833H5.49967V7.33421C5.49683 4.50857 7.63559 2.1405 10.4469 1.85653C13.2583 1.57256 15.8274 3.46508 16.3897 6.23421C16.4904 6.73035 16.1699 7.21421 15.6738 7.31496C15.1776 7.4157 14.6938 7.09517 14.593 6.59904C14.2181 4.75295 12.5054 3.49127 10.6312 3.68058C8.75695 3.8699 7.33111 5.44861 7.33301 7.33329V10.0833H17.4163C18.9351 10.0833 20.1663 11.3145 20.1663 12.8333V19.25C20.1663 20.7687 18.9351 22 17.4163 22H4.58301C3.06422 22 1.83301 20.7687 1.83301 19.25V12.8333C1.83301 11.3145 3.06422 10.0833 4.58301 10.0833ZM3.66634 12.8333C3.66634 12.327 4.07675 11.9166 4.58301 11.9166H17.4163C17.9226 11.9166 18.333 12.327 18.333 12.8333V19.25C18.333 19.7562 17.9226 20.1666 17.4163 20.1666H4.58301C4.07675 20.1666 3.66634 19.7562 3.66634 19.25V12.8333Z" fill="#fcbf26" />
    <mask id="mask0_601:444" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="20" height="21">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.58301 10.0833H5.49967V7.33421C5.49683 4.50857 7.63559 2.1405 10.4469 1.85653C13.2583 1.57256 15.8274 3.46508 16.3897 6.23421C16.4904 6.73035 16.1699 7.21421 15.6738 7.31496C15.1776 7.4157 14.6938 7.09517 14.593 6.59904C14.2181 4.75295 12.5054 3.49127 10.6312 3.68058C8.75695 3.8699 7.33111 5.44861 7.33301 7.33329V10.0833H17.4163C18.9351 10.0833 20.1663 11.3145 20.1663 12.8333V19.25C20.1663 20.7687 18.9351 22 17.4163 22H4.58301C3.06422 22 1.83301 20.7687 1.83301 19.25V12.8333C1.83301 11.3145 3.06422 10.0833 4.58301 10.0833ZM3.66634 12.8333C3.66634 12.327 4.07675 11.9166 4.58301 11.9166H17.4163C17.9226 11.9166 18.333 12.327 18.333 12.8333V19.25C18.333 19.7562 17.9226 20.1666 17.4163 20.1666H4.58301C4.07675 20.1666 3.66634 19.7562 3.66634 19.25V12.8333Z" fill="white" />
    </mask>
    <g mask="url(#mask0_601:444)">
      <rect width="22" height="22" fill="#fcbf26" />
    </g>
  </svg>
)
