import { ROUTES } from 'common/constants'

export const NAVBAR_KEYS = {
  MY_NETWORK: '/contacts',
  PROFILE: '/profile',
  EXPLORE: '/explore',
  MESSAGES: '/messages',
  LISTINGS: ROUTES.LISTINGS
}

export const SEARCH_PARAMS = {
  HOME_SELECTED_CARD: 'selectedCard',
  LISTING_SELECTED_CARD: 'selectedMyCard',
  ARCHIVED_SELECTED_CARD: 'selectedArchivedCard'
}

export const PROFILE_TABS_KEYS = {
  VACANCIES: 'vacancies',
  ABOUT: 'about',
  OPEN_TO: 'openTo'
}

export const JOB_TYPE_KEYS = {
  REMOTE: 'remote',
  ON_SITE: 'onSite'
}

export const PROFILE_ACTIONS_KEYS = {
  SHARE: 'share',
  TRUST: 'trust',
  UNTRUST: 'untrust',
  ADD_CONTACT: 'Add contact'
}

export const EXPLORE_PAGE_CARD_TITLE_KEYS = {
  INTRODUCTION_BY: 'introductionBy',
  HIRING: 'homeMatchesHiring',
  OPEN_TO: 'homeMatchesOpenTo'
}

export enum LINK_ERRORS {
  URL_LENGTH = 'urlLength',
  NAME_LENGTH = 'nameLength',
  EMPTY_URL = 'emptyUrl',
  EMPTY_NAME = 'emptyName',
  URL_PATTERN = 'urlPattern',
  DUPLICATE = 'duplicate',
}

export const CARD_ACTIONS_KEYS = {
  BOOKMARK: 'bookmark',
  SHARE: 'share',
  FORWARD: 'forward',
  HIDE: 'hide',
  REPORT_JOB: 'report-job',
  MOVE_ARCHIVE: 'move-archive',
  EDIT: 'edit',
  DELETE: 'delete'
}
