import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { PrimaryButton } from 'common/components/Button/PrimaryButton'
import { SecondaryButton } from 'common/components/Button/SecondaryButton'
import { ROUTES } from 'common/constants'
import { useWhitelistedParams } from 'common/hooks/useWhitelistedParams'
import { CloseIcon } from 'common/icons/CloseIcon'
import { Logo } from 'common/icons/Logo'
import { getAppLinkByOS } from 'common/utils/getAppLinkByOS'
import { selectAppLinksTranslations, selectPublicTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

export const PublicNavBar = () => {
  const history = useHistory()
  const location = useLocation()
  const withWhitelistedParams = useWhitelistedParams()
  const goToLandingPage = () => history.push(withWhitelistedParams('/'))
  const publicProfileTranslations = useSelector(selectPublicTranslations)
  const appLinks = useSelector(selectAppLinksTranslations)
  const [isOpen, setIsOpen] = useState(true)

  const renderHTMLString = (htmlString: string) => {
    const [a, c] = htmlString.split(/<tag>.*<\/tag>/)
    const b = React.createElement(
      'a',
      { href: getAppLinkByOS(appLinks) },
      htmlString.replace(/.*<tag>/, '').replace(/<\/tag>.*/, '')
    )
    return [a, b, c]
  }

  return (
    <>
      <div className={cn(styles.container)}>
        <SecondaryButton
          className={styles.signIn}
          title={publicProfileTranslations.signIn}
          onClick={goToLandingPage}
        />
        <PrimaryButton
          className={styles.joinUs}
          title={publicProfileTranslations.joinUs}
          onClick={goToLandingPage}
        />
      </div>
      {(isOpen && location.pathname !== ROUTES.SUPPORT) && (
        <div className={styles.mobileUnAuthNavBar}>
          <div className={styles.linkForUploadApp}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <p>{renderHTMLString(publicProfileTranslations.useAppSuggestion)}</p>
          </div>
          <div onClick={() => setIsOpen(false)}><CloseIcon /></div>
        </div>
      )}
    </>
  )
}
