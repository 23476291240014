export const calculateBorderRadius = (width: number) => {
  if (width >= 100) {
    return 6
  }

  if (width >= 54) {
    return 4
  }

  return 2
}
