import { CSSProperties, RefObject, useEffect } from 'react'

export const useDynamicInputWidth = (
  inputRef: RefObject<HTMLInputElement>,
  inputValue: string,
  spanRef: RefObject<HTMLSpanElement>,
  inputPadding?: number,
  widthForPlaceholder?: boolean
) => {
  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      // eslint-disable-next-line no-param-reassign
      inputRef.current.style.width = inputValue
        ? `${spanRef.current.offsetWidth + (2 * (inputPadding || 1))}px`
        : '80px' // TDOD: other sln
      if (widthForPlaceholder) {
        // eslint-disable-next-line no-param-reassign
        inputRef.current.style.width = 'unset'
      }
    }
  }, [inputValue])

  const spanStyles: CSSProperties = {
    visibility: 'hidden',
    whiteSpace: 'nowrap',
    position: 'absolute'
  }

  return spanStyles
}
