import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  getUserDetailsPathByUid, getUserDetailsPathByUsername, getUserSpecialtyDetails, getUserVacancyDetails
} from 'common/utils/url'
import { getMyUid } from 'features/MyProfile/selectors'

export const useProfileNavigation = () => {
  const history = useHistory()
  const myUid = useSelector(getMyUid)

  const navigateToProfile = (uid: string, username?: string, isDeleted?: boolean, query?: string) => {
    return username
      ? navigateToProfileByUsername(username, isDeleted, query)
      : navigateToProfileByUid(uid, isDeleted, query)
  }

  const navigateToProfileByUid = (uid: string, isDeleted?: boolean, query?: string) => {
    if (isDeleted) return
    const pathname = getUserDetailsPathByUid(uid, myUid)
    history.push(`${pathname}${query || ''}`)
  }

  const navigateToProfileByUsername = (username: string, isDeleted?: boolean, query?: string) => {
    if (isDeleted) return
    const pathname = getUserDetailsPathByUsername(username)
    history.push(`${pathname}${query || ''}`)
  }

  const navigateToUserVacancy = (uid: string, vacancyId: string, isDeleted?: boolean, query?: string) => {
    if (isDeleted || !vacancyId) return
    const pathname = getUserVacancyDetails(uid)
    history.push(`${pathname}${query || ''}`)
  }

  const navigateToUserSpecialty = (uid: string, specialtyId: string, isDeleted?: boolean, query?: string) => {
    if (isDeleted || !specialtyId) return
    const pathname = getUserSpecialtyDetails(uid)
    history.push(`${pathname}${query || ''}`)
  }

  return {
    navigateToProfile,
    navigateToUserVacancy,
    navigateToUserSpecialty
  }
}
