import { FC } from 'react'
import { SYMBOLS } from 'common/constants'
import styles from '../../styles.module.sass'

interface ITags {
  title: string
  tags: string[]
}

export const Tags: FC<ITags> = ({ title, tags }) => {
  return (
    <div className={styles.subsection}>
      <h4 className={styles.subsectionTitle}>{title}</h4>
      <p>
        <p>{SYMBOLS.HASHTAG}{tags.join(` ${SYMBOLS.HASHTAG}`)}</p>
      </p>
    </div>
  )
}
