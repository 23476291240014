import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'common/constants'
import { useDesktopMediaQuery } from 'common/hooks/mediaQuery'
import { LockIcon } from 'common/icons/LockIcon'
import { SettingsIcon } from 'common/icons/SettingsIcon'
import { SignOutIcon } from 'common/icons/SignOutIcon'
import { ArchiveIcon } from 'common/icons_V2/ArchiveIcon'
import { ChevronUpIcon } from 'common/icons_V2/ChevronUpIcon'
import { ShareIcon } from 'common/icons_V2/ShareIcon'
import { deleteMyUser, signOut } from 'features/Auth/actions'
import { ShareModalWithDiscard } from 'features/Home/components/CardActionsPopup_V2/components/ShareModal'
import { getMyUid } from 'features/MyProfile/selectors'
import { DropDownButton } from 'features/NavBar/components/DropDownButton'
import { ConfirmSignOutModal } from 'features/Settings/ConfirmSignOutModal'
import { DeleteAllDataModal } from 'features/Settings/DeleteAllDataModal'
import { selectOnboardingTranslations, selectProfileTranslations, selectSettingsTranslations } from 'features/Translations/selectors'

interface IProfileMenu {
  isMobileScreen?: boolean
  closeSideBar?: () => void
}

export const ProfileMenu: FC<IProfileMenu> = ({ isMobileScreen = false, closeSideBar }) => {
  const dispatch = useDispatch()
  const myUid = useSelector(getMyUid)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false)
  const [isSignOutLoading, setIsSignOutLoading] = useState(false)
  const [isOpenList, setIsOpenList] = useState(isMobileScreen || false)
  const history = useHistory()
  const settingsTranslations = useSelector(selectSettingsTranslations)
  const profileTranslations = useSelector(selectProfileTranslations)
  const onboardingTranslations = useSelector(selectOnboardingTranslations)
  const isDesktop = useDesktopMediaQuery()

  const toggleOpenList = () => (setIsOpenList(!isOpenList))
  const closeList = () => (isMobileScreen ? null : setIsOpenList(false))
  const [isOpenShareModal, setIsOpenShareModal] = useState(false)

  const dropDownList = [
    {
      title: settingsTranslations.settingsButtonText,
      onClick: () => {
        closeSideBar?.()
        history.push(ROUTES.SETTINGS)
      },
      icon: <SettingsIcon />
    },
    {
      title: settingsTranslations.shareProfile,
      onClick: () => {
        closeSideBar?.()
        setIsOpenShareModal(true)
      },
      icon: <ShareIcon />
    },
    ...(isDesktop
      ? []
      : [
        {
          title: onboardingTranslations.textLegalPrivacy,
          onClick: () => {
            closeSideBar?.()
            history.push(ROUTES.PRIVACY_POLICY)
          },
          icon: <LockIcon />
        },
        {
          title: onboardingTranslations.textLegalTerms,
          onClick: () => {
            closeSideBar?.()
            history.push(ROUTES.TERMS_OF_SERVICE)
          },
          icon: <LockIcon />
        }
      ]),
    {
      title: profileTranslations.jobHistory,
      onClick: () => {
        closeSideBar?.()
        history.push(ROUTES.CLOSED_VACANCIES)
      },
      icon: <ArchiveIcon />
    },
    {
      title: settingsTranslations.signOut,
      onClick: () => setIsSignOutModalOpen(true),
      icon: <SignOutIcon />
    }
  ]

  const onDeleteUser = () => {
    setIsDeleteLoading(true)
    dispatch(deleteMyUser(myUid))
  }

  const onSignOut = () => {
    setIsSignOutLoading(true)
    dispatch(signOut(() => history.push('/')))
  }

  const getIcon = () => {
    if (isMobileScreen) {
      return null
    }
    return <ChevronUpIcon rotate={isOpenList ? 0 : 180} />
  }

  return (
    <>
      <DropDownButton
        icon={getIcon()}
        arrow={false}
        list={dropDownList}
        isActive={false}
        isOpenList={isOpenList}
        onCloseList={closeList}
        onToggleOpenList={toggleOpenList}
        listWidth={isMobileScreen ? '100%' : 278}
        isMobileScreen={isMobileScreen}
        closeSideBar={closeSideBar}
      />
      <DeleteAllDataModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onSubmit={onDeleteUser}
        isDeleteLoading={isDeleteLoading}
      />
      <ConfirmSignOutModal
        isOpen={isSignOutModalOpen}
        onClose={() => setIsSignOutModalOpen(false)}
        onSubmit={onSignOut}
        isSignOutLoading={isSignOutLoading}
      />
      <ShareModalWithDiscard
        uid={myUid}
        isOpen={isOpenShareModal}
        onClose={() => setIsOpenShareModal(false)}
        modalTitle={settingsTranslations.shareMyProfileModalTitle}
      />
    </>
  )
}
