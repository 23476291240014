import { useLocation } from 'react-router-dom'
import { QUERY_PARAMS } from 'common/constants'
import { getQueryObject } from 'common/utils/url'

const WHITELISTED_PARAMS = [QUERY_PARAMS.REDIRECT]

export const useWhitelistedParams = () => {
  const { search } = useLocation()
  const query = getQueryObject(search)

  const withWhitelistedParams = (pathname: string, blacklist: string[] = []) => {
    const filtered = Object.entries(query).reduce((acc, [key, value]) => {
      if (WHITELISTED_PARAMS.includes(key) && !blacklist.includes(key)) {
        const withQuery = pathname.includes('?')
        return `${acc || withQuery ? '&' : '?'}${acc}${key}=${value}`
      }
      return acc
    }, '')
    return `${pathname}${filtered}`
  }

  return withWhitelistedParams
}
