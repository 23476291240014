import dayjs from 'dayjs'

export const getFormattedDate = (dateUpdated: Date | string) => {
  const date = typeof dateUpdated === 'string' ? new Date(dateUpdated) : dateUpdated
  const isSameDay = dayjs().isSame(dateUpdated, 'day')
  if (isSameDay) {
    return date.toLocaleTimeString(navigator.language, {
      hour: 'numeric',
      minute: 'numeric'
    })
  }
  const formattedDay = dayjs(dateUpdated)?.calendar(null, {
    lastDay: 'ddd',
    sameDay: 'HH:mm',
    lastWeek: 'ddd',
    sameElse: date.toLocaleDateString(navigator.language)
  })
  return formattedDay
}

export const minutesToMs = (min: number) => min * 60 * 1000

export const minutesToSecond = (min: number) => min * 60

export const formatSeconds = (sec: string | number): string => {
  const seconds = Number(sec)
  if (Math.floor(seconds / 3600)) return `${Math.floor(seconds / 3600)} hours`
  if (Math.floor(seconds / 60)) return `${Math.floor(seconds / 60)} minutes`
  return `${seconds} seconds`
}

export const secondsToMinutesAndSeconds = (secondes: number) => {
  const seconds = Math.floor(secondes % 60)
  const minutes = Math.floor(secondes / 60)
  const addLeadingZero = (number: number) => `0${number}`.slice(-2)

  return {
    minutes: addLeadingZero(minutes || 0),
    seconds: addLeadingZero(seconds || 0)
  }
}

export const millisecondsToMinutesAndSeconds = (totalMilliSeconds: number) => {
  let seconds = Math.floor(totalMilliSeconds / 1000)
  const minutes = Math.floor(seconds / 60)
  const milliSeconds = Math.floor(totalMilliSeconds % 1000)

  seconds %= 60
  const addLeadingZero = (number: number) => `0${number}`.slice(-2)
  const changingViewMilliSeconds = (number: number) => `${number}`.slice(0, 2)
  return {
    minutes: addLeadingZero(minutes || 0),
    seconds: addLeadingZero(seconds || 0),
    milliSeconds: changingViewMilliSeconds(milliSeconds || 0)
  }
}

export const millisecondsToSeconds = (totalMilLiSeconds: number) => Math.floor(totalMilLiSeconds / 1000)
