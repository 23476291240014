import { FC } from 'react'
import { useSelector } from 'react-redux'
import { TransparentButton } from 'common/components/Button_V2/TransparentButton'
import { useProfileNavigation } from 'common/hooks/useProfileNavigation'
import { Typography, TypographyVariants } from 'common/typography'
import { getMyProfile } from 'features/MyProfile/selectors'
import { selectSettingsTranslations } from 'features/Translations/selectors'
import { ProfileIcon } from './ProfileIcon'
import styles from './styles.module.sass'

interface IProfileSection {
  onAfterClick?: () => void
}

export const ProfileSection: FC<IProfileSection> = ({ onAfterClick }) => {
  const settingsTranslations = useSelector(selectSettingsTranslations)
  const profile = useSelector(getMyProfile)
  const { navigateToProfile } = useProfileNavigation()

  const navigateToUserProfile = () => {
    if (profile) {
      navigateToProfile(profile.uid, profile.username)
    }
    onAfterClick?.()
  }

  return (
    <div className={styles.dropdownProfileSection}>
      <div className={styles.profileHeader}>
        <ProfileIcon />
        <div className={styles.profileDetails}>
          <Typography
            variant={TypographyVariants.Desktop_UI_M_Medium}
            tag="p"
            className={styles.profileName}
          >
            {profile?.firstName || ''}&nbsp;{profile?.lastName || ''}
          </Typography>
          <Typography
            variant={TypographyVariants.Desktop_UI_M_Medium}
            tag="p"
            className={styles.jobName}
          >
            {profile?.job?.title || ''}
          </Typography>
        </div>
      </div>
      <TransparentButton
        onClick={navigateToUserProfile}
        title={settingsTranslations.viewProfile}
      />
    </div>
  )
}
