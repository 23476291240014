import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LENGTH_LIMITS } from 'common/constants'
import { Typography, TypographyVariants } from 'common/typography'
import { formatString } from 'common/utils/formatStrings'
import { updateMyProfile } from 'features/MyProfile/actions'
import { GenericForm } from 'features/MyProfile/components/ProfilePage/GenericForm'
import { PROFILE_TABS_KEYS } from 'features/Translations/constants'
import { selectMyProfileTranslations, selectOtherProfileTranslations } from 'features/Translations/selectors'
import { withDiscardModal } from 'hocs/withDiscardModal'
import { ProfileSections } from 'providers/ProfileSectionsProvider'
import styles from './styles.module.sass'

interface IAboutElement {
  about: string,
  isOtherProfile?: boolean,
  isOpen?: boolean,
  setIsOpenAboutElement?: React.Dispatch<React.SetStateAction<boolean>>,
  onOpenDiscardModal: () => void
}

const FIELDS = {
  ABOUT: 'about'
}

const AboutElement: FC<IAboutElement> = ({
  about, isOtherProfile = true, isOpen, setIsOpenAboutElement, onOpenDiscardModal
}) => {
  const dispatch = useDispatch()
  const otherProfileTabsTranslations = useSelector(selectOtherProfileTranslations)
  const myProfileTranslations = useSelector(selectMyProfileTranslations)
  const profileTabsTranslations = isOtherProfile
    ? otherProfileTabsTranslations
    : myProfileTranslations
  const aboutTitle = profileTabsTranslations[PROFILE_TABS_KEYS.ABOUT]
  const [error, setError] = useState<string | null>(null)

  const handleUpdate = (data: any) => {
    setError(null)
    const cleanedData = {
      [FIELDS.ABOUT]: data[FIELDS.ABOUT]?.trim() ?? ''
    }
    dispatch(updateMyProfile(cleanedData, (error) => {
      if (error) {
        setError(error)
      }
    }, true))
  }

  return (
    <>
      <GenericForm
        fields={[
          {
            name: FIELDS.ABOUT,
            type: 'textarea',
            title: aboutTitle,
            defaultValue: about,
            showMaxCharCount: true,
            maxCharCount: LENGTH_LIMITS.MAX.ABOUT_JOB,
            placeholder: aboutTitle
          }
        ]}
        error={error}
        onSubmit={handleUpdate}
        viewOnly={isOtherProfile}
        formHelperText={myProfileTranslations.aboutPlaceholder}
        onCancel={() => onOpenDiscardModal()}
        isOpen={isOpen}
        setIsOpenAboutElement={setIsOpenAboutElement}
        sectionName={ProfileSections.ABOUT}
      >
        {about && (
          <div className={styles.aboutBlock}>
            <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="div" className={styles.fieldText}>
              {formatString(about)}
            </Typography>
          </div>
        )}
      </GenericForm>
    </>
  )
}

export const AboutElementWithDiscard = withDiscardModal(AboutElement)
