import { AppEnv, ConfigType } from 'config/type'

enum APP_NAMES {
  DEV = 'uplift-c0380',
  PROD = 'upliftprod'
}

const project: APP_NAMES = (process.env.REACT_APP_FIREBASE_PROJECT as APP_NAMES) ?? APP_NAMES.DEV

const commonConfig = {
  privacyPolicyUrl: 'https://www.termsfeed.com/live/be837ef1-bb38-4054-b2d8-54550cff1f1e',
  termsAndConditionsUrl: 'https://www.termsfeed.com/live/77b68c7c-2cfb-422f-a8ee-6152f8f3662b',
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY ?? '',
    authDomain: `${project}.firebaseapp.com`,
    databaseURL: `https://${project}.firebaseio.com`,
    projectId: project,
    storageBucket: `${project}.appspot.com`,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_APP_ID?.split(':')[1]
  },
  isProd: project === APP_NAMES.PROD
}

const config: {
  [key in APP_NAMES]: Partial<ConfigType>
} = {
  [APP_NAMES.DEV]: {
    env: AppEnv.DEV,
    baseUrl: 'https://uplift-c0380.web.app',
    dynamicLinkBase: 'https://upliftdirectdev.page.link',
    backendAPIUrl: 'https://europe-west1-uplift-c0380.cloudfunctions.net/',
    dynamicLink: {
      android: {
        afl: 'https://uplift-c0380.web.app',
        apn: 'com.w8rk.app.dev'
      }
    },
    features: {
      callEnabled: false,
      jobAudienceEnabled: false
    }
  },
  [APP_NAMES.PROD]: {
    env: AppEnv.PROD,
    baseUrl: 'https://w8rk.com',
    dynamicLinkBase: 'https://w8rk.com/links/',
    backendAPIUrl: 'https://europe-west1-upliftprod.cloudfunctions.net/',
    dynamicLink: {
      android: {
        afl: 'https://w8rk.com',
        apn: 'com.w8rk.app'
      }
    },
    features: {
      callEnabled: false,
      jobAudienceEnabled: false
    }
  }
}

export default {
  ...commonConfig,
  ...config[project]
}
