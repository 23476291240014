import { NotificationHistoryJobOfferType } from 'features/Notifications/types'

export const sortJobOffers = (
  jobOfferIds: string[], history: { [key: string]: NotificationHistoryJobOfferType }
) => {
  return jobOfferIds.sort((a, b) => {
    const aTime = (history[a])?.jobDetails?.ts || ''
    const bTime = (history[b])?.jobDetails?.ts || ''
    return new Date(bTime).getTime() - new Date(aTime).getTime()
  })
}
