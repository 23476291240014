import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { EXPENDABLE_INPUT_OPTION_ID } from 'features/Auth/components/OnboardingStepsNew/PopupMenu_V2'
import { getSpecialties } from 'features/Auth/selectors'
import { FormExpandableInput, withExpandableInputProps } from 'features/FormExpandableInput_V2'
import { selectErrorMsgsTranslations, selectProfileTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

const WithExpandableInput = withExpandableInputProps(FormExpandableInput)

export const JobTitleField: FC<any> = ({
  title,
  placeholder,
  onOptionSelect,
  fieldName,
  error,
  isSubmitted,
  isOnEditing,
  maxCharCount,
  showMaxCharCount,
  isRequired = true,
  isEditable = true
}) => {
  const formContext = useFormContext()
  const allSpecialties = useSelector(getSpecialties)
  const errorMsgsTranslations = useSelector(selectErrorMsgsTranslations)
  const profileTranslations = useSelector(selectProfileTranslations)

  if (!formContext) return <></>

  const {
    getValues, trigger, clearErrors, setError, formState: { errors }
  } = formContext

  const onFocus = () => clearErrors(fieldName)

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget?.id !== EXPENDABLE_INPUT_OPTION_ID) {
      if (e.target.value.length > maxCharCount) {
        setError(
          fieldName,
          { type: 'custom', message: errorMsgsTranslations.helperTextLimit.replace('%', `${maxCharCount}`) }
        )
      } else if (isSubmitted || isOnEditing) {
        trigger(fieldName)
      }
    }
  }

  const selectOption = (option: string) => {
    clearErrors(fieldName)
    onOptionSelect(option)
  }

  return (
    <Controller
      name={fieldName}
      rules={{
        required: {
          value: isRequired,
          message: profileTranslations.helperJobTitle
        },
        maxLength: {
          value: maxCharCount,
          message: errorMsgsTranslations.helperTextLimit.replace('%', `${maxCharCount}`)
        }
      }}
      render={() => {
        return (
          <div className={styles.wrapper}>
            <WithExpandableInput
              placeholder={placeholder}
              writable
              allowSelectCustomValue
              options={allSpecialties}
              openPopupOnClick={isEditable}
              onBlur={onBlur}
              onFocus={onFocus}
              onOptionSelect={selectOption}
              defaultInputValue={getValues(fieldName)}
              title={title}
              isRequired={isRequired}
              blurOnselect={false}
              id={fieldName}
              error={error || errors[fieldName]?.message as string}
              maxCharCount={maxCharCount}
              disabled={!isEditable}
              showMaxCharCount={showMaxCharCount}
              expandableInputWrapperStyles={styles.expandableInputWrapperStyles}
            />
          </div>
        )
      }}
    />
  )
}
