export const getAppLinkByOS = (appLinksLocalise: { [key: string]: string }) => {
  const { userAgent } = navigator

  if (/android/i.test(userAgent)) {
    return appLinksLocalise.googlePlay || ''
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return appLinksLocalise.appStore || ''
  }

  return ''
}

export const isMobileOrTablet = () => {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent)
}
