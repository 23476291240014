import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AUDIENCES } from 'common/constants'
import { selectVacanciesTranslations } from 'features/Translations/selectors'

export const useAudiences = () => {
  const vacanciesTranslations = useSelector(selectVacanciesTranslations)
  return useMemo(() => [
    { key: AUDIENCES.ONLY_TRUSTS, label: vacanciesTranslations[AUDIENCES.ONLY_TRUSTS] },
    { key: AUDIENCES.MY_NETWORK, label: vacanciesTranslations[AUDIENCES.MY_NETWORK] }
  ], [vacanciesTranslations])
}
