import LogoSvg from '../images/logo.svg'

interface ILogo {
  width?: string
  height?: string
}

export const Logo = ({ width = '100%', height }: ILogo) => {
  return (
    <img src={LogoSvg} alt="Logo" width={width} height={height} />
  )
}
