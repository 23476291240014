import type { Message } from '@twilio/conversations'
import type { MessageType } from 'features/Conversations/types'
import type { ChatTranslationsType } from 'features/Translations/types'

export const isAudioMessage = (message?: Message | MessageType) => !!message?.attachedMedia?.[0] && message.attachedMedia[0].contentType.slice(0, 6) === 'audio/'
export const isMediaMessage = (message?: Message | MessageType) => !!message?.attachedMedia?.[0]
export const isMediaNotAudio = (message?: Message | MessageType) => {
  return isMediaMessage(message) && !isAudioMessage(message)
}

export const getMessageText = (chatTranslations: ChatTranslationsType, message?: Message | MessageType) => {
  if (isAudioMessage(message)) return chatTranslations.voiceMessage
  if (isMediaNotAudio(message) && message?.attachedMedia?.[0].filename) return message!.attachedMedia?.[0].filename
  return message?.body || ''
}

export const isImgMessage = (message: Message | MessageType) => {
  const messageContentType = message?.attachedMedia?.[0]?.contentType

  return isMediaMessage(message) && messageContentType?.startsWith('image/')
}

export const isPdfMessage = (message: Message | MessageType) => {
  const messageContentType = message?.attachedMedia?.[0]?.contentType

  return isMediaMessage(message) && messageContentType === 'application/pdf'
}

export const downloadFile = (blob: Blob, message: any) => {
  const blobURL = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = blobURL

  a.download = message?.attachedMedia?.[0].filename || 'media'
  document.body.appendChild(a)
  a.click()
}

export const downloadMediaMessage = (message: any) => {
  return async () => {
    const mediaContentUrl = await message?.attachedMedia?.[0]?.getContentTemporaryUrl()
    fetch(mediaContentUrl)
      .then((response) => response.blob())
      .then((blob: Blob) => {
        downloadFile(blob, message)
      })
  }
}

export const createMediaMsgFromLocal = (
  blob: Blob | File, filename?: string
) => {
  const mediaLocalUrl = window.URL.createObjectURL(blob)
  return {
    attachedMedia: [
      {
        filename: filename || (blob as { name: string }).name,
        size: blob.size,
        contentType: blob.type,
        getContentTemporaryUrl: () => Promise.resolve(mediaLocalUrl)
      }
    ],
    mediaLocalUrl
  }
}
