import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PrimaryButton } from 'common/components/Button_V2/PrimaryButton'
import { Image } from 'common/components/Image_V2'
import { useWhitelistedParams } from 'common/hooks/useWhitelistedParams'
import { Typography, TypographyVariants } from 'common/typography'
import { Modal } from 'features/Modal_V2'
import { ProfileType } from 'features/MyProfile/types'
import { selectOtherProfileTranslations, selectPublicTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

export enum PublicProfileLOModalType {
  PROFILE ='profile',
  LISTINGS = 'listings'
}

interface IPublicProfileLOModal {
  profile: ProfileType
  modalType: PublicProfileLOModalType
}

export const PublicProfileLOModal: FC<IPublicProfileLOModal> = ({ profile, modalType }) => {
  const otherProfileTabsTranslations = useSelector(selectOtherProfileTranslations)
  const publicProfileTranslations = useSelector(selectPublicTranslations)
  const history = useHistory()
  const withWhitelistedParams = useWhitelistedParams()

  const goToLandingPage = () => history.push(withWhitelistedParams('/'))
  const getModalText = () => {
    switch (modalType) {
      case PublicProfileLOModalType.PROFILE:
        return otherProfileTabsTranslations.publicProfileSignupModalProfile
      case PublicProfileLOModalType.LISTINGS:
        return otherProfileTabsTranslations.publicProfileSignupModalListings
      default:
        return ''
    }
  }
  const markedString = (getModalText() || '')
    .replace('%name', '__NAME__')
    .replace('%lastName', '__LASTNAME__')
  const parts = markedString.split(/(__NAME__|__LASTNAME__)/)

  return (
    <Modal title="" isOpen onClose={() => null} width={420}>
      <div className={styles.publicProfileLOState}>
        <Image
          photoURL={profile.photoURL}
          photo={profile.photo}
          alt={profile.displayName}
          width={136}
          isRounded
        />
        <Typography
          variant={TypographyVariants.Desktop_UI_XL_Medium}
          className={styles.publicProfileModalTitle}
          tag="p"
        >
          {parts.map((part, index) => {
            if (part === '__NAME__') {
              return <strong key={index}>{profile?.firstName || ''}</strong>
            }
            if (part === '__LASTNAME__') {
              return <strong key={index}>{profile?.lastName || ''}</strong>
            }
            return <span key={index}>{part}</span>
          })}
        </Typography>
        <PrimaryButton
          title={publicProfileTranslations.joinUsModal}
          onClick={goToLandingPage}
        />
      </div>
    </Modal>
  )
}
