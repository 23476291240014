import type { RootState } from 'common/types'
import { createSelector } from 'reselect'

const getAuthSelector = (state: RootState) => state.auth

export const getAuth = createSelector(getAuthSelector, ({ auth }) => auth)
export const getAuthUid = createSelector(getAuthSelector, ({ uid }) => uid)
export const getIsAuthLoading = createSelector(getAuthSelector, ({ isLoading }) => isLoading)
export const getSpecialties = createSelector(getAuthSelector, ({ specialties }) => specialties)
export const getRegisterData = createSelector(getAuthSelector, ({ registerData }) => registerData)
