import { FC } from 'react'
import styles from '../../styles.module.sass'

interface IAudience {
  title: string
  audience: string
}

export const Audience: FC<IAudience> = ({ title, audience }) => {
  return (
    <div className={styles.subsection}>
      <h4 className={styles.subsectionTitle}>{title}</h4>
      <p>{audience}</p>
    </div>
  )
}
