import { ActionTypes } from 'features/FilterItems/types'

const initialState = {
  homeFilters: [] as number[],
  listingFilters: [] as number[],
  networkFilters: [] as number[],
  networkModalFilters: [] as number[]
}

export const FilterReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case 'FILTERS__SET_HOME_FILTERS': {
      return {
        ...state,
        ...action?.filters.homeFilters
      }
    }
    case 'FILTERS__SET_LISTING_FILTERS': {
      return {
        ...state,
        ...action?.filters.listingFilters
      }
    }
    case 'FILTERS__SET_NETWORK_FILTERS': {
      return {
        ...state,
        ...action?.filters.networkFilters
      }
    }
    case 'FILTERS__SET_NETWORK_MODAL_FILTERS': {
      return {
        ...state,
        ...action?.filters.networkModalFilters
      }
    }
    default:
      return state
  }
}
