import {
  FC, Fragment, useCallback, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'
import { SecondaryButton } from 'common/components/Button/SecondaryButton'
import { EmptySearchState } from 'common/components/EmptySearchState'
import { ImageWithTrustMark } from 'common/components/ImageWithTrustMark'
import { useProfileNavigation } from 'common/hooks/useProfileNavigation'
import { colorTheMatch } from 'common/utils/colorTheMatch'
import { isNumber } from 'common/utils/numbers'
import { searchInString } from 'common/utils/searchInString'
import { MOCK_USER } from 'features/Contacts/Network/constants'
import { Modal } from 'features/Modal'
import { actions } from 'features/MyProfile/actions'
import { selectIsLoadingTrustOrUntrust, selectMyNetworkContacts, selectTrustLevels } from 'features/MyProfile/selectors'
import type { ContactsType } from 'features/MyProfile/types'
import { SearchInput } from 'features/SearchInput'
import { PROFILE_ACTIONS_KEYS } from 'features/Translations/constants'
import {
  selectMyNetworkTranslations, selectMyProfileTranslations, selectOtherProfileTranslations, selectProfileTranslations
} from 'features/Translations/selectors'
import { useContactActions } from 'providers/ContactActionsProvider'
import styles from './styles.module.sass'

interface ITrustUsersModal {
  isOpenContactsModal: boolean
}

export const TrustUsersModal: FC<ITrustUsersModal> = ({ isOpenContactsModal }) => {
  const dispatch = useDispatch()
  const { trust } = useContactActions()
  const [searchString, setSearchString] = useState('')
  const profileTranslations = useSelector(selectProfileTranslations)
  const myProfileTranslations = useSelector(selectMyProfileTranslations)
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)
  const contacts = useSelector(selectMyNetworkContacts)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const isLoading = useSelector(selectIsLoadingTrustOrUntrust)
  const trustLevels = useSelector(selectTrustLevels)
  const { navigateToProfile } = useProfileNavigation()

  const onSearch = (value: string) => {
    setSearchString(value.trim())
  }

  const helperForTrusting = useMemo(() => (searchString ? [] : [{
    displayName: profileTranslations.emptyTrustingHelperTitle,
    photoURL: '',
    uid: MOCK_USER,
    job: { title: profileTranslations.emptyTrustingHelperSubtitle },
    photo: '',
    isTrusted: false,
    first_name: '',
    last_name: '',
    slots: '',
    about: ''
  }]), [searchString])

  const sortByLevel = useCallback((contacts: ContactsType[]) => {
    return contacts.sort((firstUser, secondUser) => {
      if (!trustLevels[firstUser.uid] && trustLevels[secondUser.uid]) return 1
      if (trustLevels[firstUser.uid] && !trustLevels[secondUser.uid]) return -1
      if (trustLevels[firstUser.uid] === trustLevels[secondUser.uid]) {
        return firstUser.displayName < secondUser.displayName ? -1 : 1
      }
      return trustLevels[firstUser.uid] - trustLevels[secondUser.uid]
    })
  }, [])

  const filteredTrustedContacts = useMemo(() => {
    return sortByLevel(
      contacts.filter(
        (contact) => contact.isTrusted
          && (searchInString(searchString, contact.displayName || '')
            || searchInString(searchString, contact.job?.title || '')
            || searchInString(searchString, contact.job?.company || ''))
      )
    )
  }, [contacts, searchString, sortByLevel])

  const filteredUnTrustedContacts = useMemo(() => {
    return sortByLevel(
      contacts.filter(
        (contact) => !contact.isTrusted
          && (searchInString(searchString, contact.displayName || '')
            || searchInString(searchString, contact.job?.title || '')
            || searchInString(searchString, contact.job?.company || ''))
      )
    )
  }, [contacts, searchString, sortByLevel])

  const myContacts = [
    {
      title: myProfileTranslations.trustedText,
      users: filteredTrustedContacts.length ? filteredTrustedContacts : helperForTrusting,
      count: contacts.filter((contact) => contact.isTrusted).length
    },
    {
      title: profileTranslations.suggestedSectionTitle,
      users: filteredUnTrustedContacts,
      buttonText: otherProfileTranslations[PROFILE_ACTIONS_KEYS.TRUST],
      className: styles.trustButtonStyle,
      onClick: async (uid: string) => trust(uid)
    }
  ]

  const openCurrentUserProfile = useCallback((uid: string, userName: string) => {
    dispatch(actions.setIsOpenContactsModal(false))
    navigateToProfile(uid, userName)
  }, [])

  const renderContact = useCallback((
    {
      uid, photo, photoURL, displayName, job, userName
    },
    options?: { buttonText?: string, onClick?: (uid: string) => void, className?: string }
  ) => {
    const isMockUser = uid === MOCK_USER
    return (
      <div
        key={uid}
        className={cn(styles.contact, isMockUser && styles.cursorUnset)}
      >
        <ImageWithTrustMark
          photo={photo}
          photoURL={photoURL}
          alt={displayName}
          width={54}
          className={styles.imgContainer}
          uid={uid}
          onClick={isMockUser ? undefined : () => openCurrentUserProfile(uid, userName)}
        />
        <div className={styles.data}>
          <div onClick={isMockUser ? undefined : () => openCurrentUserProfile(uid, userName)}>
            <p className={styles.userName} data-tip data-for={displayName}>
              {colorTheMatch(displayName, searchString)}
            </p>
            <ReactTooltip id={displayName} place="top" effect="solid" className={styles.tooltip}>
              {displayName}
            </ReactTooltip>
            <p className={styles.position} data-tip data-for={job?.title}>
              {colorTheMatch(job?.title, searchString)}
            </p>
            <ReactTooltip id={job?.title} place="top" effect="solid" className={styles.tooltip}>
              {job?.title}
            </ReactTooltip>
          </div>
          {!!options?.buttonText && (
            <SecondaryButton
              title={options?.buttonText || ''}
              className={cn(styles.button, options?.className)}
              onClick={() => options?.onClick?.(uid)}
            />
          )}
        </div>
      </div>
    )
  }, [openCurrentUserProfile, searchString])

  return (
    <Modal
      isOpen={isOpenContactsModal}
      width="535px"
      title={myNetworkTranslations.title}
      isLoading={isLoading}
      onClose={() => dispatch(actions.setIsOpenContactsModal(false))}
      footerStyles={styles.footerStyles}
      scrollableContent={(
        <>
          {myContacts.some((contact) => !!contact.users.length) ? (
            myContacts?.map(({
              title, users, count, buttonText, className, onClick
            }) =>
              (users?.length ? (
                <Fragment key={title}>
                  <h3 className={styles.subtitle}>
                    {title}
                    {(!searchString.length && isNumber(count)) && (
                      <>&nbsp;{count}/{profileTranslations.maxCountTrusts}</>
                    )}
                  </h3>
                  <div className={styles.contacts}>
                    {users.map((user) => {
                      const isMockUser = user.uid === MOCK_USER
                      if (searchString && isMockUser) return <></>
                      return renderContact(
                        user,
                        isMockUser ? {} : {
                          buttonText,
                          onClick,
                          className
                        }
                      )
                    })}
                  </div>
                </Fragment>
              ) : <></>))
          ) : (
            <EmptySearchState />
          )}
        </>
      )}
    >
      <SearchInput
        onChange={onSearch}
        className={styles.searchInput}
        value={searchString}
        placeholder={myNetworkTranslations.searchTrustedUserPlaceholder}
      />
    </Modal>
  )
}
