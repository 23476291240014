import { profileAPI } from 'api'
import { LocationTye, ThunkType } from './types'

export const actions = {
  setAllLocations: (locations: LocationTye) => ({ type: 'LOCATIONS__SET_ALL_LOCATIONS', locations } as const)
}

export const getAllLocations = (): ThunkType => async (dispatch, getState) => {
  const { locations } = getState()
  if (!locations.cities.length) {
    try {
      const locations = await profileAPI.getAllLocations()
      dispatch(actions.setAllLocations(locations))
      return locations
    } catch (error) {
      return {}
    }
  }
  return null
}
