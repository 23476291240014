import { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { actions } from 'common/actions'
import { getPageScrollYPosition } from 'common/selectors'

export const useSetPageScrollYPosition = (pageRoute: string) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const pageScrollYPosition = useSelector(getPageScrollYPosition(pageRoute))
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    ref?.scroll(0, pageScrollYPosition)
    window.scrollTo(0, pageScrollYPosition)
  }, [pageScrollYPosition, ref, location.pathname])

  useLayoutEffect(() => {
    ref?.scrollTo(0, pageScrollYPosition)
    window.scrollTo(0, pageScrollYPosition)
  }, [pageScrollYPosition, ref, location.pathname])

  const setPageScrollYPosition = () => {
    dispatch(actions.setPageScrollYPosition({
      pageRoute,
      scrollY: ref && ref.scrollTop > 0 ? ref.scrollTop : window.scrollY
    }))
  }

  const resetPageScrollYPosition = () => {
    dispatch(actions.setPageScrollYPosition({ pageRoute, scrollY: 0 }))
  }

  return {
    ref, setRef, setPageScrollYPosition, resetPageScrollYPosition
  }
}
