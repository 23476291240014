import { FC } from 'react'
import styles from '../../styles.module.sass'

interface IWorkingDetails {
  title: string
  workingDetails: { key: string; value: string | null }[],
}

export const WorkingDetails: FC<IWorkingDetails> = ({ title, workingDetails }) => {
  return (
    <div className={styles.subsection}>
      <h4 className={styles.subsectionTitle}>{title}</h4>
      <div className={styles.row}>
        {workingDetails.map(({ key, value }) => (
          <p key={key}>{value}</p>
        ))}
      </div>
    </div>
  )
}
